import CardGiftcard from '@mui/icons-material/CardGiftcard'
import { styled, TextField } from '@mui/material'
import { useState } from 'react'

import { Button } from '@/components/button'
import RedeemedDialog from '@/components/dialog/redeemed-dialog'
import { Divider } from '@/components/divider'
import GiftItems from '@/components/gift/gift-items'
import { Spinner } from '@/components/spinner'
import { useLoggedInUserCredentials } from '@/store/auth'
import { column, row } from '@/utils/flexGrid'
import { isInMobileAppWebview } from '@/utils/mobile-app-communication'
import theme from '@/utils/theme'

export default function Redeem () {
  const [error, setError] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [giftData, setGiftData] = useState(null)

  const { token, uid } = useLoggedInUserCredentials()
  const isWebView = isInMobileAppWebview()
  const [redeemCode, setRedeemCode] = useState('')

  const handleInputChange = (event) => {
    const inputValue = event.target.value.trim()
    setRedeemCode(inputValue)
    setError('')
  }

  async function redeemGiftCard () {
    setIsLoading(true)

    try {
      const redeem = await window.fetch(`${import.meta.env.VITE_API_SERVER}/gift/redeem`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-uid': uid,
          'x-token': token
        },
        body: JSON.stringify({ giftId: redeemCode.toLowerCase() })
      })

      if (redeem.status === 400) {
        throw new Error('Already redeemed')
      }
      if (redeem.status === 404) {
        throw new Error('Code is invalid')
      }
      if (redeem.status !== 200) {
        throw new Error('An error occurred')
      }

      window.fetch(`${import.meta.env.VITE_API_SERVER}/gift/get_by_id`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ giftId: redeemCode.toLowerCase() })
      }).then(res => res.json()).then(data => {
        setGiftData(data)
      })

      setRedeemCode('')
    } catch (error) {
      setError(error.message || 'An error occurred')
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <Container>
      <h2>Redeem Gift Card</h2>
      <Divider />
      <div className='redeem'>
        <h3>Enter your unique code here:</h3>
        <div className='redeem-input'>
          <TextField
            variant='outlined'
            value={redeemCode}
            onChange={handleInputChange}
          />
          <Button onClick={redeemGiftCard} disabled={!redeemCode.length} className='green' endIcon={!isLoading ? <CardGiftcard /> : <></>}>{!isLoading ? 'Redeem' : <Spinner size={24} color='inherit' />}</Button>
        </div>
        {error && <p className='body-md' style={{ color: 'red' }}>{error}</p>}
      </div>
      {!isWebView && (
        <>
          <div className='gift'>
            <h3>Buy a gift card</h3>
            <GiftItems hasAnimation={false} style={{ marginBlock: 10 }} />
          </div>
        </>
      )}
      <RedeemedDialog isOpen={Boolean(giftData)} data={giftData} onClose={() => setGiftData(null)} />
    </Container>
  )
}

const Container = styled('div')({
  ...column,
  alignItems: 'flex-start',
  gap: theme.spacing(3),
  '& .redeem': {
    ...column,
    width: '100%',
    gap: theme.spacing(1)
  },
  '& .redeem-input': {
    ...row,
    gap: theme.spacing(2),
    '& .MuiTextField-root': {
      width: '70%',
      '& input': {
        padding: theme.spacing(1)
      }
    },
    '& button': {
      width: '30%'
    }
  },
  '& .gift': {
    ...column,
    width: '100%',
    gap: theme.spacing(1)
  },
  [theme.breakpoints.down('md')]: {
    '& .redeem-input': {
      ...column,
      '& .MuiTextField-root, button': {
        width: '100%'
      }
    }
  }
})
