import { useState, useRef, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useParams, useSearchParams } from 'react-router-dom'

import VideoPlayer from './player'

import { useShowFreeTrialBanner } from '@/hooks/monetization'
import { showBanner } from '@/store/controls'
import { useGetInsights, useGetVideo, useGetVideoMetadata } from '@/store/video'
import { isProd } from '@/utils'
import { APIWrapper } from '@/utils/api'
import { BANNER_KEYS } from '@/utils/banners'

const VideoPage = (props) => {
  const {
    children,
    disableDeadTime,
    jumpToVideoTime,
    muxPlayerRef,
    onRateChange,
    playerContent,
    showHighlights,
    showRallyNav,
    showTabView,
    showTheaterControl,
    showDeadTimeToggle
  } = props
  const params = useParams()
  const dispatch = useDispatch()
  const searchParams = useSearchParams()[0]
  const localMuxPlayerRef = useRef(null)
  const [rallyNumber, setRallyNumber] = useState(1)

  // Use props provided muxPlayerRef and fallback to local one when not set
  const playerRef = muxPlayerRef ?? localMuxPlayerRef
  const vid = params.vid
  const workflowId = searchParams.get('workflowId')
  const getInsightsArgs = { vid }
  if (workflowId) {
    getInsightsArgs.workflowId = workflowId
  }

  useEffect(() => {
    if (!isProd) {
      dispatch(showBanner(BANNER_KEYS.DEV_ENV))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useShowFreeTrialBanner()

  return (
    <APIWrapper
      apis={[
        { api: useGetVideo, args: vid, waitForLoad: true, name: 'video', needsUseAPI: false },
        { api: useGetInsights, args: getInsightsArgs, waitForLoad: false, name: 'insights', needsUseAPI: false },
        { api: useGetVideoMetadata, args: vid, waitForLoad: true, name: 'videoMetadata', needsUseAPI: false }
      ]}
      addContext={ctx => {
        // Add video-player related stuff
        ctx.muxPlayerRef = playerRef
        ctx.setRallyNumber = setRallyNumber
        // if the insights data isn't the right version, don't try to use it
        if (ctx.insights) {
          ctx.insightsVersion = ctx.insights.version
          ctx.isInsightsVersionOkay = ctx.insights.version.startsWith('2.')
          if (!ctx.isInsightsVersionOkay) {
            ctx.insights = undefined
          }
        }

        // add workflow to context (extracted from video object to be the one
        // that was requested by the user)
        const { workflows } = ctx.video
        if (!workflowId) {
          // workflows[0] could be undefined if no workflow has started yet
          const latestWorkflow = workflows[0]
          return { workflow: latestWorkflow, ...ctx }
        }
        for (const workflow of workflows) {
          if (workflow.workflowId === workflowId) {
            return { workflow, ...ctx }
          }
        }
        return ctx // workflow not found
      }}
    >
      <VideoPlayer showTheaterControl={showTheaterControl} showRallyNav={showRallyNav} rallyNumber={rallyNumber} disableDeadTime={disableDeadTime} onRateChange={onRateChange} showHighlights={showHighlights} jumpToVideoTime={jumpToVideoTime} showTabView={showTabView} showDeadTimeToggle={showDeadTimeToggle}>
        {playerContent}
      </VideoPlayer>
      {children}
    </APIWrapper>
  )
}

export default VideoPage
