import Container from './container'
import GiftedSubscriptionCard from './gifted-subscription-card'
import Hero from './hero'
import PricingCard from './pricing-card'
import SubscriptionCard from './subscription-card'

import useMobileDetect from '@/hooks/use-mobile-detect'
import { useLoggedInUser, useMyMonetizationData } from '@/store/auth'
import cls from '@/utils/classnames'

const pricing = [
  {
    title: 'Starter',
    planKey: 'starter',
    fullPrice: 20,
    features: [
      'Tag other players to share insights for free',
      <div key='slicing'>Auto rally slicing <br />(dead time removal)</div>,
      <div key='hot'><b data-orange>COMING SOON!</b><br />Create highlight reels</div>,
      '10GB free video storage',
      'View at 1080p and 30fps',
      <div key='credits'>Includes <span data-green>60 Credits</span></div>
    ]
  },
  {
    title: 'Premium',
    planKey: 'premium',
    fullPrice: 50,
    subtitle: 'Everything in Starter, plus ..',
    features: [
      { hot: true, desc: 'Explore shot by shot analysis with Shot Explorer' },
      <div key='stats'>Download <span data-green>advanced stats</span></div>,
      'Unlimited free video storage',
      'Viewing at 4k and 60fps',
      'Download clips for offline use',
      <div key='credits'>Includes <span data-green>180 Credits</span></div>
    ]
  }
]

/**
 * Subscription tab component that handles the display of subscription options
 * and current subscription status for a logged-in user. It shows pricing cards
 * for starter and premium plans if the user has no subscriptions, otherwise
 * it displays the current subscription details.
 *
 * @component
 * @returns {JSX.Element|null} The rendered component or null if loading.
 */
export default function Subscription () {
  const { firstUploadEpoch } = useLoggedInUser()
  const isDesktop = useMobileDetect('desktop')
  const { isLoading, subscriptions, freeTrialExpirationEpoch, creditsLeft, currentSubscription, giftSubscription } = useMyMonetizationData()

  if (isLoading) return null

  const noSubscriptions = subscriptions.length === 0
  const lastSubscription = noSubscriptions ? false : currentSubscription ?? subscriptions[0]

  return (
    <Container className={cls(!isDesktop && 'mobile')}>
      {!lastSubscription
        ? (
          <>
            {giftSubscription && <GiftedSubscriptionCard giftSubscription={giftSubscription} />}
            {!giftSubscription && <Hero remaining={creditsLeft} startAt={firstUploadEpoch} endsAt={freeTrialExpirationEpoch} />}
            <div className='row'>
              {pricing.map((p, i) => (
                <PricingCard key={i} {...p} />
              ))}
            </div>
          </>
          )
        : (
          <SubscriptionCard currentSubscription={lastSubscription} giftSubscription={giftSubscription} />
          )}
    </Container>
  )
}
