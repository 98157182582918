import { styled } from '@mui/material'
import dayjs from 'dayjs'
import React from 'react'

import COLORS from '@/utils/colors'
import { column, row } from '@/utils/flexGrid'

export default function GiftedSubscriptionCard ({ giftSubscription }) {
  const endsDT = dayjs(giftSubscription.end).format('M/D/YY')
  return (
    <Container>
      <p className='body-lg'>Current subscription: <b style={{ textTransform: 'capitalize' }}>{giftSubscription.type}</b></p>
      <div className='description'>
        <p className='body-sm'>You are subscribed to this service via your <b>Gift</b></p>
      </div>
      <div className='period'>
        <div className='end'>Subscription ends on {endsDT}</div>
      </div>
    </Container>
  )
}

const Container = styled('div')({
  ...column,
  alignItems: 'center',
  alignSelf: 'stretch',
  background: COLORS.white,
  border: `1px solid ${COLORS['neutral-300']}`,
  borderRadius: 'var(--radius-sm, 8px)',
  color: COLORS['neutral-800'],
  gap: 16,
  marginBottom: 24,
  padding: '16px 0px 0px',
  '& .period': {
    ...row,
    alignItems: 'center',
    alignSelf: 'stretch',
    backgroundColor: COLORS['neutral-200'],
    gap: 32,
    justifyContent: 'center',
    padding: '8px 0'
  },
  '& .description': {
    ...column,
    textAlign: 'center'
  }
})
