import { styled } from '@mui/material'
import { useEffect, useRef } from 'react'
import { Navigate } from 'react-router-dom'

import PricingPlansSection from '../pricing/sections/plans'

import { ExploreLandingSection } from './sections/explore-section'
import { HeroLandingSection } from './sections/hero-section'
import { PartnersLandingSection } from './sections/partners-section'
import { TestimonialsLandingSection } from './sections/testimonials-section'
import { VideoLandingSection } from './sections/video-section'

import { useLoggedInUser } from '@/store/auth'
import { column } from '@/utils/flexGrid'

export default function LandingPage () {
  const user = useLoggedInUser()
  const preventNavigateAway = useRef()

  useEffect(() => {
    const mainDiv = document.getElementsByClassName('main')[0]
    mainDiv.classList.add('landing-page-main')
    return () => {
      mainDiv.classList.remove('landing-page-main')
    }
  }, [])

  if (user?.token && !preventNavigateAway.current) {
    // do not navigate away when prevent is set (user clicked subscribe button)
    return <Navigate to='/library' replace />
  }

  return (
    <Container>
      <HeroLandingSection />
      <VideoLandingSection />
      <ExploreLandingSection />
      <PricingPlansSection preventNavigateAway={preventNavigateAway} />
      <TestimonialsLandingSection />
      <PartnersLandingSection />
    </Container>
  )
}

const Container = styled('div')({
  ...column,
  '& #plans': {
    margin: '300px 0 0 0',
    '&.mobile': {
      margin: '80px 0 0 0'
    }
  }
})
