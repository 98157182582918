import { styled } from '@mui/material/styles'
import { forwardRef } from 'react'

import COLORS from '@/utils/colors'

/**
 * TagPlayer container
 *
 * @returns {React.ReactElement}
 */
export const StyledContainer = styled('div')(({ truncate }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  cursor: 'default',
  overflow: 'hidden',
  '& .player-name': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    gap: '8px',
    width: '100% !important',
    overflow: 'hidden',
    '& .name-container': {
      ...(truncate
        ? {
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap'
          }
        : {}),
      '& span': {
        fontSize: 16,
        fontWeight: 700,
        lineHeight: '133%',
        color: COLORS['neutral-800']
      }
    },
    '& .remove-icon-container': {
      width: '25px',
      height: '25px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      '& .MuiSvgIcon-root': {
        color: COLORS['neutral-400'],
        fontSize: '16px',
        '&:hover': {
          color: 'black'
        }
      }
    },
    '& .edit-icon': {
      fontSize: 18,
      color: COLORS['neutral-400']
    }
  },
  '&.taggable': {
    cursor: 'pointer',
    '&.tagged': {
      '& .player-name': {
        border: `1px solid ${COLORS['neutral-300']}`,
        borderRadius: '8px',
        padding: '4px 16px',
        width: 'fit-content !important',
        height: '40px',
        background: 'white'
      }
    }
  }
}))

// eslint-disable-next-line react/display-name
export const Container = forwardRef((props, ref) => <StyledContainer {...props} ref={ref} />)
