import SkipNextIcon from '@mui/icons-material/SkipNext'
import SkipPreviousIcon from '@mui/icons-material/SkipPrevious'
import MuxPlayer from '@mux/mux-player-react'
import { useContext, useState, useRef, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'

import ProcessingFailed from './processing-failed'
import ProcessingProgress from './processing-progress'
import VideoPlaceholder from './video-placeholder'

import { SimpleButton } from '@/components/button/simple-button'
import { Highlights } from '@/components/highlights'
import { CircularLoadingIndicator } from '@/components/loading-indicator/CircularLoadingIndicator'
import { TitleBar } from '@/components/titlebar'
import { Toggle } from '@/components/toggle'
import VideoOverlay from '@/components/video-overlay'
import { useKeyNavigation, usePlaySkipDeadTime, useMuteState } from '@/hooks/use-player'
import { useWorkflowAborted } from '@/pages/home/hooks/use-workflow-aborted'
import { usePreferMuted } from '@/store/auth'
import { sawVideo, useVideoExcerpt } from '@/store/library'
import { APIContext } from '@/utils/api'
import cls from '@/utils/classnames'
import COLORS from '@/utils/colors'
import { formatDate, formatWithTimeConfig } from '@/utils/helper'

const VideoPlayer = (props) => {
  const { children, disableDeadTime, jumpToVideoTime, onRateChange, rallyNumber, showDeadTimeToggle, showHighlights, showRallyNav, showTheaterControl } = props
  const dispatch = useDispatch()
  const params = useParams()
  const vid = params.vid
  const { setRallyNumber, muxPlayerRef, insights, insightsVersion, isInsightsVersionOkay, video, workflow, videoMetadata = {} } = useContext(APIContext)
  const { alertWhenWorkflowIsAborted } = useWorkflowAborted(workflow, video)
  const { videoExcerpt } = useVideoExcerpt(vid)
  const isVideoMuted = usePreferMuted()
  const [isTheaterMode, setIsTheaterMode] = useState(false)
  const [skipDeadTime, setSkipDeadTime] = useState(true)
  const [isProcessingFailed, setIsProcessingFailed] = useState(false)
  const [isVerticalVideo, setIsVerticalVideo] = useState(false)

  const shadowRefs = useRef()
  const theaterModeToggleRef = useRef(null)

  const { width, height } = videoMetadata.metadata
  const aspectRatio = width / height
  const posterURL = `https://storage.googleapis.com/${import.meta.env.VITE_PRO_BUCKET}/${vid}/poster.jpg`
  const rallies = insights?.rallies
  const isPosterReady = !video.noPoster
  const isVideoReadyToStream = Boolean(!video.mux?.notReady)
  const hasServerComputedInsights = Boolean(workflow && !workflow?.noInsights)
  const isWorkflowDone = Boolean(workflow?.epochFinished)
  const isWorkflowStarted = Boolean(workflow)

  usePlaySkipDeadTime(disableDeadTime, muxPlayerRef, insights?.rallies, rallyNumber, setRallyNumber, skipDeadTime)
  useKeyNavigation(muxPlayerRef)
  useMuteState(muxPlayerRef, isVideoMuted)

  function jumpToRally (rallyIdx) {
    const nextRally = rallies[rallyIdx - 1]
    if (nextRally) {
      const newCurrentTime = nextRally.start_ms
      muxPlayerRef.current.currentTime = newCurrentTime / 1000
      setRallyNumber(rallyIdx)
    }
  }

  shadowRefs.current = {
    isTheaterMode,
    setIsTheaterMode
  }

  function isFullScreen () {
    return !!(
      document.fullscreenElement ||
      document.webkitFullscreenElement ||
      document.mozFullScreenElement ||
      document.msFullscreenElement
    )
  }

  function registerTheaterModeControl () {
    if (theaterModeToggleRef.current) return
    // retrieve the elements we need to manipulate
    const muxPlayerEl = document.querySelector('mux-player')
    const muxMediaEl = muxPlayerEl.shadowRoot.querySelector('media-theme')
    const controllerEl = muxMediaEl.shadowRoot.querySelector('media-controller')
    const controlBarEl = controllerEl.querySelectorAll('media-control-bar')[1]
    if (!controlBarEl) {
      return
    }
    const fullScreenBtn = controlBarEl.querySelector('media-fullscreen-button')

    // overflow fix, known issue with mux-player / media-chrome (https://github.com/muxinc/elements/issues/865)
    const mediaThemeStyles = controlBarEl.shadowRoot.ownerDocument.createElement('style')
    mediaThemeStyles.innerHTML = `
      media-theme {
        overflow: hidden;
      }
    `
    muxPlayerEl.shadowRoot.insertBefore(mediaThemeStyles, muxPlayerEl.shadowRoot.firstChild)
    // add styles for the theater mode toggle
    const theaterModeToggleStyles = controlBarEl.shadowRoot.ownerDocument.createElement('style')
    theaterModeToggleStyles.innerHTML = `
      theater-mode-toggle:hover {
        background-color: #13d208;
      }
      theater-tooltip {
        background-color: #1c2028;
        border-radius: 4px;
        display: block;
        font-size: 13px;
        height: 23px;
        line-height: 23px;
        pointer-events: none;
        position: absolute;
        text-align: center;
        top: -41px;
        transform: translateX(-24px);
        visibility: hidden;
        width: 146px;
        z-index: 1;
      }
      theater-mode-toggle:hover theater-tooltip {
        visibility: visible !important;
      }
    `
    muxMediaEl.shadowRoot.insertBefore(theaterModeToggleStyles, muxMediaEl.shadowRoot.firstChild)
    // create the theater mode toggle
    const theaterModeToggle = muxMediaEl.shadowRoot.ownerDocument.createElement('theater-mode-toggle')
    theaterModeToggle.style = 'width: 30px; height: 26px; border-radius: 4px; cursor: pointer; display: flex; justify-content: center; align-items: center;position: relative;'
    const toggleIcon = muxMediaEl.shadowRoot.ownerDocument.createElement('theater-mode-toggle-icon')
    toggleIcon.style = 'width: 18px; height: 14px; border: 1px solid white; border-radius: 4px'
    theaterModeToggle.appendChild(toggleIcon)
    const toggleTooltip = muxMediaEl.shadowRoot.ownerDocument.createElement('theater-tooltip')
    toggleTooltip.textContent = 'Toggle theater mode'
    theaterModeToggle.appendChild(toggleTooltip)

    theaterModeToggle.onclick = () => {
      const { isTheaterMode, setIsTheaterMode } = shadowRefs.current
      if (isFullScreen()) {
        document.exitFullscreen()
        setIsTheaterMode(true)
      } else {
        try {
          document.exitPictureInPicture()
        } catch {
          // ignore: the function throws if picture-in-picture isn't present
        }
        setIsTheaterMode(!isTheaterMode)
      }
    }
    controlBarEl.insertBefore(theaterModeToggle, fullScreenBtn)
    theaterModeToggleRef.current = theaterModeToggle
  }

  function onPlay () {
    if (showTheaterControl) {
      registerTheaterModeControl()
    }
  }

  function getContent () {
    if (hasServerComputedInsights) {
      // if the insights data is an older version, show an error message
      if (isInsightsVersionOkay === false) {
        return (
          <div>
            Insights data is version {insightsVersion} but we only support 2.x.
          </div>
        )
      }

      if (!insights) {
        // API to load insights data hasn't returned the data yet
        return (
          <CircularLoadingIndicator label='Loading insights...' estimatedSecsToFinish={2} />
        )
      }

      // otherwise show children if any
      return children
    } else {
      if (isWorkflowDone) {
        // if workflow is done but insights isn't ready, then processing failed
        !isProcessingFailed && setIsProcessingFailed(true)
        return <ProcessingFailed video={video} workflow={workflow} />
      } else if (!isWorkflowStarted) {
        // no workflow has started yet (but one should start soon, it happens a
        // few seconds after the video is uploaded)
        return (
          <CircularLoadingIndicator label='Our AI will start processing your video shortly.' estimatedSecsToFinish={5} />
        )
      }
    }
  }

  function checkAspect () {
    if (muxPlayerRef.current) {
      setIsVerticalVideo(muxPlayerRef.current.videoHeight > muxPlayerRef.current.videoWidth)
    }
  }

  useEffect(() => {
    if (videoExcerpt?.new) {
      dispatch(sawVideo(vid))
    }
  }, [dispatch, vid, videoExcerpt?.new])

  if (isVideoReadyToStream) {
    return (
      <div className={cls('wrapper', isTheaterMode && 'theater-mode', isVerticalVideo && 'portrait-video')}>
        <TitleBar
          name={videoExcerpt?.name ?? video.userData.name}
          date={formatDate(video.userData.gameStartEpoch, formatWithTimeConfig, undefined, true)}
          video={video}
          workflow={workflow}
          isWorkflowDone={isWorkflowDone}
          videoExcerpt={videoExcerpt}
        />
        <div className='mux-container' style={{ aspectRatio }}>
          <div className='mux-container__video'>
            <MuxPlayer
              hotkeys='noarrowleft noarrowright'
              playbackId={video.mux?.playbackId}
              placeholder={posterURL}
              playbackRates={[0.25, 0.5, 0.75, 1.0, 1.25, 1.5, 2.0, 3.0]}
              accent-color={COLORS['primary-500']}
              onPlay={onPlay}
              ref={muxPlayerRef}
              forward-seek-offset='5'
              backward-seek-offset='5'
              onRateChange={onRateChange}
              onLoadedMetadata={checkAspect}
              muted={isVideoMuted}
              style={{ aspectRatio }}
            />
            <VideoOverlay
              muxPlayerRef={muxPlayerRef}
              rallyNumber={rallyNumber}
              insights={insights}
              vid={vid}
            />
            {alertWhenWorkflowIsAborted()}
          </div>
          {(showRallyNav && !isProcessingFailed) && (
            <div className='mux-footer'>
              <div className='rally-number'>
                Rally #{rallyNumber}
                <SimpleButton sx={{ color: 'inherit' }} onClick={() => jumpToRally(rallyNumber - 1)} disabled={!insights?.rallies || rallyNumber === 1}>
                  <SkipPreviousIcon />
                </SimpleButton>
                <SimpleButton sx={{ color: 'inherit' }} onClick={() => jumpToRally(rallyNumber + 1)} disabled={!insights?.rallies || rallyNumber === insights?.rallies.length}>
                  <SkipNextIcon />
                </SimpleButton>
              </div>
              {showDeadTimeToggle && (
                <Toggle
                  checked={skipDeadTime}
                  onChange={(checked) => setSkipDeadTime(checked)}
                  label='Remove Idle Time'
                  tooltip='Automatically skip time in between rallies.'
                />
              )}
            </div>
          )}
        </div>
        {showHighlights &&
          <Highlights
            videoExcerpt={videoExcerpt}
            userData={video.userData}
            insights={insights}
            onHighlightClicked={highlight => jumpToVideoTime(highlight.s / 1000)}
          />}
        {workflow && !isWorkflowDone && (
          <ProcessingProgress
            vid={vid}
            workflowId={workflow.workflowId}
            workflowIndex={0}
          />
        )}
        <div className='insights-container'>
          {getContent()}
        </div>
      </div>
    )
  }

  if (isPosterReady) {
    return <img className='solo-poster' src={posterURL} />
  }

  if (!isWorkflowDone) {
    return (
      <VideoPlaceholder>
        <CircularLoadingIndicator label='This video was recently uploaded. We’re still getting your video ready to watch.' />
      </VideoPlaceholder>
    )
  }
}

export default VideoPlayer
