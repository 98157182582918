import { useMemo } from 'react'

import { players } from './helpers'

/**
 * Check if the filter is empty
 * @param {Object} filters - Object containing filter options.
 * @return {boolean} - True if the filter is empty, otherwise false.
 */
export const useIsFilterEmpty = (filters) => {
  return useMemo(() => {
    if (filters.characteristics.length) return false
    if (filters.customTags.length) return false
    if (filters.highlights.length) return false
    if (filters.sequences.length) return false
    if (filters.types.length) return false
    if (filters.strokeSide !== null) return false
    if (filters.quality.min !== 0 || filters.quality.max !== 1) return false
    if (filters.shotWindow.numBefore !== -1 || filters.shotWindow.numAfter !== 1) return false
    if (!filters.players.length || players.some((p) => !filters.players.includes(p))) return false
    return true
  }, [filters])
}
