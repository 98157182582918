import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import StarsIcon from '@mui/icons-material/Stars'
import { styled } from '@mui/material'

import Button from './button'

import StripeDialog from '@/components/dialog/stripe-dialog'
import { useIsBuyingEnabled, useMonetizationPricing } from '@/hooks/monetization'
import { usePurchaseFlow } from '@/hooks/use-purchase-flow'
import COLORS from '@/utils/colors'
import { column, row } from '@/utils/flexGrid'

/**
 * A PricingCard component that displays pricing information, features, and a call to action button.
 *
 * @component
 * @param {Object} props - The properties passed to the component.
 * @param {string} props.title - The title of the pricing plan.
 * @param {number} props.fullPrice - The original full price of the plan.
 * @param {number} props.discountPrice - The discounted price of the plan.
 * @param {number} props.annually - The annual billing amount.
 * @param {string} props.subtitle - The subtitle providing more details about the plan.
 * @param {Array<Object|string>} props.features - A list of features that the plan offers, with each feature being an object or a string.
 *                                               If the feature is hot, it should be an object with a `hot` property set to `true` and a `desc` property for its description.
 *
 * @returns {JSX.Element} The rendered PricingCard component.
 *
 * @example
 * const features = [
 *    'Tag other players to share insights for free',
 *    { hot: true, desc: 'Explore shot by shot analysis with Shot Explorer' },
 * ];
 *
 * <PricingCard
 *    title="Basic Plan"
 *    fullPrice={100}
 *    discountPrice={50}
 *    annually={500}
 *    subtitle="Best for beginners"
 *    features={features}
 * />
 */
const PricingCard = (props) => {
  const { title, fullPrice, subtitle, features, planKey } = props
  const { clearSKUToBuy, skuToBuy, subscribeToPlan } = usePurchaseFlow()
  const { getAnnuallyCostByPlan, getMonthlyCostByPlan } = useMonetizationPricing()
  const isBuyingEnabled = useIsBuyingEnabled()

  const handleTryButton = () => {
    subscribeToPlan(planKey)
  }

  const handleStripeClose = () => {
    clearSKUToBuy()
  }

  return (
    <Container>
      <div className='p-title'>{title}</div>
      <div>
        <div className='full-price'>
          ${fullPrice}
        </div>
        <div className='discount-price'>
          ${getMonthlyCostByPlan(planKey)}/mo
        </div>
      </div>
      <div className='billed-annually'>
        Billed at ${getAnnuallyCostByPlan(planKey)} annually
      </div>

      <Button onClick={handleTryButton} color='success' fullWidth>
        {isBuyingEnabled ? 'Subscribe Now' : 'Coming Soon'}
      </Button>

      <div className='p-subtitle'>
        {subtitle}
      </div>
      <div className='features'>
        {features.map((f, i) =>
          f.hot
            ? (
              <div key={i} className='hot'>
                <StarsIcon />
                <span>{f.desc}</span>
              </div>
              )
            : (
              <div key={i}>
                <CheckCircleIcon />
                <span>{f}</span>
              </div>
              )
        )}
      </div>

      <StripeDialog isOpen={Boolean(skuToBuy)} skuToBuy={skuToBuy} onClose={handleStripeClose} />
    </Container>
  )
}

const Container = styled('div')({
  ...column,
  // width: 262,
  padding: '24px 16px',
  border: `1px solid ${COLORS['neutral-300']}`,
  borderRadius: 8.533,
  backgroundColor: '#fff',
  color: '#000',
  gap: 10,

  '& .p-title': {
    fontSize: 20.111,
    fontWeight: 700,
    lineHeight: '138%'
  },
  '& .full-price': {
    color: COLORS['neutral-500'],
    display: 'inline',
    fontSize: 20,
    fontWeight: 700,
    lineHeight: '142%',
    marginRight: 10,
    textDecoration: 'line-through'
  },
  '& .discount-price': {
    display: 'inline',
    fontSize: 40,
    fontWeight: 700,
    lineHeight: '142%'
  },
  '& .billed-annually': {
    color: COLORS['neutral-500'],
    fontSize: 12,
    fontWeight: 600,
    lineHeight: '166%'
  },
  '& .no-card': {
    color: COLORS['neutral-800'],
    textAlign: 'center',
    fontSize: 14.365,
    fontWeight: 700,
    lineHeight: '138%'
  },
  '& .p-subtitle': {
    color: COLORS['neutral-500'],
    fontSize: 12,
    fontWeight: 600,
    lineHeight: '166%'
  },
  '& .features': {
    ...column,
    fontSize: 14,
    fontWeight: 400,
    lineHeight: '142%',
    gap: 12,

    '& > div': {
      ...row,
      alignItems: 'center',
      gap: 10,
      '&.hot svg': {
        color: COLORS.player3
      },
      '& span': {
        padding: 4
      }
    },
    '& svg': {
      color: COLORS['primary-500'],
      marginLeft: 4
    }
  },
  // Generic helper classes
  '& [data-orange]': {
    color: COLORS['warning-600']
  },
  '& [data-green]': {
    color: COLORS['primary-500']
  },
  '& .hot': {
    background: COLORS['warning-050']
  }
})

export default PricingCard
