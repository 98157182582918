// import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import StarIcon from '@mui/icons-material/Star'
import { styled } from '@mui/material'
import React, { useState } from 'react'

import CreditPack from '../credit-pack'
import StripeDialog from '../dialog/stripe-dialog'

// import GiftPaymentForm from './gift-payment-form'

import PBV30Img from '@/assets/images/30pbv.png'
import PBV5Img from '@/assets/images/5pbv.png'
import BucketImg from '@/assets/images/bucket-180pbv.png'
import cls from '@/utils/classnames'
import COLORS from '@/utils/colors'
import { column, row } from '@/utils/flexGrid'
import theme from '@/utils/theme'

const CARD_WIDTH = 174
const CARD_SPACING = theme.spacing(3)

// const selectedButtonProps = { startIcon: <ArrowBackIcon style={{ color: COLORS['neutral-400'] }} />, style: { margin: 0, background: 'transparent', color: COLORS['neutral-400'], padding: '16px 0' } }

export default function GiftItems ({ hasAnimation = true, style }) {
  const [selectedPack, setSelectedPack] = useState(false)

  const handlePackSelection = (sku) => {
    if (selectedPack) {
      setSelectedPack(false)
    } else {
      setSelectedPack(sku)
    }
  }

  return (
    <Container style={style}>
      {/* <Container style={{ marginBottom: selectedPack ? 600 : 70 }}> */}
      {GIFT_OPTIONS.map((gift, i) => (
        <CreditPack
          key={i}
          {...gift}
          buttonText='Buy'
          // buttonText={selectedPack === gift.sku ? 'Change Selection' : 'Buy'}
          // buttonProps={selectedPack === gift.sku ? selectedButtonProps : {}}
          onClick={() => handlePackSelection(gift.sku)}
          className={cls(gift.className, selectedPack === gift.sku ? 'selected' : selectedPack && 'hidden', hasAnimation && 'animated')}
          // style={{
          //   '--move-distance': i,
          //   '--gap-width': `${(i * CARD_SPACING.slice(0, -2))}px`,
          //   order: i
          // }}
        />
      ))}
      <StripeDialog skuToBuy={selectedPack} isOpen={Boolean(selectedPack)} onClose={() => { setSelectedPack(false) }} />
      {/* <GiftPaymentForm className={cls(selectedPack && 'visible')} sku={selectedPack} style={{ position: 'absolute', left: CARD_WIDTH + Number(CARD_SPACING.slice(0, -2)), right: 0 }} /> */}
    </Container>
  )
}

export const GIFT_OPTIONS = [
  {
    packName: '10 Credits',
    days: 30,
    packDescription: 'Plus 1 month free Starter access',
    price: 50,
    percentOff: 50,
    sku: 'gift10',
    Image: <img src={PBV5Img} alt='10 Credit' />
  },
  {
    packName: '20 Credits',
    days: 60,
    packDescription: <>Plus <strong>2 month</strong> free Starter access</>,
    price: 100,
    percentOff: 50,
    sku: 'gift20',
    Image: <img src={PBV30Img} alt='20 Credit' />
  },
  {
    packName: '60 Credits',
    days: 366,
    packDescription: <>Plus <strong>1 year</strong> free Starter access</>,
    price: 180,
    percentOff: 70,
    sku: 'gift60',
    Image: <img src={PBV30Img} alt='60 Credit' />,
    prefix: '⭐ MOST POPULAR ⭐',
    containerStyle: { transform: 'scaleY(1.05)', backgroundColor: COLORS.white }
  },
  {
    packName: '180 Credits',
    days: 366,
    packDescription: <>Plus 1 year free <strong>Premium</strong> access</>,
    price: 396,
    percentOff: 88,
    sku: 'gift180',
    prefix: <><StarIcon fontSize='12px' style={{ color: COLORS.white }} /> BEST VALUE <StarIcon fontSize='12px' style={{ color: COLORS.white }} /></>,
    Image: <img src={BucketImg} alt='180 Credit' />,
    className: 'premium'
  }
]

const Container = styled('div')({
  ...row,
  width: '100%',
  maxWidth: '800px',
  position: 'relative',
  flexWrap: 'wrap',
  gap: CARD_SPACING,
  transition: 'all 0.5s ease',
  marginBlock: 70,
  '& > .credit-pack': {
    width: CARD_WIDTH,
    height: 'fit-content',
    transition: 'opacity 0.3s ease, transform 0.5s ease',
    '&.animated.selected': {
      opacity: 1,
      transform: 'translateX(calc(-100% * var(--move-distance) - var(--gap-width) ))'
    },
    '&.animated.hidden': {
      opacity: 0,
      pointerEvents: 'none'
    },

    '&.animated.visible': {
      display: 'flex'
    }
  },
  '& .gift-payment-form': {
    top: -50,
    opacity: 0,
    zIndex: -1,
    transition: 'all 0.5s ease',
    '&.visible': {
      opacity: 1,
      zIndex: 1,
      top: 0
    }
  },
  [theme.breakpoints.down('md')]: {
    paddingInline: 16,
    '& .gifts': {
      ...column,
      alignItems: 'center',
      gap: 26,
      '& > div': {
        width: '80vw'
      }
    },
    '& .gift-ball': {
      width: '300px',
      height: '300px'
    }
  }
})
