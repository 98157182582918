import AssessmentIcon from '@mui/icons-material/Assessment'
import CenterFocusStrongIcon from '@mui/icons-material/CenterFocusStrong'
import CloseIcon from '@mui/icons-material/Close'
import MenuIcon from '@mui/icons-material/Menu'
import SchoolIcon from '@mui/icons-material/School'
import { styled } from '@mui/material'
import MuiAppBar from '@mui/material/AppBar'
import MuiDrawer from '@mui/material/Drawer'
import IconButton from '@mui/material/IconButton'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import MenuList from '@mui/material/MenuList'
import Toolbar from '@mui/material/Toolbar'
import { useEffect, useRef, useState } from 'react'
import { Link, useLocation, useMatch, useNavigate } from 'react-router-dom'

import { SimpleButton } from '../button/simple-button'
import GetOnMobile from '../get-on-mobile/get-on-mobile'

import DropDownArrow from '@/assets/arrow-dropdown.svg?react'
import HelpOutlineIcon from '@/assets/help-outline.svg?react'
import PBVGiftBall from '@/assets/images/bow-ball2.png'
import Logo from '@/assets/pb-vis-horiz-logo.svg?react'
import { TextButton } from '@/components/button/text-button'
import { useFaviconBadge } from '@/components/navbar/use-favicon-badge'
import { UploadsContainer } from '@/components/upload'
import { UploadButton, AnonymousUploadButton } from '@/components/upload/UploadButton'
import { useAvatar } from '@/hooks/use-avatar'
import { useDeviceOS } from '@/hooks/use-device-os'
import useMobileDetect from '@/hooks/use-mobile-detect'
import useZendesk from '@/hooks/use-zendesk'
import { DEMO_VIDEOS } from '@/pages/anonymous-upload'
import { useIsLoggedIn } from '@/store/auth'
import { useUnseenVIDs } from '@/store/library'
import { useHasAnonymouslyUploadedVideoPending } from '@/store/uploads'
import cls from '@/utils/classnames'
import COLORS from '@/utils/colors'
import { column, row } from '@/utils/flexGrid'
import { isInMobileAppWebview } from '@/utils/mobile-app-communication'

const AppBar = styled(MuiAppBar)({
  backgroundColor: COLORS['neutral-100'],
  borderBottom: `1px solid ${COLORS['neutral-300']}`,
  // Height includes border so it's actually 48 (47 + 1)
  minHeight: '47px !important',
  padding: '0 32px 0 20px',
  zIndex: 1000,
  position: 'relative',
  '.MuiToolbar-root': {
    minHeight: '47px !important',
    '&.anonymous': {
      minHeight: '79px !important',
      '&.mobile-menu': {
        minHeight: '56px !important'
      }
    }
  },
  '.grow': {
    alignItems: 'center',
    display: 'flex',
    flex: 1,
    gap: '16px',
    justifyContent: 'flex-end',
    '& > svg': {
      marginLeft: '24px'
    },
    '.help': {
      padding: 0,
      backgroundColor: 'unset'
    },
    a: {
      lineHeight: 0
    }
  },
  '& .logo-container': {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minWidth: 130,
    marginLeft: '24px'
  },
  '& .video-lib': {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    backgroundColor: COLORS['neutral-200'],
    color: COLORS['neutral-700'],
    fontSize: 14,
    height: '47px',
    fontWeight: 600,
    marginLeft: '24px',
    marginRight: 'auto',
    minWidth: 130,
    textDecoration: 'none',
    '&.pbv-logo': {
      backgroundColor: 'unset'
    },
    '& .unseen-counter': {
      position: 'absolute',
      top: '4px',
      right: '3px',
      padding: '3px',
      minWidth: '17px',
      aspectRatio: 1,
      lineHeight: '10px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: 'red',
      color: 'white',
      fontSize: '14px',
      fontWeight: '500',
      borderRadius: '100%'
    }
  },
  '& .pb-vision': {
    width: 118
  },
  '& .logo': {
    maxWidth: 232
  },
  '& .sign-in, .recording-tips, .blog': {
    fontFamily: 'Inter',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '166%',
    color: COLORS['text-tertiary'],
    padding: '0 12px',
    textDecoration: 'none'
  },
  '& .sign-in': {
    color: COLORS['primary-500']
  },
  '& .upload-btn': {
    fontSize: 12
  },
  '&.sticky': {
    position: 'sticky',
    top: 0,
    zIndex: 1000
  },
  '& .mobile-menu-btn': {
    marginLeft: 'auto',
    backgroundColor: '#fff',
    borderRadius: 6.667,
    border: `1.25px solid ${COLORS['neutral-300']}`,
    width: 40,
    height: 40,
    '& svg': {
      fontSize: '25px'
    }
  },
  '& .anonymous': {
    marginRight: 0,

    // Logo
    '& .pbv-logo': {
      marginRight: 32,
      '& svg': {
        height: 34,
        width: 162,
        maxWidth: 'unset'
      }
    }
  },
  '& .avatar': {
    fontSize: '33px'
  },
  // Mobile styles
  '&.mobile': {
    padding: '0 15px 0 15px',
    '& .logo': {
      maxWidth: 110,
      marginRight: '10px'
    },
    '.grow': {
      gap: '10px'
    },
    '& .video-lib': {
      backgroundColor: 'unset',
      marginLeft: '0'
    },
    '& .logo-container': {
      marginLeft: '0'
    }
  }
})

const Drawer = styled(MuiDrawer)({
  '& .MuiPaper-root': {
    backgroundColor: COLORS['neutral-100'],
    padding: 16,
    width: 300,

    // Anonymous menu
    '& > .mobile': {
      opacity: 1,
      flexGrow: 1,
      '& button': {
        marginBottom: 16,
        '&:last-of-type': {
          marginLeft: 16
        }
      },
      '& .sign-in': {
        marginTop: 'auto',
        marginLeft: 16,
        marginBottom: 16
      },
      '& .gift': {
        marginTop: 32,
        marginBottom: 16
      },
      '.menu-opened': {
        backgroundColor: 'unset'
      }
    }
  },
  '& .close-icon': {
    color: 'unset',
    display: 'flex',
    position: 'absolute',
    top: 8,
    right: 8
  }
})

const AnonymousMenu = styled('div')({
  ...row,
  '& .MuiButton-textPrimary': {
    color: COLORS['text-tertiary'],
    padding: '8px 16px',
    fontWeight: 600,
    marginRight: '16px',
    lineHeight: '166%',

    '& svg': {
      transform: 'rotate(0)',
      color: COLORS['primary-500']
    },
    '&.menu-opened': {
      backgroundColor: COLORS['neutral-200'],
      borderRadius: 8,
      '& svg': {
        transform: 'rotate(180deg)'
      }
    }
  },
  '&.mobile': {
    ...column,
    '& button': {
      justifyContent: 'flex-start',
      '& em': {
        color: '#fff'
      }
    }
  }
})

const GiftButton = styled(TextButton)({
  background: 'linear-gradient(285deg, #E9BA38 4.39%, #FFE854 86.49%)',
  borderRadius: '8px',
  position: 'relative',
  marginRight: '48px !important',
  height: 43,
  width: 111,
  '&:hover, &:active, &:focus': {
    background: 'linear-gradient(285deg, #E9BA38 4.39%, #FFE854 86.49%)'
  },
  '&:hover, &:active': {
    opacity: 0.9
  },
  '& img': {
    position: 'absolute',
    right: -29,
    top: 10,
    width: 35,
    height: 27
  }
})

function AnonymousUploadToolbar ({ isDesktop }) {
  const isLoggedIn = useIsLoggedIn()
  const hasUnclaimedAnonymousUpload = useHasAnonymouslyUploadedVideoPending()
  const logoLink = (isInMobileAppWebview() || isLoggedIn || hasUnclaimedAnonymousUpload) ? '/library' : '/'

  return (
    <Toolbar disableGutters>
      <Link to={logoLink} className='video-lib pbv-logo anonymous'>
        {!isDesktop ? <Logo className='logo' /> : <Logo className='pb-vision' />}
      </Link>
      {isLoggedIn && <Link to={logoLink} className='video-lib'>Video Library</Link>}
    </Toolbar>
  )
}

function MenuComponent (props) {
  const { isMobile, id, sx, children, ...rest } = props
  return isMobile ? <MenuList id={id} sx={sx}>{children}</MenuList> : <Menu id={id} sx={sx} {...rest}>{children}</Menu>
}

function AnonymousToolbar (props) {
  const { isMobile, setDrawerOpen, isLearnOpen, setIsLearnOpen } = props
  const navigate = useNavigate()
  const menuAnchor = useRef()
  const location = useLocation()

  const jumpTo = (path) => {
    setDrawerOpen(false)
    setIsLearnOpen()
    navigate(path)
  }
  const toggleMenu = (evt) => {
    evt.stopPropagation()
    setIsLearnOpen(!isLearnOpen)
  }
  const closeDrawer = (evt) => {
    if (evt.target.closest('button') || evt.target.nodeName === 'A') {
      setDrawerOpen(false)
    }
  }

  useEffect(() => {
    if (location.pathname === '/subscribe' && location.hash === '#ambassador') {
      setTimeout(() => {
        const element = document.getElementById('ambassador-program')
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' })
        }
      }, 100)
    }
  }, [location])

  return (
    <AnonymousMenu className={cls(isMobile && 'mobile')} onClick={closeDrawer}>
      <GiftButton className='gift' onClick={() => navigate('/gift')}>
        Gift Cards
        <img src={PBVGiftBall} className='gift-ball' />
      </GiftButton>
      <TextButton
        ref={menuAnchor}
        aria-controls='learn-menu'
        aria-haspopup='true'
        onClick={toggleMenu}
        className={cls(isLearnOpen && 'menu-opened')}
        endIcon={<DropDownArrow />}
      >
        Learn
      </TextButton>
      {isLearnOpen && (
        <MenuComponent
          id='learn-menu'
          anchorEl={menuAnchor.current}
          open={isLearnOpen}
          onClose={() => setIsLearnOpen()}
          isMobile={isMobile}
          sx={{ '& .MuiMenuItem-root': { color: COLORS['neutral-700'] }, '& .MuiPaper-root': { marginTop: '8px', borderRadius: '8px', border: `1px solid  ${COLORS['neutral-300']}` }, '& svg': { marginRight: 1 } }}
        >
          <MenuItem component='a' href='https://help.pb.vision/articles/1108176' target='_blank'>
            <CenterFocusStrongIcon />
            Recording Tips
          </MenuItem>
          <MenuItem component='a' href='https://help.pb.vision/' target='_blank'>
            <SchoolIcon />
            Knowledge Base
          </MenuItem>
          <MenuItem onClick={() => jumpTo(`/video/${DEMO_VIDEOS[0].vid}`)}>
            <AssessmentIcon />
            Demo
          </MenuItem>
        </MenuComponent>
      )}
      <TextButton onClick={() => jumpTo('/subscribe#ambassador')}>
        Ambassadors
      </TextButton>
      <TextButton onClick={() => jumpTo('/partner')}>
        Clubs
      </TextButton>
      <TextButton onClick={() => jumpTo('/subscribe')}>
        Pricing
      </TextButton>
      {isMobile && (
        <Link to='/login' className='sign-in'>
          Sign In
        </Link>
      )}
      {isMobile && <AnonymousUploadButton className='green upload-btn' label='Get Started' />}
    </AnonymousMenu>
  )
}

function ToolbarWrapper (props) {
  const { isDrawerOpen, setDrawerOpen, isLearnOpen, setIsLearnOpen, logoLink } = props

  return (
    <>
      <Drawer open={isDrawerOpen} onClose={() => setDrawerOpen(false)} ModalProps={{ keepMounted: true }}>
        <SimpleButton className='close-icon' onClick={() => setDrawerOpen(false)}>
          <CloseIcon />
        </SimpleButton>
        <Link to={logoLink} className='video-lib pbv-logo'><Logo className='logo' width='162' height='34' /></Link>
        <AnonymousToolbar setDrawerOpen={setDrawerOpen} isLearnOpen={isLearnOpen} setIsLearnOpen={setIsLearnOpen} isMobile />
      </Drawer>
      <IconButton className='mobile-menu-btn' variant='outlined' onClick={() => setDrawerOpen(true)}>
        <MenuIcon />
      </IconButton>
    </>
  )
}

function NavToolbar (props) {
  const { isDesktop } = props
  const isMobile = useMobileDetect()
  const { deviceOS } = useDeviceOS()
  const { toggleZendeskWidget } = useZendesk()
  const isLoggedIn = useIsLoggedIn()
  const location = useLocation()
  const isOnSettingsPage = location.pathname.startsWith('/settings/')
  const hasUnclaimedAnonymousUpload = useHasAnonymouslyUploadedVideoPending()
  const unseenVIDsResult = useUnseenVIDs()
  const unseenCount = unseenVIDsResult.unseenVIDs.size
  useFaviconBadge({ unseenCount, isReady: unseenVIDsResult.isReady })
  const { UserAvatar } = useAvatar()
  const [isLearnOpen, setIsLearnOpen] = useState(false)
  const [isDrawerOpen, setDrawerOpen] = useState(false)

  const logoLink = (isInMobileAppWebview() || isLoggedIn || hasUnclaimedAnonymousUpload) ? '/library' : '/'
  const UploadBtn = isLoggedIn ? UploadButton : AnonymousUploadButton
  const willShowMobileMenu = !isLoggedIn && !isDesktop

  return (
    <Toolbar disableGutters className={cls(!isLoggedIn && 'anonymous', willShowMobileMenu && 'mobile-menu')}>
      <Link to={logoLink} className='video-lib pbv-logo'>
        {!isDesktop
          ? (
            <>
              <Logo className='logo' />
              {unseenVIDsResult.isReady && unseenCount > 0 && <div className='unseen-counter'>{unseenCount}</div>}
            </>
            )
          : (
            <Logo className='pb-vision' />
            )}
      </Link>
      {!isLoggedIn && (isDesktop ? <AnonymousToolbar setDrawerOpen={setDrawerOpen} isLearnOpen={isLearnOpen} setIsLearnOpen={setIsLearnOpen} /> : <ToolbarWrapper isDrawerOpen={isDrawerOpen} setDrawerOpen={setDrawerOpen} isDesktop={isDesktop} logoLink={logoLink} isLearnOpen={isLearnOpen} setIsLearnOpen={setIsLearnOpen} />)}
      {isLoggedIn && isDesktop && location.pathname !== '/' && (
        <Link to='/library' className='video-lib'>
          Video Library
          {unseenVIDsResult.isReady && unseenCount > 0 && <div className='unseen-counter'>{unseenCount}</div>}
        </Link>
      )}
      {!willShowMobileMenu && (
        <div className='grow'>
          {!isLoggedIn && location.pathname !== '/login' && (
            <Link to='/login' className='sign-in'>
              Sign In
            </Link>
          )}
          {!isOnSettingsPage && !isMobile && <UploadBtn className='green upload-btn' label={isLoggedIn ? 'Upload Video' : 'Get Started'} />}
          {isMobile && deviceOS !== 'other' && <GetOnMobile style={{ height: '40px', width: '120px', borderRadius: '4px' }} />}
          {isLoggedIn && location.pathname !== '/' && (
            <IconButton className='help' onClick={toggleZendeskWidget}>
              <HelpOutlineIcon />
            </IconButton>
          )}
          {isLoggedIn && (
            <Link to='/settings/main'>
              <UserAvatar className='avatar' />
            </Link>
          )}
        </div>
      )}
    </Toolbar>
  )
}

/**
 * BaseNavbar component
 *
 * @exports
 * @returns {React.ReactElement}
 */
export function BaseNavbar () {
  const location = useLocation()
  const isLoggedIn = useIsLoggedIn()
  const matchUpload = useMatch('/anonymous-upload')
  const BaseToolbar = !isLoggedIn && matchUpload ? AnonymousUploadToolbar : NavToolbar
  const Uploads = matchUpload ? null : <UploadsContainer />
  const isDesktop = useMobileDetect('desktop')

  return (
    <AppBar elevation={0} position='static' className={cls(!isDesktop && 'mobile', location.pathname === '/' && 'sticky')}>
      <BaseToolbar isDesktop={isDesktop} />
      {Uploads}
    </AppBar>
  )
}
