import { authSlice } from './auth'

import { buildAPIsSlice } from '@/utils/api'

function transformLibraryResponse (resp) {
  return { videos: resp.videos, folders: resp?.folders, public: Boolean(resp?.public) }
}

export const apisSlice = buildAPIsSlice({
  apis: {
    getMyLibrary: {
      path: '/library/get',
      providesTags: (result, error, arg) => {
        return [{ type: 'VideoExcerpt', id: 'Library' }]
      },
      transformResponse: transformLibraryResponse
    },
    getLibraryFromVids: {
      path: '/library/get_by_vids',
      providesTags: (result, error, arg) => {
        return [{ type: 'VideoExcerpt', id: arg.vids.slice().sort().join(',') }]
      },
      transformResponse: transformLibraryResponse,
      isUserAPI: false
    },
    getPublicLibrary: {
      path: '/library/get_public',
      providesTags: (result, error, arg) => {
        return [{ type: 'VideoExcerpt', id: arg.uid }]
      },
      transformResponse: transformLibraryResponse,
      isUserAPI: false
    },
    updateLibrary: {
      path: '/library/update',
      onQueryStarted: async (queryArg, lifecycleProps) => {
        await patchLocalWrapper(queryArg, lifecycleProps, (uid, requestBody) => {
          const { dispatch, getState } = lifecycleProps
          // don't need to update library from VIDs because that API is only
          // used by anonymous users but the API to update a video requires the
          // user to be logged in but we do need to update the get my library
          // API data
          return updateMyLibraryWithChanges(
            dispatch, getState(), uid, requestBody.videos)
        })
      }
    },
    getVideo: {
      path: 'video/get_by_id',
      providesTags: (result, error, arg) => {
        return [{ type: 'Video', id: arg.vid }]
      },
      isUserAPI: false
    },
    updateVideo: {
      path: '/video/update',
      onQueryStarted: async (queryArg, lifecycleProps) => {
        await patchLocalWrapper(queryArg, lifecycleProps, (uid, requestBody) => {
          // build the changes that will flow through to the library's excerpt
          const { vid, ...changes } = requestBody
          const excerptChanges = {}
          for (const k of Object.keys(changes)) {
            if (k === 'gameStartEpoch') {
              excerptChanges.epoch = changes[k]
            } else if (k === 'name' || k === 'desc') {
              excerptChanges[k] = changes[k]
            }
          }

          // get the patch to the library (should be exactly one since we are
          // only updating one video, unless the library data has not been
          // fetched yet)
          const { dispatch, getState } = lifecycleProps
          const state = getState()
          const patchResults = updateMyLibraryWithChanges(
            dispatch, state, uid, [{ vid, ...excerptChanges }])

          // update the local video data too
          const getVideoCachedArgs = apisSlice.util.selectCachedArgsForQuery(
            state, 'getVideo')
          for (const args of getVideoCachedArgs) {
            if (args.vid === vid) {
              patchResults.push(dispatch(
                apisSlice.util.updateQueryData('getVideo', args, draft => {
                  if (!draft) {
                    console.log('missing draft', args)
                    return
                  }
                  const draftUserData = draft.userData
                  console.log('changing video', { draft: JSON.stringify(draftUserData), changes })
                  for (const [k, v] of Object.entries(changes)) {
                    if (v === null) {
                      // null tells the server to remove the (optional) field
                      delete draftUserData[k]
                    } else if (v !== undefined) {
                      // undefined tell the server to keep the old value so if
                      // it isn't undefined at this point then v is the new
                      // value
                      draftUserData[k] = v
                    }
                  }
                })
              ))
            }
          }
          return patchResults
        })
      }
    },
    getVideoMetadata: {
      path: 'video/get_metadata',
      providesTags: (result, error, arg) => {
        return [{ type: 'VideoMetadata', id: arg.vid }]
      },
      isUserAPI: false
    },
    tagUser: {
      path: '/user/tag',
      method: 'POST',
      providesTags: (result, error, arg) => {
        return [{ type: 'Tag', id: 'CreateTag' }]
      },
      onQueryStarted: async (queryArg, lifecycleProps) => {
        await patchLocalWrapper(queryArg, lifecycleProps, async (uid, requestBody) => {
          const { vid, playerIdx } = requestBody
          const { dispatch, queryFulfilled, getState } = lifecycleProps
          const state = getState()
          const patchResults = []

          const { data } = await queryFulfilled

          // update the local video data
          const getVideoCachedArgs = apisSlice.util.selectCachedArgsForQuery(
            state, 'getVideo')
          for (const args of getVideoCachedArgs) {
            if (args.vid === vid) {
              patchResults.push(dispatch(
                apisSlice.util.updateQueryData('getVideo', args, draft => {
                  const draftPlayers = draft.userData.players
                  draftPlayers[playerIdx] = data.playerInfo
                  for (let i = 0; i < 4; i++) {
                    const player = draftPlayers[i]
                    if (typeof player === 'undefined') {
                      draftPlayers[i] = {}
                    }
                  }
                })
              ))
            }
          }

          // update recentlyTagged list in authSlice
          dispatch(authSlice.actions.updateRecentlyTagged(data.recentlyTagged))

          return patchResults
        })
      }
    },
    removeTagFromRecent: {
      path: '/user/tag/remove_from_recent',
      method: 'POST',
      providesTags: (result, error, arg) => {
        return [{ type: 'Tag', id: 'RemoveFromRecent' }]
      },
      onQueryStarted: async (queryArg, lifecycleProps) => {
        await patchLocalWrapper(queryArg, lifecycleProps, async (uid, requestBody) => {
          const { dispatch, queryFulfilled } = lifecycleProps
          const { data } = await queryFulfilled
          // update recentlyTagged list in authSlice
          dispatch(authSlice.actions.updateRecentlyTagged(data.recentlyTagged))
        })
      }
    },
    lookupUser: {
      path: '/user/lookup',
      method: 'POST',
      providesTags: (result, error, arg) => {
        return [{ type: 'Lookup', id: 'LookupUser' }]
      },
      onQueryStarted: async (queryArg, { dispatch, queryFulfilled }) => {
        try {
          await queryFulfilled
          dispatch(apisSlice.util.invalidateTags(['Lookup']))
        } catch {
          // Handle any errors here if necessary
        }
      }
    },
    fetchCustomUserData: {
      path: '/user/me',
      providesTags: (result, error, arg) => {
        return [{ type: 'CustomUserData' }]
      },
      onQueryStarted: async (queryArg, lifecycleProps) => {
        const { queryFulfilled, dispatch } = lifecycleProps
        const { data } = await queryFulfilled

        // update customData in authSlice whenever is's fetched
        dispatch(authSlice.actions.loadedCustomData(data))
      },
      isUserAPI: true
    },
    reportReferralCode: {
      path: '/user/referred',
      method: 'POST',
      providesTags: (result, error, arg) => {
        return [{ type: 'Referral', id: arg.uid }]
      },
      isUserAPI: true
    },
    getCVGitHashForAIEngineVersion: {
      path: 'ai_engine/revision/get_cv_version',
      providesTags: (result, error, arg) => {
        return [{ type: 'CVVersion', id: arg.aiEngineVersion }]
      },
      isUserAPI: false
    },
    createFolder: {
      path: '/library/folder/create',
      method: 'POST',
      providesTags: (result, error, arg) => {
        return [{ type: 'Folder', id: 'NewFolder' }]
      },
      onQueryStarted: async (queryArg, { dispatch, queryFulfilled }) => {
        try {
          await queryFulfilled
          // Invalidate the library to refetch the data
          dispatch(apisSlice.util.invalidateTags(['VideoExcerpt']))
        } catch {
          // Handle any errors here if necessary
        }
      }
    },
    updateFolder: {
      path: '/library/folder/update',
      method: 'POST',
      providesTags: (result, error, arg) => {
        return [{ type: 'Folder', id: 'UpdateFolder' }]
      },
      onQueryStarted: async (queryArg, { dispatch, queryFulfilled }) => {
        try {
          await queryFulfilled
          // Invalidate the library to refetch the data
          dispatch(apisSlice.util.invalidateTags(['VideoExcerpt']))
        } catch {
          // Handle any errors here if necessary
        }
      }
    },
    removeFolder: {
      path: '/library/folder/remove',
      method: 'POST',
      providesTags: (result, error, arg) => {
        return [{ type: 'Folder', id: 'RemoveFolder' }]
      },
      onQueryStarted: async (queryArg, { dispatch, queryFulfilled }) => {
        try {
          await queryFulfilled
          // Invalidate the library to refetch the data
          dispatch(apisSlice.util.invalidateTags(['VideoExcerpt']))
        } catch {
          // Handle any errors here if necessary
        }
      }
    },
    videoReprocess: {
      path: '/video/reprocess',
      onQueryStarted: async (queryArg, lifecycleProps) => {
        const { queryFulfilled, dispatch } = lifecycleProps
        try {
          const { vid } = queryArg
          /**
           * The API should cause the number of VCs the user has to decrease by 1.
           * The API returns the new credits value
           */
          const { data } = await queryFulfilled
          await dispatch(authSlice.actions.updateCredits(data))

          /**
           * Invalidate the 'Video' tag to refetch the video data once when the user reprocess video
           *
           * To ensure that the server has enough time to handle reprocessing, we delay
           * the refetch of data for 1 second
           *
           */
          setTimeout(() => {
            dispatch(apisSlice.util.invalidateTags([{ type: 'Video', id: vid }]))
          }, 1000)
        } catch (err) {

        }
      },
      isUserAPI: true
    }
  },
  name: '',
  tagTypes: ['CVVersion', 'Video', 'VideoExcerpt', 'VideoMetadata', 'CustomUserData']
})

// this works with my library and get library by vids APIs
function updateLibraryWithChanges (currentVideos, updatesList) {
  const updates = {}
  for (const { vid, ...changes } of updatesList) {
    updates[vid] = changes
  }
  for (const video of currentVideos ?? []) {
    const changes = updates[video.vid]
    if (changes) {
      Object.assign(video, changes)
    }
  }
}

function updateMyLibraryWithChanges (dispatch, state, uid, updates) {
  const patchResults = []
  const getMyLibraryCachedArgs = apisSlice.util.selectCachedArgsForQuery(
    state, 'getMyLibrary')
  for (const args of getMyLibraryCachedArgs) {
    if (args.headers['x-uid'] === uid) {
      patchResults.push(dispatch(
        apisSlice.util.updateQueryData('getMyLibrary', args, draft => {
          const videos = draft.videos
          updateLibraryWithChanges(videos, updates)
        })
      ))
    }
  }
  return patchResults
}

async function patchLocalWrapper (queryArg, lifecycleProps, patchFunc) {
  const { headers, ...requestBody } = queryArg
  const { queryFulfilled } = lifecycleProps
  const uid = headers['x-uid']
  const patchResults = patchFunc(uid, requestBody)
  try {
    await queryFulfilled
  } catch (err) {
    for (const patchResult of patchResults) {
      patchResult.undo()
    }
    // alternatively, we could invalidate and refetch
    // e.g., dispatch(api.util.invalidateTags(['some type']))
  }
}
