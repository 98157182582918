/* eslint-disable react/jsx-props-no-spreading */
import Button from '@mui/material/Button'
import DialogContent from '@mui/material/DialogContent'
import Paper from '@mui/material/Paper'
import clsx from 'clsx'
import { useEffect, useCallback, useRef } from 'react'
import Draggable from 'react-draggable'
import { useDropzone } from 'react-dropzone'
import { Link, useLocation, useNavigate } from 'react-router-dom'

import { UploadButton } from '../upload/UploadButton'

import Dialog from './dialog'

import useMobileDetect from '@/hooks/use-mobile-detect'
import { useIsLoggedIn } from '@/store/auth'
import { useQueueNewUpload, useUploads } from '@/store/uploads'
import cls from '@/utils/classnames'

function PaperComponent (props) {
  return (
    <Draggable handle='#draggable-dialog-title' cancel={'[class*="MuiDialogContent-root"]'}>
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <Paper {...props} />
    </Draggable>
  )
}

/**
 * UploadDialog component
 *
 * @param {object} props -  UploadDialog props
 * @param {boolean} props.open - open
 * @param {Function} props.onClose - onClose
 * @returns {React.ReactElement} UploadDialog component
 */
export function UploadDialog ({ open, onClose }) {
  const navigate = useNavigate()
  const queueNewUpload = useQueueNewUpload()
  const uploads = useUploads()
  const isLoggedIn = useIsLoggedIn()
  const location = useLocation()
  const isMobile = useMobileDetect()
  const originalPath = useRef(location.pathname)

  const onDrop = useCallback((acceptedFiles, fileRejections, event) => {
    const file = acceptedFiles[0]
    if (file) {
      queueNewUpload(file)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const { getRootProps, getInputProps } = useDropzone({ noClick: true, onDrop, accept: { 'video/*': [] }, maxFiles: 1 })

  const handleClose = (event, reason) => {
    onClose()
  }

  const onDemoVideoButtonClick = () => {
    navigate('/video/demo')
  }

  useEffect(() => {
    // execute on location change
    if (originalPath.current !== location.pathname) {
      // Update ref to the current pathname so the user can re-open the dialog
      originalPath.current = location.pathname
      onClose()
    }
  }, [location, onClose, open])

  const renderHeader = () => (
    <div className='header'>
      <div className='close-button' onClick={() => onClose()}>
        +
      </div>
      <h2 className='title'>Upload your video for free</h2>
      <p className='subtitle'>
        We recommend a full doubles game, horizontal format with all 4 corners of the court in the frame.
        <Link
          className='helpLink'
          to='https://help.pb.vision/articles/1108176'
        >
          Need help?
        </Link>
      </p>
    </div>
  )

  const renderContent = () => (
    <div className='content'>
      <div className='dndContainer' {...getRootProps()}>
        <input {...getInputProps()} accept='video/*' />
        <img
          src='https://assets-global.website-files.com/643d7427b65657f82d934780/65d7f88a3d755c2584e9b0b9_upload-icon-big.svg'
          loading='lazy'
          alt='upload image'
        />
        <h5>Drag and drop video files to upload</h5>
        <UploadButton className='uploadButton' label='Select Files' disableIcon />
      </div>
      <p className='spacer-text'>- or use ours -</p>
      <Button className='demoVideoButton' variant='contained' onClick={onDemoVideoButtonClick}>
        DEMO VIDEO
      </Button>
    </div>
  )

  const renderFooter = () => (
    <div className='footer'>
      <img
        className='pbVisionLogo'
        src='https://assets-global.website-files.com/643d7427b65657f82d934780/643d75fbd2826f1bec115551_pb-vis-horiz.svg'
        loading='lazy'
        alt='PB Vision logo'
        height='32'
      />
      <div className='signInSection'>
        <p className={clsx(['signin-text', 'label'])}>Already a member? </p>
        <Link to='/login' target='_self' className={clsx(['signInLink'])} rel='noreferrer'>
          Sign in
        </Link>
      </div>
    </div>
  )

  useEffect(() => {
    if (uploads.some(upload => ['uploading', 'pending'].includes(upload.status))) {
      if (isLoggedIn) {
        navigate('/library')
      } else if (open) {
        navigate('/anonymous-upload')
      }
    }
  }, [isLoggedIn, navigate, onClose, open, uploads])

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperComponent={PaperComponent}
      classes={{
        paper: cls('paper', isMobile && 'mobile')
      }}
    >
      <DialogContent
        classes={{
          root: 'muiDialogContentRoot'
        }}
      >
        {renderHeader()}
        {renderContent()}
        {renderFooter()}
      </DialogContent>
    </Dialog>
  )
}
