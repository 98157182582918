import styled from '@emotion/styled'
import React from 'react'

import { Button } from '../button'

import cls from '@/utils/classnames'
import COLORS from '@/utils/colors'
import { column, row } from '@/utils/flexGrid'
import theme from '@/utils/theme'

export default function CreditPack ({
  prefix,
  packName,
  packDescription,
  discount,
  Image,
  price,
  percentOff,
  onClick,
  containerStyle,
  buttonProps,
  className,
  buttonText = 'Select',
  ...props
}) {
  return (
    <Container onClick={onClick} style={containerStyle} className={cls('credit-pack', className)} {...props}>
      <div className='prefix'>{prefix}</div>
      <div className='plan-name'>
        <h4>{packName}</h4>
        <p className='body-sm'>{packDescription}</p>
      </div>
      <div className='discount'>{discount}</div>
      {Image}
      <div className='price'>
        <p>
          <span>$</span>{price}
        </p>
        {percentOff && <span>{`${percentOff}% off`}</span>}
      </div>
      <Button className='green' {...buttonProps}>{buttonText}</Button>
    </Container>
  )
}

const Container = styled('div')({
  ...column,
  flex: 1,
  maxWidth: '174px',
  width: '100%',
  backgroundColor: COLORS['neutral-100'],
  border: `1px solid ${COLORS['neutral-300']}`,
  borderRadius: 8,
  color: COLORS['neutral-800'],
  cursor: 'pointer',
  '& .prefix': {
    ...row,
    gap: 10,
    height: 24,
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: 10,
    fontWeight: 600
  },
  '& .plan-name': {
    ...column,
    justifyContent: 'center',
    padding: '10px',
    textAlign: 'center',
    alignItems: 'center',
    backgroundColor: COLORS['neutral-200']
  },
  '& .price': {
    ...column,
    justifyContent: 'center',
    alignItems: 'center',
    padding: 8,
    backgroundColor: COLORS['neutral-200'],
    '& span': {
      fontSize: 10
    },
    '& p': {
      ...row,
      justifyContent: 'center',
      alignItems: 'center',
      fontSize: 38,
      lineHeight: '36px',
      fontWeight: 700,
      '& span': {
        fontSize: 18
      }
    }
  },
  '& .discount': {
    fontSize: 11,
    fontWeight: 600,
    '& div': {
      ...column,
      height: '100%',
      justifyContent: 'center',
      alignItems: 'center'
    }
  },
  '&.premium': {
    borderColor: COLORS.player3,
    background: '#fffde6',
    '& .plan-name': {
      background: '#fffde6'
    },
    '& .prefix, .price, button': {
      background: 'linear-gradient(285deg, #E9BA38 4.39%, #FFE854 86.49%)'
    },
    '& button': {
      color: COLORS.black
    },
    '& .price p': {
      color: COLORS.white,
      textShadow: '0px 4px 14px #C79900'
    }
  },
  '& image': {
    width: '100%',
    height: 124
  },
  '& button': {
    margin: '8px 18px 16px'
  },
  [theme.breakpoints.down('md')]: {
    maxWidth: 'unset'
  }
})
