import * as R from 'ramda'

// Automatically generates control points for a list of on-curve bezier points.
// – core code is based on https://www.particleincell.com/2012/bezier-splines/

function computeControlPoints (K) {
  const p1 = []
  const p2 = []
  const n = K.length - 1

  /* rhs vector */
  const a = []
  const b = []
  const c = []
  const r = []

  /* left most segment */
  a[0] = 0
  b[0] = 2
  c[0] = 1
  r[0] = K[0] + 2 * K[1]

  /* internal segments */
  for (let i = 1; i < n - 1; i++) {
    a[i] = 1
    b[i] = 4
    c[i] = 1
    r[i] = 4 * K[i] + 2 * K[i + 1]
  }

  /* right segment */
  a[n - 1] = 2
  b[n - 1] = 7
  c[n - 1] = 0
  r[n - 1] = 8 * K[n - 1] + K[n]

  /* solves Ax=b with the Thomas algorithm (from Wikipedia) */
  for (let i = 1; i < n; i++) {
    const m = a[i] / b[i - 1]
    b[i] -= m * c[i - 1]
    r[i] -= m * r[i - 1]
  }

  p1[n - 1] = r[n - 1] / b[n - 1]
  for (let i = n - 2; i >= 0; --i) {
    p1[i] = (r[i] - c[i] * p1[i + 1]) / b[i]
  }

  /* we have p1, now compute p2 */
  for (let i = 0; i < n - 1; i++) {
    p2[i] = 2 * K[i + 1] - p1[i + 1]
  }

  p2[n - 1] = 0.5 * (K[n] + p1[n - 1])

  return { p1, p2 }
}

function transpose (lists) {
  const len = Math.min(...lists.map(R.prop('length')))
  return R.range(0, len).reduce((acc, i) => [...acc, lists.map(R.nth(i))], [])
}

const controlPointsToLists = (controlPoints) => {
  return R.flatten(transpose([controlPoints.p1, controlPoints.p2]))
}

/**
 *
 * @param _points
 * @param _controlPoints
 */
export function combinePoints (_points, _controlPoints) {
  let points = [..._points]
  let controlPoints = [..._controlPoints]
  let results = []
  while (true) {
    results = [...results, ...R.take(1, points), ...R.take(2, controlPoints)]
    points = R.drop(1, points)
    controlPoints = R.drop(2, controlPoints)
    if (controlPoints.length === 0) {
      break
    }
  }
  return [...results, ...R.take(1, points)]
}

/**
 *
 * @param bezierPoints
 */
export function getSegments (bezierPoints) {
  const numSegments = (bezierPoints.length - 1) / 3
  return R.range(0, numSegments).map((i) => {
    const fromIndex = i * 3
    const toIndex = fromIndex + 4
    return R.slice(fromIndex, toIndex, bezierPoints)
  })
}

export const getControlPoints = R.pipe(transpose, R.map(computeControlPoints), R.map(controlPointsToLists), transpose)
