import { Dialog, styled } from '@mui/material'
import React from 'react'

import { Button } from '../button'
import { GIFT_OPTIONS } from '../gift/gift-items'

import COLORS from '@/utils/colors'
import { column } from '@/utils/flexGrid'
import theme from '@/utils/theme'

export default function RedeemedDialog ({ data, isOpen, onClose }) {
  function onCloseHandler () {
    onClose()
  }

  const receivedGift = GIFT_OPTIONS.find((gift) => gift.sku === data?.sku)

  return (
    <StyledDialog open={isOpen} onClose={onCloseHandler}>
      <h2>Successfully redeemed</h2>
      <div className='gift'>
        <p className='body-md'>
          You have successfully redeemed the gift for
        </p>
        <p className='body-lg'>
          <b>{receivedGift?.packName}</b>
        </p>
        <p className='body-lg'>
          {receivedGift?.packDescription}
        </p>
      </div>
      <p className='body-md'>
        Gifted by: <b>{data?.buyerEmail}</b>
      </p>
      <Button onClick={onCloseHandler} className='green'>Close</Button>
    </StyledDialog>
  )
}

const StyledDialog = styled(Dialog)(() => ({
  zIndex: 9999,
  '& .gift': {
    ...column,
    padding: '8px 16px',
    backgroundColor: COLORS['neutral-200'],
    marginBottom: 16,
    alignItems: 'center'
  },
  '& h2': {
    color: COLORS['primary-500'],
    marginBottom: 16,
    alignSelf: 'center'
  },
  '& button': {
    marginTop: 16
  },
  '& .MuiDialog-paper': {
    backgroundColor: COLORS['neutral-050'],
    border: `1px solid ${COLORS['neutral-300']}`,
    padding: 16,
    maxWidth: 'none',
    [theme.breakpoints.down('md')]: {
      margin: '16px'
    }
  }
}))
