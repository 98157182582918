import CloseIcon from '@mui/icons-material/Close'
import { styled } from '@mui/material'
import Button from '@mui/material/Button'
import Checkbox from '@mui/material/Checkbox'
import MuiDialog from '@mui/material/Dialog'
import FormControlLabel from '@mui/material/FormControlLabel'
import IconButton from '@mui/material/IconButton'
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'

import animatedGif from '@/assets/tag.gif'
import { useLoggedInUser } from '@/store/auth'
import { now } from '@/utils'
import COLORS from '@/utils/colors'
import { column, row } from '@/utils/flexGrid'
import { updateReminder } from '@/utils/user'

const TaggingDialog = () => {
  const dispatch = useDispatch()
  const [open, setOpen] = useState(false)
  const [hideDialog, setHideDialog] = useState(false)
  const user = useLoggedInUser()
  const timeUpdated = useRef(false)

  const settings = user?.settings ?? user?.customData?.settings

  const updateHide = (hide) => {
    setHideDialog(hide)
    dispatch(updateReminder('tag', { enabled: !hide }))
  }

  const handleClose = (startTagging) => {
    const firstPlayer = document.querySelector('.name-container')
    if (startTagging === true && firstPlayer) {
      firstPlayer.scrollIntoView()
      firstPlayer.click()
    }
    setOpen(false)
  }

  // Update tag.t time when user sees the dialog (on opening)
  // and set the initial values for the state
  useEffect(() => {
    if (!timeUpdated.current && user?.token) {
      // Check if more than 14 days have passed since the stored timestamp ( for dev environment check if 24 hours has passed instead)
      const hasBeenTwoWeeks = now() - (settings?.reminders?.tag?.t ?? 0) > (import.meta.env.DEV ? 86400 : 86400 * 14)
      // User has turned the dialog off
      const isOff = settings?.reminders?.tag?.off === true
      // Show the dialog if tag.off is not true and if 2 weeks has passed since last view
      const isEnabled = !isOff && hasBeenTwoWeeks

      setOpen(isEnabled)
      if (isEnabled) {
        dispatch(updateReminder('tag', { lastRemindedAtEpoch: now(true) }))
      }
      // Prevent multiple time updates
      timeUpdated.current = true
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, user?.token])

  return (
    <Dialog open={open} onClose={handleClose}>
      <IconButton
        className='close'
        aria-label='close'
        onClick={handleClose}
      >
        <CloseIcon />
      </IconButton>

      <div className='title'>
        <span>Tagging = free</span>
        <span>videos and credits</span>
      </div>
      <img
        src={animatedGif}
        alt='Tagging preview'
        style={{ width: '100%', height: 'auto' }}
      />

      <div className='content'>
        When you tag a friend, they get the video added to&nbsp;
        <strong>their library for free, and you enjoy the same perk</strong>&nbsp;
        when they tag you back.
        <br />
        <br />
        Plus, if a tagged friend decides to sign up, <strong>both of you earn</strong> 15 bonus video credits!
      </div>
      <div className='footer'>
        <FormControlLabel
          control={
            <Checkbox
              checked={hideDialog}
              onChange={(evt) => {
                updateHide(evt.target.checked)
              }}
            />
          }
          label="Don't show again"
        />

        <Button
          variant='contained'
          onClick={() => handleClose(true)}
          disableElevation
        >
          Start Tagging
        </Button>
      </div>
    </Dialog>
  )
}

const Dialog = styled(MuiDialog)({
  '& .MuiPaper-root': {
    ...column,
    alignItems: 'flex-start',
    background: COLORS['neutral-050'],
    border: `1px solid ${COLORS['neutral-300']}`,
    borderRadius: '12px',
    display: 'inline-flex',
    flexDirection: 'column',
    gap: 1,
    margin: 0,
    padding: 24,
    position: 'relative',
    width: 404
  },

  '& .close': {
    position: 'absolute',
    top: 8,
    right: 8
  },
  '& .title': {
    ...column,
    color: COLORS['neutral-700'],
    marginBottom: '16px',
    textAlign: 'center',
    fontFamily: 'Inter',
    fontSize: 36,
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '116%',
    letterSpacing: -2,
    width: '100%'
  },
  '& .content': {
    color: COLORS['neutral-800'],
    textAlign: 'center',
    fontFamily: 'Inter',
    fontSize: 16,
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '150%',
    margin: '16px 0',
    '& strong': {
      fontWeight: 700
    }
  },
  '& .footer': {
    ...row,
    width: '100%',
    '& .MuiFormControlLabel-root': {
      whiteSpace: 'nowrap'
    },
    '& button': {
      borderColor: `1px solid ${COLORS['neutral-500']}`,
      color: COLORS.white,
      backgroundColor: COLORS['primary-500'],
      marginLeft: 'auto'
    }
  }
})

export default TaggingDialog
