import QuestionAnswerOutlinedIcon from '@mui/icons-material/QuestionAnswerOutlined'
import { styled } from '@mui/material'
import React from 'react'

import PBVGiftBall from '@/assets/images/pbv-gift-ball.svg?react'
import { Button } from '@/components/button'
import GiftItems from '@/components/gift/gift-items'
import COLORS from '@/utils/colors'
import { column } from '@/utils/flexGrid'
import theme from '@/utils/theme'

export default function GiftPage () {
  return (
    <Container>
      <PBVGiftBall className='gift-ball' />
      <h1>Give the Gift of Pickleball Mastery</h1>
      <h2 className='subtitle'>because PB Vision makes every game recording both useful and fun.</h2>
      <GiftItems />
      <div className='description'>
        <h2>What Is a Credit?</h2>
        <p className='body-lg'>A credit unlocks the full PB Vision experience for one pickleball game. </p>
        <p className='title-lg'>With a credit, you get: </p>
        <ul>
          <li>AI-powered video capture of one game to 11 points.</li>
          <li>Game Analytics: stats and performance insights. Check out this <a href='/video/demo' target='_blank'>demo</a></li>
          <li>Personal Library: Your game is added to your collection for future review.</li>
          <li>Tag your friends in our system and they <strong>get it for free</strong></li>
        </ul>
        <p className='body-lg'>In essence, one credit equals one complete game experience—from recording to analytics, sharing and progress tracking. </p>
        <Button
          endIcon={<QuestionAnswerOutlinedIcon style={{ color: COLORS['primary-500'] }} />} className='gray' onClick={() => {
            window.open('https://help.pb.vision/', '_blank')
          }}
        >Frequently Asked Questions
        </Button>
      </div>
      <p className='body-md terms'><strong>Terms & Conditions</strong>: Gift cards are one-time use and valid for one year from the purchase date.</p>
    </Container>
  )
}

const Container = styled('div')({
  ...column,
  alignItems: 'center',
  '& .gift-ball': {
    width: '500px',
    height: '500px'
  },
  '& h1': {
    fontSize: '56px',
    lineHeight: '57px',
    letterSpacing: '-2px'
  },
  '& .subtitle': {
    fontWeight: 300
  },
  '& .description': {
    ...column,
    alignItems: 'flex-start',
    maxWidth: '800px',
    width: '100%',
    backgroundColor: COLORS['neutral-100'],
    border: `1px solid ${COLORS['neutral-300']}`,
    borderRadius: '8px',
    gap: '10px',
    padding: '16px 24px',
    '& ul': {
      color: COLORS.black,
      marginLeft: '24px'
    }
  },
  '& .terms': {
    marginBlock: '48px'
  },
  [theme.breakpoints.up('md')]: {
    width: '1140px !important'
  },
  [theme.breakpoints.down('md')]: {
    paddingInline: 16,
    '& .gift-ball': {
      width: '300px',
      height: '300px'
    }
  }
})
