import { createTheme } from '@mui/material/styles'

import COLORS from './colors'

const defaultShadows = [...createTheme({}).shadows.filter((shadow, i) => i > 2)]

const shadows = ['none', '0px 6px 12px -2px rgba(21, 21, 21, 0.08), 0px 2px 8px -2px rgba(21, 21, 21, 0.08)', ...defaultShadows]

// Required to fix elevation error for Dialog with keepMounted prop
shadows[24] = shadows[shadows.length - 1]

const theme = createTheme({
  typography: {
    fontFamily: '"Inter", "Helvetica", "Arial", sans-serif',
    h2: {
      fontSize: 20,
      fontWeight: 600,
      color: COLORS['neutral-800']
    },
    body2: {
      color: COLORS['neutral-700'],
      fontSize: 14,
      fontWeight: 500
    },
    caption: {
      color: COLORS['neutral-500'],
      fontSize: 12,
      fontWeight: 500
    }
  },
  palette: {
    pbVision: {
      background: '#f0f2f6',
      border: '#cdd7e1',
      green: COLORS['primary-500']
    },
    secondary: {
      main: COLORS['primary-500']
    },
    midnight: {
      main: COLORS['neutral-700']
    },
    primary: {
      main: COLORS['primary-500']
    }
  },
  spacing: [0, 8, 16, 24, 30, 36],
  shape: {
    borderRadiusSmall: 8,
    borderRadiusMedium: 16,
    borderRadiusLarge: 24
  },
  components: {
    MuiButtonBase: {
      defaultProps: {
        color: 'secondary',
        variant: 'outlined'
      }
    },
    MuiMenu: {
      defaultProps: {
        elevation: 1
      }
    },
    MuiCssBaseline: {
      // cspell:disable
      styleOverrides: `
      body {
        margin: 0;
        font-family: 'Inter', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;

        color: #C7C7C7;
        overflow-y: hidden;
      }

      code {
        font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
          monospace;
      }

      * {
        box-sizing: border-box;
        margin: 0;
        padding: 0;
      }

      a, a:visited {
        color: #00d824;
        text-decoration: none
      }

      p {
        color: ${COLORS['neutral-800']};
      }
      p.body-lg {
        font-size: 18px;
        font-weight: 400;
        line-height: 27.9px
      }
      p.body-md {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px
      }
      p.body-sm {
        font-size: 14px;
        font-weight: 400;
        line-height: 20px
      }
      p.title-lg {
        font-size: 18px;
        font-weight: 600;
        line-height: 29px
      }
      p.title-md {
        font-size: 16px;
        font-weight: 600;
        line-height: 24px
      }

      h1 {
        font-size: 24px;
        color: ${COLORS['neutral-800']}
      }
      h2 {
        font-size: 30px;
        font-weight: 600;
        color: ${COLORS['neutral-800']};
        line-height: 44px;
        }
      h3 {
        color: ${COLORS['neutral-800']};
        font-size: 24px;
        font-weight: 600;
        line-height: 36px
        }
      h4 {
        color: ${COLORS['neutral-800']};
        font-size: 20px;
        font-weight: 600;
        line-height: 31px
      }

      html, body, #root, #pbvision {
        width: 100%;
        height: 100%;
      }


      #pbvision {
        overflow: auto;
        position: relative;
        overflow-x: hidden;
      }

      #pbvision > .main > div {
        display: flex;
        flex-direction: column;
      }

      #pbvision > .main  {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 16px 0;
        min-height: 100%;

        &.webflow, &.converted-from-webflow {
          padding: 0;
        }
      }

      footer {
        display: flex;
        gap: 10px;
        margin-bottom: 10px;
      }

      /* Overide native datetime input for chrome */
      input[type="datetime-local"]::-webkit-calendar-picker-indicator {
          border-radius: 50%;
          cursor: pointer
      }

      /* Handle non-mobile devices */
      @media not ((min-width: 320px) and (max-width: 568px)) {
        #pbvision > .main > div {
          // Max width of content on desktop/tablet
          width: 848px;
        }
        #pbvision > .main.converted-from-webflow {
          & > div {
            width: 100%;
          }
        }
      }


      /* LANDING PAGE */
      /* LANDING PAGE */
      /* LANDING PAGE */

      /* Handle desktop devices */
      @media (min-width: 1200px)  {
        #pbvision > .main.landing-page-main > div {
          width: 1140px;
        }
      }

      /* Handle iPad and mobiles */
      @media (max-width: 900px)  {
        #pbvision > .main.landing-page-main > div {
          width: 100%;
        }
      }
      `
      // cspell:enable
    }
  },
  shadows,
  breakpoints: {
    values: {
      xs: 576,
      sm: 767,
      md: 991,
      lg: 1199,
      xl: 1399
    }
  }
})

export default theme
