import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import PlayArrowIcon from '@mui/icons-material/PlayArrow'
import { styled } from '@mui/material/styles'
import dayjs from 'dayjs'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate, useSearchParams } from 'react-router-dom'

import CreateAccount from '../create-account'
import TermsSection from '../create-account/TermsSection'
import ForgotPassword from '../forgot-password'
import { PasswordResetEmailConfirmation } from '../login/alert'

import UploadView from './upload-view'

import useMobileDetect from '@/hooks/use-mobile-detect'
import { Container as ThumbContainer } from '@/pages/library/grid-view/video-thumb/container'
import { useLoggedInUser } from '@/store/auth'
import { preliminarilyClaimAnonymouslyUploadedVideos, useAnonymouslyUploadedVideoClaimResponse, useUploads } from '@/store/uploads'
import { isProd } from '@/utils'
import cls from '@/utils/classnames'
import COLORS from '@/utils/colors'
import { isInMobileAppWebview } from '@/utils/mobile-app-communication'
import { formatMs } from '@/utils/time'

const INITIAL_VIEW = 'initial'
const PASSWORD_VIEW = 'password'
const FIRST_VIDEO_VIEW = 'first_video_view'

// cspell:disable
export const DEMO_VIDEOS = isProd
  ? [
      { vid: '83gyqyc10y8f', name: 'Gold Standard', epoch: 1726502000, secs: 698 },
      { vid: 'nsbbhjdmydr4', name: 'Picklemall Demo', epoch: 1726501979, secs: 1544 },
      { vid: '9vp4rs5i9eq1', name: 'Pro Training Session', epoch: 1726501592, secs: 1089 },
      { vid: 'a6tyyoxu2c1i', name: 'PPA Mens Doubles Final', epoch: 1726501693, secs: 843 }
    ]
  : [
      { vid: '7bapnndaasxm', name: 'Demo', epoch: 1727373840, secs: 1083 },
      { vid: '7bapnndaasxm', name: 'Facility', epoch: 1727373840, secs: 1083 },
      { vid: '7bapnndaasxm', name: 'Pro', epoch: 1727373840, secs: 1083 },
      { vid: '7bapnndaasxm', name: 'Amateur', epoch: 1727373840, secs: 1083 }
    ]
// cspell:enable

const Container = styled('div')({
  flexDirection: 'row !important',
  color: COLORS['neutral-800'],
  marginTop: -16,
  width: '100% !important',

  '& .half': {
    display: 'flex',
    flexDirection: 'column',
    width: '50%',
    minHeight: 'calc(100vh - 103px)', // 193 = 48 appBar + 145 margin-top / 48 + 12
    height: 'auto',

    '&.login': {
      paddingBottom: '8px',
      marginTop: 12,
      '&.forgot-pass': {
        marginTop: 0
      },
      '& .wrapper': {
        position: 'relative',
        top: '50%',
        transform: 'translateY(-50%)'
      }
    },
    '& .link-button': {
      textTransform: 'none',
      pointerEvents: 'none',
      '& .row.cancel button': {
        pointerEvents: 'all'
      },
      '&.enabled': {
        pointerEvents: 'all'
      },
      '&:hover': {
        opacity: 1
      }
    }
  },
  '& .wrapper': {
    width: 'auto',
    display: 'flex',
    flexDirection: 'column',
    alignSelf: 'flex-end',
    marginRight: 52
  },
  '& .icons': {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    marginRight: 16,
    minWidth: 40,
    maxWidth: 40,

    '& svg': {
      fontSize: 40
    },
    '& .line': {
      alignSelf: 'center',
      background: COLORS.divider,
      flexGrow: 1,
      width: 1
    }
  },
  '& .content': {
    display: 'flex',
    flexDirection: 'column',
    width: 398,

    '&.while-wait': {
      marginTop: 68,
      width: 'unset',
      flexGrow: 1,
      maxWidth: 380
    },
    '& p': {
      fontSize: 16,
      fontWeight: 400,
      lineHeight: '150%',
      padding: 0
    }
  },
  '& .option': {
    fontSize: 24,
    fontWeight: 600,
    lineHeight: '150%'
  },
  '& .row': {
    display: 'flex',
    flexDirection: 'row'
  },
  '& .success': {
    color: COLORS['primary-500']
  },
  '& .circle': {
    alignSelf: 'center',
    color: COLORS['neutral-400'],
    height: 33,
    width: 33,
    borderRadius: '100%',
    border: '1px solid currentColor',
    marginTop: 8
  },
  '& .pb64': {
    paddingBottom: 12
  },
  '& .upload .icons > svg': {
    marginBottom: 5
  },
  '& .preview': {
    borderLeft: `1px solid ${COLORS['neutral-400']}`,
    background: COLORS['neutral-050'],
    alignItems: 'center',
    justifyContent: 'center'
  },
  // Overrides for create-account
  '& .create-account': {
    padding: '0 !important',
    minHeight: 'auto !important',

    '& > div': {
      width: '100% !important',

      '& > div:first-of-type': {
        display: 'none'
      }
    }
  },
  // Card overrides
  '& .upload-card': {
    display: 'flex',
    flexDirection: 'column',
    width: 394,
    border: `1px solid ${COLORS['neutral-300']}`,
    boxShadow: 'unset',
    borderRadius: '4px 0 0 0',
    lineHeight: 1,

    '& .upload-img': {
      position: 'relative',
      color: '#fff',

      '& img': {
        aspectRatio: '16 / 9'
      },
      '& svg': {
        position: 'absolute',
        height: 114,
        width: 114,
        top: '50%',
        left: '50%',
        transform: 'translate3d(-50%, -50%, 0)'
      }
    },
    '& .progress': {
      height: 6,
      borderRadius: 6,
      flexGrow: 1,

      '&.empty': {
        border: `1px solid ${COLORS['neutral-400']}`
      }
    },
    '& .name': {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      fontSize: 20,
      fontStyle: 'normal',
      fontWeight: 600,
      lineHeight: '155%'
    },
    '& .details': {
      fontSize: 14,
      fontStyle: 'normal',
      fontWeight: 400,
      minHeight: '1.42em',
      lineHeight: '142%'
    },
    '& .cancel': {
      alignItems: 'center',

      '& svg': {
        fontSize: 20,
        marginLeft: 16
      }
    }
  },
  '& .warning': {
    color: COLORS['warning-500']
  },
  '& .status': {
    width: 394,
    fontSize: 18,
    fontWeight: 600,
    marginTop: 8
  },
  '& .gray': {
    color: COLORS['neutral-400']
  },
  '& .mailto': {
    color: 'inherit',
    fontWeight: 'bold'
  },
  '& .gridView': {
    marginBottom: 32,
    marginTop: 8,
    '& .thumbsContainer': {
      display: 'flex',
      gap: 15,
      width: '100%',
      flexWrap: 'wrap',
      alignItems: 'flex-start',

      '& > div': {
        width: 'calc(50% - 8px)'
      },
      '& .placeholder': {
        width: 272
      }
    }
  },
  '& .neutral-800': {
    color: COLORS['neutral-800']
  },
  // Override alert
  '& .MuiAlert-root': {
    backgroundColor: COLORS['primary-100'],
    color: COLORS['primary-700'],
    width: 398
  },
  // Mobile overrides
  '&.mobile': {
    flexDirection: 'column !important',
    paddingInline: '15px',
    '& .create-account-container': {
      marginLeft: '-56px',
      width: 'auto'
    },
    '& .half': {
      width: 'unset',
      minHeight: 'unset',
      '& .content': {
        width: 'auto'
      },
      '&.login': {
        '& .wrapper': {
          transform: 'unset',
          width: '100%',
          marginRight: 0,
          overflowWrap: 'break-word',
          '& .row.results .content': {
            // use all space not used by icon's width (40px) and margin (16px)
            width: 'calc(100% - 56px)'
          }
        }
      }
    }
  }
})

function VideoThumb (props) {
  const { video: { vid, name, epoch, secs } } = props
  const sourceURL = `https://storage.googleapis.com/${import.meta.env.VITE_PRO_BUCKET}/${vid}/thumbnail.jpg`

  return (
    <ThumbContainer className='tablet' to={`/video/${vid}`} target='_blank' sx={{ '& .footer': { pointerEvents: 'none' } }}>
      <div className='thumbnail-container' style={{ backgroundImage: `url(${sourceURL})` }}>
        <div className='duration-container'>
          <div className='duration'>{formatMs(secs * 1000)}</div>
        </div>
        <div className='playback-icon-overlay'>
          <div className='playback-icon-container'>
            <PlayArrowIcon className='playback-icon' />
          </div>
        </div>
      </div>
      <div className='footer'>
        <div className='footer-top'>
          <div className='static-name-section'>
            <div className='name'>{name}</div>
          </div>
        </div>
        <div className='footer-bottom'>
          <div className='static-date-section'>
            <div className='date'>{dayjs(epoch).format('ddd, DD MMM')} &nbsp;&nbsp;&nbsp; {dayjs(epoch).format('hh:mm A')}</div>
          </div>
        </div>
      </div>
    </ThumbContainer>
  )
}

function GetResults (props) {
  const { view, isNative } = props
  return (
    <div className='content'>
      <div className='option'>Get your results</div>
      <p>Please share your e-mail so we can let you know when it&apos;s ready</p>
      {view === PASSWORD_VIEW ? <TermsSection /> : <div className='create-account-container'><CreateAccount isNative={isNative} /></div>}
    </div>
  )
}

function ThanksUser (props) {
  const { user } = props
  return (
    <div className='content'>
      <div className='option'>Thanks, {user.email}</div>
      <p>We’ll email you when your report is ready. </p>
    </div>
  )
}

function GetYourResults (props) {
  const { loggedInUser, view } = props
  return (
    <div className='row results'>
      <div className='icons'>{loggedInUser ? <CheckCircleIcon className='success' /> : <div className='circle' />}</div>
      {loggedInUser ? <ThanksUser user={loggedInUser} /> : <GetResults view={view} isNative={props.isNative} />}
    </div>
  )
}

function WhileYouWait () {
  return (
    <div className='content while-wait'>
      <div className='option'>While you wait</div>
      <p>Have a look at what others have shared.</p>
      <div className='gridView'>
        <div className='thumbsContainer'>
          {DEMO_VIDEOS?.map((video) => (
            <VideoThumb key={video.name} video={video} />
          ))}
        </div>
      </div>
    </div>
  )
}

function InfoSection (props) {
  const { view, isUploaded, loggedInUser } = props
  return (
    <div className='wrapper'>
      {(!isUploaded || !loggedInUser) && (
        <div className='row upload'>
          <div className='icons'>
            <CheckCircleIcon className='success' />
            <div className='line' />
          </div>
          <div className='content pb64'>
            <div className='option'>Upload in progress!</div>
            <p>Our AI will spend about an hour carefully analyzing your game.</p>
          </div>
        </div>
      )}

      {/* {loggedInUser && isUploaded && (
        <div className='row results'>
          <div className='icons'>
            <CheckCircleIcon className='success' />
          </div>
          <div className='content'>
            <div className='option'>Upload complete!</div>
            <p>
              You can safely leave this page, upon successful analysis, we’ll email your insights link to:{' '}
              {loggedInUser.email}
            </p>
          </div>
        </div>
      )} */}
      {(view === INITIAL_VIEW || view === FIRST_VIDEO_VIEW) && <GetYourResults loggedInUser={loggedInUser} view={view} isNative={props.isNative} />}
      {loggedInUser && view !== PASSWORD_VIEW && (
        <div className='row results'>
          <div className='icons' />
          <WhileYouWait />
        </div>
      )}
    </div>
  )
}

function Page (props) {
  const isNative = !!props.isNative
  const isMobile = useMobileDetect('lg')
  const navigate = useNavigate()
  // if we're on this page, there is an upload (unless someone came here
  // directly, like they bookmarked this page)
  const upload = useUploads()[0]
  const loggedInUser = useLoggedInUser()
  const [searchParams, setSearchParams] = useSearchParams()
  const [alertOpen, setAlertOpen] = useState(true)
  const [view, setView] = useState(INITIAL_VIEW)
  const isForgotPassScreen = searchParams.get('forgot') === '1'
  const isForgotPassSent = searchParams.get('forgotSent') === '1'
  const isUploading = ['uploading', 'pending'].includes(upload?.status)
  const isUploaded = !isUploading

  const claimResult = useAnonymouslyUploadedVideoClaimResponse()
  const [hasDispatchedPreliminaryClaim, setHasDispatchedPreliminaryClaim] = useState()
  const dispatch = useDispatch()

  useEffect(() => {
    // If user enters or reloads the page with query strings set
    // remove them to re-initialize the page
    searchParams.delete('email')
    searchParams.delete('forgot')
    searchParams.delete('forgotSent')
    searchParams.delete('promptForPassword')
    setSearchParams(searchParams)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // claim the video preliminarily if the user just logged in
  useEffect(() => {
    if (loggedInUser && upload?.vid && !hasDispatchedPreliminaryClaim) {
      if (loggedInUser.providerId === 'password') {
        dispatch(preliminarilyClaimAnonymouslyUploadedVideos(loggedInUser.email))
        setHasDispatchedPreliminaryClaim(true)
      }
    }
  }, [dispatch, hasDispatchedPreliminaryClaim, loggedInUser, upload?.vid])

  useEffect(() => {
    // If video hasn't yet been claimed - just return
    if (!claimResult) {
      return
    }

    // User didn't enter password yet - only email
    if (!claimResult.isPasswordSet && loggedInUser?.providerId === 'password') {
      setView(PASSWORD_VIEW)
      return
    }

    // User is logged in, upload has finished and this is his first video
    if (claimResult.isFirstVideo) {
      setView(FIRST_VIDEO_VIEW)
      return
    }

    if (loggedInUser) {
      // user is logged in and does not need to set a password (either they have
      // one set or they signed in with SSO)
      navigate('/library', { replace: true })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [claimResult, loggedInUser?.providerId, navigate, upload?.status])

  useEffect(() => {
    if (searchParams.get('forgotSent') === '1') {
      setView(INITIAL_VIEW)
    }
  }, [searchParams])

  useEffect(() => {
    // There are no uploads - redirect to landing page (ignore on native app)
    if (!upload && !isInMobileAppWebview()) {
      navigate('/', { replace: true })
    }
  }, [navigate, upload])

  return (
    <Container className={cls(isMobile && 'mobile')}>
      <div
        className={cls('half', 'login', isForgotPassScreen && 'forgot-pass')}
        style={isNative ? { marginTop: 0, width: 'auto' } : {}}
      >
        {isForgotPassScreen
          ? (
            <div className='wrapper'>
              <ForgotPassword />
            </div>
            )
          : (
            <InfoSection view={view} loggedInUser={loggedInUser} isUploading={isUploading} isUploaded={isUploaded} isNative={isNative} />
            )}
        {isForgotPassSent && alertOpen && (
          <div className='wrapper'>
            <div className='icons' />
            <PasswordResetEmailConfirmation setPasswordResetConfirmation={setAlertOpen} />
          </div>
        )}
      </div>
      <div className='half preview'>
        <UploadView upload={upload} loggedInUser={loggedInUser} />
      </div>
    </Container>
  )
}

export default Page
