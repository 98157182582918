import CloseIcon from '@mui/icons-material/Close'
import LinkOutlinedIcon from '@mui/icons-material/LinkOutlined'
import MailOutlinedIcon from '@mui/icons-material/MailOutlined'
import SmsOutlinedIcon from '@mui/icons-material/SmsOutlined'
import { Button } from '@mui/material'
import clsx from 'clsx'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useSearchParams } from 'react-router-dom'

import { Container } from './container'

import { InlineEdit } from '@/components/inline-edit'
import { getDefaultPlayerName } from '@/components/tag-player'
import useMobileDetect from '@/hooks/use-mobile-detect'
import { useURLParams } from '@/hooks/use-url-params'
import { tagUser } from '@/store/library'
import { useDialog } from '@/store/providers/dialog-provider'
import { useSnackbar } from '@/store/providers/snackbar-provider'
import { isProd } from '@/utils'
import { copyToClipboard } from '@/utils/helper'
import { isInMobileAppWebview, notifyShareRequested } from '@/utils/mobile-app-communication'

const PHONE_NUMBER_INVITATION_ENABLED = false

/**
 * InviteUserPopup component
 *
 * @exports
 * @param props {object} { open, anchorEl, onClose, vid, playerIdx, playerName, onAddUserEmail, onAddUserPhone }
 * @returns {React.ReactElement}
 */
let shareURL = ''
export const InviteUserPopup = (props) => {
  const isMobile = useMobileDetect()
  const dispatch = useDispatch()
  const { open, anchorEl, playerName, vid, playerIdx, aiEngineVersion } = props
  const [name, setName] = useState(playerName.trim())
  const { nestParamsIntoURL } = useURLParams()
  const openSnackbar = useSnackbar()
  const [searchParams] = useSearchParams()
  const showDialog = useDialog()

  const playerImages = []
  for (let imageIdx = 0; imageIdx < 4; imageIdx++) {
    playerImages.push(`https://storage.googleapis.com/${import.meta.env.VITE_PRO_BUCKET}/${vid}/${aiEngineVersion}/player${playerIdx}-${imageIdx}.jpg`)
  }

  useEffect(() => {
    setName(playerName.trim())
  }, [playerName])

  useEffect(() => {
    shareURL = isProd ? 'https://share.pb.vision' : import.meta.env.VITE_API_SERVER
    shareURL += `/video/share/${vid}`
  }, [searchParams, vid])

  const onClose = () => props.onClose()

  const normalizeUrl = (url) =>
    url.startsWith('http') || url.startsWith('data:image/') ? url : `data:image/png;base64,${url}`

  const onTextMessageClick = () => {
    props.onAddUserPhone({ name })
  }

  const onEmailClick = () => {
    props.onAddUserEmail({ name })
  }

  const onSendLinkClick = () => {
    if (isInMobileAppWebview()) {
      notifyShareRequested(nestParamsIntoURL(shareURL))
    } else {
      copyLinkToClipboard()
    }
  }

  const copyLinkToClipboard = async () => {
    const url = nestParamsIntoURL(shareURL)
    copyToClipboard(url)
      .then(() => {
        openSnackbar('Link copied to clipboard')
      })
      .catch(() => {
        openSnackbar('Failed to copy то clipboard', 'error')
      })
  }

  const onNameChange = ({ text }) => {
    setName(text)
  }

  const doTagUser = async () => {
    try {
      if (!!name && name !== getDefaultPlayerName(playerIdx)) {
        await dispatch(tagUser({ vid, playerIdx, name }))
        props.onClose({ success: true })
      }
    } catch (error) {
      openSnackbar('An error occurred while tagging the user. Please try again', 'error')
    }
  }

  const onSaveWithoutTagging = () => {
    showDialog({
      title: 'Tag Friends, Share Free Stats',
      description: 'By tagging friends, you both receive the same video in your personal game library allowing you to track trends over time',
      agreeText: 'Continue with text',
      disagreeText: 'Tag with email',
      onAccept: async () => await doTagUser(),
      onCancel: onEmailClick,
      confirmButtonClasses: 'gray small',
      disagreeButtonClasses: 'green small',
      stopPropagation: true,
      width: 310,
      smallButtons: true
    })
  }

  return (
    <Container
      open={open}
      onClose={onClose}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left'
      }}
      className={clsx([{ mobile: isMobile }])}
      transitionDuration={0}
    >
      <div className='header'>
        <div className='title-section'>
          <div className='avatar'>P</div>
          <InlineEdit text={name} onChange={onNameChange} mayEdit />
        </div>
        <CloseIcon className='close-icon' onClick={onClose} />
      </div>
      <div className='content'>
        <div className='player-images'>
          {playerImages.map((a, i) => (
            <img key={i} src={normalizeUrl(a)} alt='' />
          ))}
        </div>
        <div className='button-section'>
          {PHONE_NUMBER_INVITATION_ENABLED &&
            <Button startIcon={<SmsOutlinedIcon />} onClick={onTextMessageClick} variant='outlined'>
              Text Message
            </Button>}
          <Button startIcon={<MailOutlinedIcon />} onClick={onEmailClick} variant='outlined'>
            Email
          </Button>
          <Button startIcon={<LinkOutlinedIcon />} onClick={onSendLinkClick} variant='outlined'>
            Send a link
          </Button>
          <span onClick={onSaveWithoutTagging} className='save-without-tagging'>Save without tagging</span>
        </div>
      </div>
    </Container>
  )
}
