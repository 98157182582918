import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import { Button, styled } from '@mui/material'
import React from 'react'

import { useSnackbar } from '@/store/providers/snackbar-provider'
import COLORS from '@/utils/colors'
import { row } from '@/utils/flexGrid'

export default function CopyCodeButton ({ code, placeholder }) {
  const openSnackbar = useSnackbar()

  const handleCopyCode = async () => {
    await navigator.clipboard.writeText(code)
    openSnackbar('Copied to clipboard')
  }

  const label = placeholder || code
  return (
    <Container className='code' endIcon={<ContentCopyIcon />} onClick={handleCopyCode}>
      <h3>{label.toUpperCase()}</h3>
    </Container>
  )
}

const Container = styled(Button)({
  ...row,
  gap: '16px',
  borderRadius: '12px',
  background: COLORS['neutral-100'],
  padding: '16px 24px',
  border: `2px solid ${COLORS['neutral-400']}`,
  alignItems: 'center',
  '& svg': {
    color: COLORS['neutral-700']
  },
  '&:hover, &:active, &:focus': {
    background: 'transparent',
    opacity: '0.8'
  }
})
