/// <reference types="vite-plugin-svgr/client" />

import { styled } from '@mui/material/styles'
import React, { useCallback, useMemo } from 'react'

import { BarChart } from '../bar-chart'
import { PlayerImage } from '../player-image'
import { TagPlayer } from '../tag-player'

import HelpIcon from '@/assets/help.svg?react'
import { Avatar } from '@/components/avatar'
import { CourtKitchen } from '@/components/court-kitchen'
import { Tooltip } from '@/components/tooltip'
import useMobileDetect from '@/hooks/use-mobile-detect'
import { useRenamePlayer } from '@/pages/home/hooks/use-rename-player'
import cls from '@/utils/classnames'
import COLORS from '@/utils/colors'
import { column, row } from '@/utils/flexGrid'
import { round } from '@/utils/helper'

/**
 * TeamStats container
 *
 * @returns {React.ReactElement}
 */
const Container = styled('div')({
  ...column,
  padding: '0 32px',

  '& .flex-1': {
    flex: 1
  },

  '& .column': {
    ...column
  },
  '& .row': {
    ...row
  },
  '& .grow': {
    flexGrow: 1
  },
  '& .justify-end': {
    justifyContent: 'flex-end'
  },
  '& .gap50': {
    gap: 50
  },
  '& .title': {
    color: COLORS['neutral-800'],
    fontSize: 20,
    fontWeight: 600
  },
  '& .team2 .title': {
    textAlign: 'right'
  },
  '& .player': {
    display: 'flex',
    alignItems: 'center',
    fontSize: 20,
    fontWeight: 600,
    color: COLORS['00-on-surface-high-emphasis']
  },
  '& .team2 .player': {
    justifyContent: 'flex-end'
  },
  '& .img': {
    '&:first-of-type': {
      // Separate player images vertically
      marginBottom: 8
    },

    // Place the avatar initials in the middle of the image
    '&.player1, &.player2': {
      '& div': {
        transform: 'translate(50%, -50%)',
        right: 0,
        bottom: 0
      }
    },
    '&.player3, &.player4': {
      '& div': {
        transform: 'translate(-50%, -50%)',
        left: 0,
        bottom: 0
      }
    },

    '& div': {
      position: 'absolute',
      height: 35,
      width: 35,
      alignItems: 'center',
      justifyContent: 'center',
      ...column
    }
  },
  '& .half-w': {
    width: '50%'
  },
  '& .label': {
    ...row,
    color: COLORS['00-on-surface-high-emphasis'],
    margin: '20px 0',
    fontSize: 24,
    // Tooltip
    '& span': {
      ...column,
      alignSelf: 'center',
      marginRight: 10
    },
    '&.serving': {
      marginTop: 8
    },
    '&.returning': {
      marginTop: 40
    },
    '&.distribution': {
      fontWeight: 600,
      marginTop: 40,
      marginBottom: 10
    }
  },
  // For truncating purposes
  '& .mw0': {
    minWidth: 0
  },

  '& .ml20': {
    marginLeft: 20
  },
  '& .dist-label': {
    marginTop: 22,
    '& .ml-auto': {
      marginLeft: 'auto'
    }
  },
  '& .mr30': {
    marginRight: 30
  },
  '& .tar': {
    textAlign: 'right'
  },
  '& .body1': {
    color: COLORS['00-on-surface-high-emphasis'],
    fontSize: 16,
    fontWeight: 400,
    letterSpacing: 0.5,
    paddingBottom: 4
  },
  '& .edit-ico': {
    cursor: 'pointer',
    fontSize: 18,
    color: '#BDBDBD',
    margin: '0 5px'
  },
  '& .player1': {
    color: COLORS.player1
  },
  '& .player2': {
    color: COLORS.player2
  },
  '& .player3': {
    color: COLORS.player3
  },
  '& .player4': {
    color: COLORS.player4
  },
  '& .team-b': {
    '& span:first-of-type': {
      justifyContent: 'flex-end'
    }
  },
  // Override bar-chart default font
  '& .bar-chart div': {
    lineHeight: '30px',
    padding: 0,
    '& > span': {
      fontSize: 18,
      fontWeight: 600
    }
  },
  '& .court-kitchen': {
    padding: '0 8px'
  },
  // Mobile overrides
  '&.mobile': {
    padding: '0 20px',
    '& .teams': {
      flexDirection: 'column',
      '& > .title': {
        marginBottom: 14
      },
      '& > .column': {
        flexDirection: 'row'
      },
      // Move court to the end
      '& > .label': {
        order: 3,
        fontSize: 20,
        whiteSpace: 'nowrap',
        flexWrap: 'wrap',
        marginBottom: 10,
        '& b': {
          fontSize: 24,
          marginLeft: 34
        }
      },
      '& > .court-kitchen': {
        order: 4
      },
      // Update team 2 alignments
      '& .team-a': {
        marginBottom: 49
      },
      '& .team-b': {
        marginBottom: 49,
        '& .img > div': {
          left: 'unset',
          right: 0,
          transform: 'translate(50%, -50%)'
        },
        '& span:first-of-type': {
          justifyContent: 'flex-start'
        }
      }
    },
    '& .team2': {
      order: 2,
      '& .player': {
        marginLeft: 26
      }
    },
    '& .label.distribution': {
      fontSize: 20
    },
    '& .shot-distribution': {
      ...column,
      marginBottom: 50,

      '& .bar-title': {
        marginTop: 22,
        fontSize: 16,
        color: COLORS['00-on-surface-high-emphasis']
      }
    },
    '& .half-w': {
      width: '100%'
    },
    '& .ml-auto': {
      marginLeft: 'unset'
    },
    // Re-arrange distribution bars (push team2 to the end of the container)
    '& .order1': { order: 1 },
    '& .order2': { order: 2 },
    '& .order3': { order: 3 },
    '& .order4': { order: 4 },

    // Align name to start when in mobile mode
    '& .justify-start': {
      justifyContent: 'start'
    }
  }
})

function WrapIf (props) {
  const { wrapClass, children, condition } = props

  if (condition) return <div className={wrapClass}>{children}</div>
  return children
}

function KitchenArrival (props) {
  const { isMobile, vid, aiEngineVersion, userData, onRenamePlayer, playerData, returning, hidePlayers } = props

  const kitchenArrivals = useMemo(() => {
    const serves = []
    const returns = []

    /* eslint-disable camelcase */
    playerData.forEach(({ role_data }) => {
      const calcWhenServing =
        role_data.serving.oneself.kitchen_arrival && role_data.serving.oneself.total
          ? (role_data.serving.oneself.kitchen_arrival + role_data.serving.partner.kitchen_arrival) /
            (role_data.serving.oneself.total + role_data.serving.partner.total)
          : 0
      const calcWhenReceiving =
        role_data.receiving.oneself.kitchen_arrival && role_data.receiving.oneself.total
          ? (role_data.receiving.oneself.kitchen_arrival + role_data.receiving.partner.kitchen_arrival) /
            (role_data.receiving.oneself.total + role_data.receiving.partner.total)
          : 0
      // [number of serving rallies where the player made it to the kitchen] / [number of total rallies where this player's team served] * 100%
      serves.push(round(calcWhenServing * 100))
      returns.push(round(calcWhenReceiving * 100))
    })
    return [serves, returns]
    /* eslint-enable camelcase */
  }, [playerData])

  return (
    <div className='row teams'>
      {!hidePlayers && isMobile && <div className='title'>Team A Stats</div>}
      {!hidePlayers && (
        <div className='column flex-1 team-a mw0'>
          <div className='row mw0'>
            <div className='column'>
              <PlayerImage className='img player1' width={75} height={75} vid={vid} aiEngineVersion={aiEngineVersion} playerIdx={0} text={userData.players[0]?.name || 'Player 1'} onChange={onRenamePlayer(0)}>
                <Avatar width='35' initials={userData.players[0]?.name} round />
              </PlayerImage>
              <PlayerImage className='img player2' width={75} height={75} vid={vid} aiEngineVersion={aiEngineVersion} playerIdx={1} text={userData.players[1]?.name || 'Player 2'} onChange={onRenamePlayer(1)}>
                <Avatar width='35' initials={userData.players[1]?.name} round />
              </PlayerImage>
            </div>
            <div className='column grow mw0'>
              <div className='player grow ml20'>
                <TagPlayer
                  truncate
                  vid={vid}
                  playerIdx={0}
                  playerName={userData.players[0]?.name}
                  aiEngineVersion={aiEngineVersion}
                />
              </div>
              <div className='player grow ml20'>
                <TagPlayer
                  truncate
                  vid={vid}
                  playerIdx={1}
                  playerName={userData.players[1]?.name}
                  aiEngineVersion={aiEngineVersion}
                />
              </div>
            </div>
          </div>
        </div>
      )}
      {isMobile && (
        <div className='label'>
          <Tooltip title='Shows how often a player reaches the kitchen line during rallies, counted only when their side is serving.'>
            <HelpIcon />
          </Tooltip>
          Kitchen Arrival&nbsp;<b>When {returning ? 'Returning' : 'Serving'}</b>
        </div>
      )}
      <CourtKitchen values={kitchenArrivals[returning ? 1 : 0]} />
      {!hidePlayers && isMobile && <div className='title'>Team B Stats</div>}
      {!hidePlayers && (
        <div className='column flex-1 team-b mw0'>
          <div className='row mw0 justify-end'>
            <div className='column team2 grow mw0'>
              <div className='player grow mr30 tar justify-start'>
                <TagPlayer
                  truncate
                  vid={vid}
                  playerIdx={2}
                  playerName={userData.players[2]?.name}
                  aiEngineVersion={aiEngineVersion}
                />
              </div>
              <div className='player grow mr30 tar justify-start'>
                <TagPlayer
                  truncate
                  vid={vid}
                  playerIdx={3}
                  playerName={userData.players[3]?.name}
                  aiEngineVersion={aiEngineVersion}
                />
              </div>
            </div>
            <div className='column'>
              <PlayerImage className='img player3' width={75} height={75} vid={vid} aiEngineVersion={aiEngineVersion} playerIdx={2} text={userData.players[2]?.name || 'Player 3'} onChange={onRenamePlayer(2)}>
                <Avatar width='35' initials={userData.players[2]?.name} round />
              </PlayerImage>
              <PlayerImage className='img player4' width={75} height={75} vid={vid} aiEngineVersion={aiEngineVersion} playerIdx={3} text={userData.players[3]?.name || 'Player 4'} onChange={onRenamePlayer(3)}>
                <Avatar width='35' initials={userData.players[3]?.name} round />
              </PlayerImage>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

/**
 * shows stats of both teams
 */
export function TeamStats ({ insights, video, workflow }) {
  const isMobile = useMobileDetect()
  const { userData, vid } = video
  const { player_data: playerData } = insights
  const { aiEngineVersion } = workflow

  const renamePlayerIfNeeded = useRenamePlayer()
  const onRenamePlayer = useCallback(
    (index) => (update) => { renamePlayerIfNeeded(video, index, update.text) },
    [renamePlayerIfNeeded, video]
  )

  return (
    <Container className={cls(isMobile && 'mobile')}>
      {!isMobile && (
        <div className='label serving'>
          <Tooltip title='Shows how often a player reaches the kitchen line during rallies, counted only when their side is serving.'>
            <HelpIcon />
          </Tooltip>
          Kitchen Arrival:&nbsp;{isMobile && <br />}<b>When Serving</b>
        </div>
      )}
      <KitchenArrival isMobile={isMobile} vid={vid} aiEngineVersion={aiEngineVersion} userData={userData} onRenamePlayer={onRenamePlayer} playerData={playerData} />
      {!isMobile && (
        <div className='label returning'>
          <Tooltip title='Shows how often a player reaches the kitchen line during rallies, counted only when their side is returning.'>
            <HelpIcon />
          </Tooltip>
          Kitchen Arrival:&nbsp;{isMobile && <br />}<b>When Returning</b>
        </div>
      )}
      <KitchenArrival isMobile={isMobile} vid={vid} aiEngineVersion={aiEngineVersion} userData={userData} onRenamePlayer={onRenamePlayer} playerData={playerData} returning hidePlayers={isMobile} />
      <div className='label distribution'>Shot Distribution and Stacking</div>
      <WrapIf wrapClass='row dist-label shot' condition={!isMobile}>
        <div className='body1'>Shot Distribution</div>
        <div className='body1 ml-auto order1'>Shot Distribution</div>
      </WrapIf>
      <WrapIf wrapClass='row gap50' condition={!isMobile}>
        <div className='column half-w'>
          <BarChart values={[{ value: playerData[0].total_team_shot_percentage, fillColor: COLORS.player1 }]} emptySpaceColor={COLORS.player2} />
        </div>
        <div className='column half-w order2'>
          <BarChart values={[{ value: playerData[2].total_team_shot_percentage, fillColor: COLORS.player3 }]} emptySpaceColor={COLORS.player4} />
        </div>
      </WrapIf>
      <WrapIf wrapClass='row dist-label' condition={!isMobile}>
        <div className='body1 first'>Left side play</div>
        <div className='body1 ml-auto order3'>Left side play</div>
      </WrapIf>
      <WrapIf wrapClass='row gap50' condition={!isMobile}>
        <div className='column half-w'>
          <BarChart values={[
            { value: playerData[0].left_side_percentage, fillColor: COLORS.player1 },
            { value: playerData[1].left_side_percentage, fillColor: COLORS.player2 }
          ]}
          />
        </div>
        <div className='column half-w order4'>
          <BarChart values={[
            { value: playerData[2].left_side_percentage, fillColor: COLORS.player3 },
            { value: playerData[3].left_side_percentage, fillColor: COLORS.player4 }
          ]}
          />
        </div>
      </WrapIf>

    </Container>
  )
}
