import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import Alert from '@mui/material/Alert'
import clsx from 'clsx'

export function PasswordResetEmailConfirmation (props) {
  const { styles, isMobile, setPasswordResetConfirmation } = props

  return (
    <Alert
      onClose={() => setPasswordResetConfirmation(false)}
      iconMapping={{ success: <CheckCircleIcon /> }}
      severity='success'
      variant='filled'
      sx={{ width: '100%' }}
      classes={{
        root: clsx([styles.alert, { [styles.mobile]: isMobile }]),
        icon: styles.icon,
        message: styles.message,
        action: styles.action
      }}
    >
      {`Thanks! An email was sent to the email you provided with link to
       reset your password. If you don't receive an email please contact
       support@pb.vision`}
    </Alert>
  )
}
