import styled from '@emotion/styled'

import COLORS from '@/utils/colors'

const VideoPlaceholder = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: COLORS['neutral-300'],
  width: '100%',
  aspectRatio: '2/1'
})

export default VideoPlaceholder
