import DeleteIcon from '@mui/icons-material/Delete'
import DriveFileMoveIcon from '@mui/icons-material/DriveFileMove'
import EditIcon from '@mui/icons-material/Edit'
import InfoIcon from '@mui/icons-material/Info'
import LinkIcon from '@mui/icons-material/Link'
import MapIcon from '@mui/icons-material/Map'
import TextSnippetIcon from '@mui/icons-material/TextSnippet'
import TodayIcon from '@mui/icons-material/Today'
import { SvgIcon } from '@mui/material'
import { useContext, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import ExcelIcon from '@/assets/icons/excel-icon.svg?react'
import { Button } from '@/components/button'
import ContextMenu from '@/components/context-menu'
import DownloadRawDataModal from '@/components/raw-data-dialog'
import { PageContext } from '@/pages/library/context'
import { useIsLoggedIn, useLoggedInUser } from '@/store/auth'
import { removeVideoFromLibrary } from '@/store/library'
import { useDialog } from '@/store/providers/dialog-provider'
import { useSnackbar } from '@/store/providers/snackbar-provider'
import { useGetVideo } from '@/store/video'
import { isProd } from '@/utils'
import COLORS from '@/utils/colors'
import { downloadOrOpenLink } from '@/utils/download'
import { copyToClipboard } from '@/utils/helper'
import { isInMobileAppWebview } from '@/utils/mobile-app-communication'

/**
 * VideoContextMenu component
 *
 * @param {object} props - VideoContextMenu props
 * @param {object} props.className - className
 * @returns {React.ReactElement} VideoContextMenu component
 */
function VideoContextMenu ({ className, videoExcerpt, isProcessing }) {
  const { setIsMoveItemsOpen, setItemsForMoving, bulkSelected } = useContext(PageContext)
  const [showRawDataDialog, setShowRawDataDialog] = useState(false)
  const [downloadExcelId, setDownloadExcelId] = useState(null)
  const videoDetails = useGetVideo(downloadExcelId)
  const user = useLoggedInUser()
  const dispatch = useDispatch()
  const { vid } = videoExcerpt
  const openSnackbar = useSnackbar()
  const isLoggedIn = useIsLoggedIn()
  const showDialog = useDialog()
  const navigate = useNavigate()
  const openShotExplorer = () => {
    navigate(`/video/${vid}/explore`)
  }

  const onCopyLink = async () => {
    const serverUrl = isProd ? 'https://share.pb.vision' : import.meta.env.VITE_API_SERVER
    const referral = user?.referrals?.myCode?.toUpperCase()
    const shareURL = `${serverUrl}/video/share/${vid}${referral ? `?rf=${referral}` : ''}`
    copyToClipboard(shareURL)
      .then(() => {
        openSnackbar('Link copied to clipboard')
      })
      .catch(() => {
        openSnackbar('Failed to copy то clipboard', 'error')
      })
  }

  const onEditRallies = () => {
    console.log(`TODO: need to hook up onEditRallies: ${videoExcerpt.name}`)
  }

  const onEditDate = () => {
    console.log(`TODO: need to hook up onEditDate: ${videoExcerpt.name}`)
  }

  const onRemoveFromLibrary = async () => {
    showDialog({
      title: 'Are you sure?',
      description: 'This action cannot be undone',
      disagreeText: 'Remove from Library',
      agreeText: 'Keep in library',
      onCancel: () => {
        dispatch(removeVideoFromLibrary(vid))
      }
    })
  }

  const onMoveVideoToFolder = () => {
    setIsMoveItemsOpen(true)

    console.log(bulkSelected)

    if (bulkSelected?.length) {
      setItemsForMoving(bulkSelected)
    } else {
      setItemsForMoving(state => [...state, videoExcerpt])
    }
  }

  const onShowDownload = (e) => {
    e.stopPropagation()
    setShowRawDataDialog(false)
  }

  const openInfoLink = (e) => {
    e.preventDefault()
    e.stopPropagation()
    const link = 'https://pb.vision/post/interpreting-pb-visions-advanced-stats'
    downloadOrOpenLink(link, null, true)
  }

  const items = [
    {
      icon: <EditIcon />,
      text: 'Edit Rallies',
      onClick: onEditRallies,
      hidden: true
    },
    {
      icon: <TodayIcon />,
      text: 'Edit Date',
      onClick: onEditDate,
      hidden: true
    },
    {
      icon: <LinkIcon sx={{ color: COLORS['primary-500'] }} />,
      text: 'Copy Link',
      onClick: onCopyLink
    },
    {
      icon: <MapIcon />,
      text: 'Shot Explorer',
      onClick: openShotExplorer
    },
    {
      icon: <SvgIcon sx={{ padding: '2px' }} component={ExcelIcon} />,
      text: (
        <>
          Download Excel Data
          <Button
            className='info-link-btn'
            onClick={openInfoLink}
            sx={{
              margin: 0,
              padding: 0,
              minHeight: 'unset',
              minWidth: 'unset',
              backgroundColor: 'transparent',
              '&:hover': {
                backgroundColor: 'transparent'
              }
            }}
          >
            <InfoIcon />
          </Button>
        </>
      ),
      // Disable the dialog while the video is still processing
      disabled: isProcessing,
      onClick: () => setDownloadExcelId(vid)
    },
    {
      icon: <TextSnippetIcon />,
      text: 'Download Raw Data',
      // Disable the dialog while the video is still processing
      disabled: isProcessing,
      onClick: () => setShowRawDataDialog(true)
    },
    {
      icon: <DeleteIcon />,
      text: 'Remove from Library',
      onClick: onRemoveFromLibrary,
      hidden: !isLoggedIn && isInMobileAppWebview()
    },
    {
      icon: <DriveFileMoveIcon />,
      text: 'Move',
      onClick: onMoveVideoToFolder
    }
  ]

  useEffect(() => {
    if (vid && downloadExcelId && videoDetails?.isReady) {
      const aiEngineVersion = videoDetails?.data?.workflows?.[0]?.aiEngineVersion
      const fileName = `${vid}_${aiEngineVersion}_stats.xlsx`
      downloadOrOpenLink(`https://storage.googleapis.com/${import.meta.env.VITE_PRO_BUCKET}/${vid}/${aiEngineVersion}/${fileName}`, fileName, true)
      setDownloadExcelId(null)
    }
  }, [downloadExcelId, vid, videoDetails])

  return (
    <>
      <ContextMenu items={items} className={className} />
      {showRawDataDialog && <DownloadRawDataModal open={showRawDataDialog} onClose={onShowDownload} vid={vid} />}
    </>
  )
}

export default VideoContextMenu
