import SearchIcon from '@mui/icons-material/Search'
import { styled } from '@mui/material'
import OutlinedInput from '@mui/material/OutlinedInput'
import dayjs from 'dayjs'
import { useContext, useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'

import { PageContext } from '../context'
import { displayItemsByFolder } from '../display-items-by-folder'

import CancelIcon from '@/assets/cancel-icon.svg?react'
import COLORS from '@/utils/colors'
import { pluralize } from '@/utils/helper'

/**
 * Video Library Search Box component
 *
 * @exports
 * @returns {React.ReactElement} Video Library Search Box component
 */
function SearchBox () {
  const { filteredVideoExcerpts, videoExcerpts, setFilteredVideoExcerpts, folders, dateRange } = useContext(PageContext)
  const isRangeSelected = !!dateRange[0] && !!dateRange[1]
  const [searchValue, setSearchValue] = useState('')
  const { fid } = useParams()

  const videosGroupedByFolder = useMemo(() => displayItemsByFolder(filteredVideoExcerpts, fid), [fid, filteredVideoExcerpts])

  const onChange = (event) => {
    const { value: searchString } = event.target
    setSearchValue(searchString) // Update the search value state
  }

  const clearInput = () => {
    setSearchValue('') // Clear the search input
  }

  useEffect(() => {
    const searchTokens = searchValue.split(' ').filter(i => i).map((token) => token.toLowerCase())

    function isDate (token) {
      const val = Number(token)
      return !isNaN(val) && token.indexOf('.') === -1 && val > 0 && val < 32
    }

    const isYear = (token) => !isNaN(Number(token)) && token.length === 4

    function getWeekdayNames () {
      const days = []
      const formats = ['short', 'long']
      formats.forEach(format => {
        for (let d = new Date(), i = 7; i; --i) {
          days.push(d.toLocaleString(undefined, { weekday: format }).toLowerCase())
          d.setDate(d.getDate() + 1)
        }
      })
      return days
    }
    const weekdayNames = getWeekdayNames()
    const isDayOfWeek = (token) => weekdayNames.indexOf(token) !== -1

    function getMonthNames () {
      const months = []
      const formats = ['short', 'long']
      formats.forEach(format => {
        for (let d = new Date(), i = 12; i; --i) {
          months.push(d.toLocaleString(undefined, { month: format }).toLowerCase())
          d.setMonth(d.getMonth() + 1)
        }
      })
      return months
    }
    const monthNames = getMonthNames()
    const isMonth = (token) => monthNames.indexOf(token) !== -1

    function mapTokens () {
      const map = {
        dates: [],
        years: [],
        daysOfWeek: [],
        months: [],
        nonDateTokens: [],
        all: []
      }
      searchTokens.forEach(token => {
        map.all.push(token)

        if (isDate(token)) {
          map.dates.push(token)
        } else if (isYear(token)) {
          map.years.push(token)
        } else if (isDayOfWeek(token)) {
          map.daysOfWeek.push(token)
        } else if (isMonth(token)) {
          map.months.push(token)
        } else {
          map.nonDateTokens.push(token)
        }
      })
      return map
    }
    const tokenMap = mapTokens()

    function mapVideoDates (video) {
      const videoDate = new Date(video.epoch * 1000)
      const daysOfWeek = [
        videoDate.toLocaleDateString(undefined, { weekday: 'long' }).toLowerCase(),
        videoDate.toLocaleDateString(undefined, { weekday: 'short' }).toLowerCase()
      ]
      const months = [
        videoDate.toLocaleDateString(undefined, { month: 'long' }).toLowerCase(),
        videoDate.toLocaleDateString(undefined, { month: 'short' }).toLowerCase()
      ]
      const date = String(videoDate.getDate())
      const year = String(videoDate.getFullYear())

      return {
        date,
        year,
        daysOfWeek,
        months,
        dateInstance: videoDate
      }
    }

    function checkVideo (video) {
      const videoDatesMap = mapVideoDates(video)

      function checkDates () {
        return tokenMap.dates.length ? tokenMap.dates.indexOf(videoDatesMap.date) !== -1 : true
      }
      function checkYears () {
        return tokenMap.years.length ? tokenMap.years.indexOf(videoDatesMap.year) !== -1 : true
      }
      function checkDaysOfWeek () {
        let success
        if (!tokenMap.daysOfWeek.length) {
          success = true
        } else {
          success = !!tokenMap.daysOfWeek.find((dayOfWeek) => {
            return videoDatesMap.daysOfWeek.indexOf(dayOfWeek) !== -1
          })
        }
        return success
      }
      function checkMonths () {
        let success
        if (!tokenMap.months.length) {
          success = true
        } else {
          success = !!tokenMap.months.find((month) => {
            return videoDatesMap.months.indexOf(month) !== -1
          })
        }
        return success
      }
      function checkTitleAgainstNonDateTokens () {
        let success = true
        tokenMap.nonDateTokens.forEach((token) => {
          if (!video.name || video.name.toLowerCase().indexOf(token) === -1) {
            success = false
          }
        })
        return success
      }
      function checkTitleAgainstAllTokens () {
        let success = true
        tokenMap.all.forEach((token) => {
          if (!video.name || video.name.toLowerCase().indexOf(token) === -1) {
            success = false
          }
        })
        return success
      }
      function checkTitleAgainstDateAndYear () {
        let success = true
        tokenMap.dates.concat(tokenMap.years).forEach((token) => {
          if (!video.name || video.name.toLowerCase().indexOf(token) === -1) {
            success = false
          }
        })
        return success
      }

      function checkDateRange () {
        const videoDate = dayjs(videoDatesMap.dateInstance)
        const startDate = dateRange[0]
        const endDate = dateRange[1]
        const isSameAsStartDate = videoDate.isSame(startDate, 'day')
        const isAfterStartDate = videoDate.isAfter(startDate, 'day')
        const isSameAsEndDate = videoDate.isSame(endDate, 'day')
        const isBeforeEndDate = videoDate.isBefore(endDate, 'day')
        return (isSameAsStartDate || isAfterStartDate) && (isSameAsEndDate || isBeforeEndDate)
      }

      const checkAgainstDateTokens = () => {
        let success =
          checkDates() &&
          checkYears() &&
          checkDaysOfWeek() &&
          checkMonths() &&
          checkTitleAgainstNonDateTokens()
        if (!!tokenMap.dates.length || !!tokenMap.years.length) {
          success = success || checkTitleAgainstDateAndYear()
        }
        return success
      }

      const checkAgainstDateRange = () =>
        checkDateRange() &&
        checkTitleAgainstAllTokens()

      return isRangeSelected ? checkAgainstDateRange() : checkAgainstDateTokens()
    }

    const videos = videoExcerpts || []

    const videosAndFoldersCombined = videos.concat(folders || [])

    setFilteredVideoExcerpts(videosAndFoldersCombined?.filter((video) => checkVideo(video)))
  }, [folders, searchValue, videoExcerpts, dateRange, isRangeSelected, setFilteredVideoExcerpts])

  const count = useMemo(() => {
    const videosInsideCurrentFolder = videosGroupedByFolder.filter(video => video.vid).length
    const foldersInsideCurrentFolder = videosGroupedByFolder.filter(video => video.fid).length
    const global = filteredVideoExcerpts.length

    return {
      global,
      foldersInsideCurrentFolder,
      videosInsideCurrentFolder
    }
  }, [filteredVideoExcerpts, videosGroupedByFolder])

  return (
    <Container>
      <OutlinedInputStyled
        size='small'
        placeholder='Search'
        value={searchValue}
        startAdornment={<SearchIcon className='search-icon' />}
        endAdornment={<CancelIcon className='cancel-icon' onClick={clearInput} />}
        onChange={onChange}
      />
      {videoExcerpts && (searchValue || isRangeSelected) && (
        <span>
          {/* found {count.videosInsideCurrentFolder} {pluralize('video', count.videosInsideCurrentFolder)}
          {count.foldersInsideCurrentFolder > 0 && ` and ${count.foldersInsideCurrentFolder} ${pluralize('folder', count.foldersInsideCurrentFolder)}`} */}
          {count.videosInsideCurrentFolder} {pluralize('video', count.videosInsideCurrentFolder)} found in this folder
        </span>
      )}
    </Container>
  )
}

export default SearchBox

const OutlinedInputStyled = styled(OutlinedInput)({
  width: '100%',
  height: '40px',
  marginRight: '10px',
  '& svg': {
    color: COLORS['neutral-500'],
    '&.search-icon': {
      marginRight: '4px'
    },
    '&.cancel-icon': {
      cursor: 'pointer'
    }
  },
  '& .input': {
    paddingLeft: '4px'
  },
  '& .notchedOutline': {
    borderColor: '#636B74'
  }
})

const Container = styled('div')({
  width: '270px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  gap: '0px'
})
