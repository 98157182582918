/* jsx-a11y/no-static-element-interactions */
import VisibilityIcon from '@mui/icons-material/Visibility'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import IconButton from '@mui/material/IconButton'
import InputAdornment from '@mui/material/InputAdornment'
import OutlinedInput from '@mui/material/OutlinedInput'
import TextField from '@mui/material/TextField'
import clsx from 'clsx'
import emailValidator from 'email-validator'
import { GoogleAuthProvider, OAuthProvider, signInWithEmailAndPassword, signInWithPopup } from 'firebase/auth'
import { useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'

import { PasswordResetEmailConfirmation } from './alert'
import styles from './styles.module.scss'

import AppleLogo from '@/assets/apple_logo.svg?react'
import GoogleLogo from '@/assets/google_logo.svg?react'
import { Button } from '@/components/button'
import useMobileDetect from '@/hooks/use-mobile-detect'
import { useIsAuthInitialized, useIsLoggedIn } from '@/store/auth'
import { normalizeLoginErrorMessage } from '@/utils/email'
import { getAuth } from '@/utils/firebase'
import { isInIOSWebview, isInMobileAppWebview, notifySSORequested } from '@/utils/mobile-app-communication'

function LoginPage () {
  const isMobile = useMobileDetect()
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()
  const isAuthReady = useIsAuthInitialized()
  const isLoggedIn = useIsLoggedIn()
  const [email, setEmail] = useState(searchParams.get('email') || '')
  const [password, setPassword] = useState('')
  const [error, setError] = useState('')
  const [isProcessingUserRequest, setIsProcessingUserRequest] = useState(false)
  const [showPassword, setShowPassword] = useState(false)
  const isPasswordScreen = Boolean(searchParams.get('promptForPassword'))
  const isFromPasswordReset = searchParams.get('from') === 'reset-password'
  const [passwordResetConfirmation, setPasswordResetConfirmation] = useState(isFromPasswordReset)
  const { validate: validateEmail } = emailValidator
  const isEmailValid = validateEmail(email)

  useEffect(() => {
    if (isAuthReady && isLoggedIn) {
      navigate('/library', { replace: true })
    }
  }, [isAuthReady, isLoggedIn, navigate])

  useEffect(() => {
    if (isFromPasswordReset) {
      searchParams.delete('from')
      setSearchParams(searchParams)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (isPasswordScreen && !isEmailValid) {
      searchParams.delete('email')
      setSearchParams(searchParams)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPasswordScreen])

  if (isLoggedIn || !isAuthReady) {
    return undefined // will redirect to library if logged in
  }

  function wrapFirebaseAuthRequest (promise) {
    setIsProcessingUserRequest(true)
    setError()
    promise
      .then(() => { }) // if logged in, will auto-redirect after redux updates
      .catch((err) => {
        const normalizedError = normalizeLoginErrorMessage(err)
        setError(normalizedError)
      })
      .finally(() => setIsProcessingUserRequest(false))
  }

  function renderPageSwitch () {
    const title = "Don't have an account?"
    const link = 'Create one'
    return (
      <div className={styles.pageSwitch}>
        <div className={styles.text}>{title}</div>
        <div
          className={styles.link} onClick={() => {
            navigate('/create-account?' + new URLSearchParams({ email }).toString())
          }}
        >
          {link}
        </div>
      </div>
    )
  }

  function handleSSOWrapper (provider, func) {
    if (isInMobileAppWebview()) {
      notifySSORequested(provider)
    } else {
      func()
    }
  }

  const handleLoginWithGoogle = () => {
    const googleProvider = new GoogleAuthProvider()
    googleProvider.addScope('email')
    googleProvider.addScope('profile')
    wrapFirebaseAuthRequest(signInWithPopup(getAuth(), googleProvider))
  }

  const renderSignInWithGoogle = () => (
    <Button
      classes={{
        root: clsx([styles.ssoButton, styles.google]),
        text: styles.text,
        startIcon: styles.icon
      }}
      onClick={() => handleSSOWrapper('google', handleLoginWithGoogle)}
      startIcon={<GoogleLogo />}
    >
      Sign in with Google
    </Button>
  )

  const handleLoginWithApple = () => {
    const appleProvider = new OAuthProvider('apple.com')
    appleProvider.addScope('email')
    appleProvider.addScope('name')
    wrapFirebaseAuthRequest(signInWithPopup(getAuth(), appleProvider))
  }

  const renderSignInWithApple = () => (
    <Button
      classes={{
        root: clsx([styles.ssoButton, styles.apple]),
        text: styles.text,
        startIcon: styles.icon
      }}
      onClick={() => handleSSOWrapper('apple', handleLoginWithApple)}
      startIcon={<AppleLogo />}
    >
      Sign in with Apple
    </Button>
  )

  const handleForgotPasswordClicked = () => navigate('/forgot-password?' + new URLSearchParams({ email }).toString())

  const navigateToPasswordScreen = () => {
    setError('')
    setSearchParams({ email, promptForPassword: 1 })
  }

  const onEmailKeyUp = (e) => {
    if (e.key === 'Enter' && isEmailValid) {
      setPassword('')
      navigateToPasswordScreen()
    }
  }

  const renderEmailScreen = () => (
    <div className={styles.emailScreen}>
      <div className={styles.title}>Sign In</div>
      {renderSignInWithGoogle()}
      {(!isInMobileAppWebview() || isInIOSWebview()) && renderSignInWithApple()}
      <div className={styles.separator}>
        <div className={styles.line} />
        <div className={styles.text}>or</div>
        <div className={styles.line} />
      </div>
      <div className={clsx([styles.textField])}>
        <div className={styles.label}>Email</div>
        <TextField
          autoFocus={!isMobile}
          name='email'
          autoComplete='username'
          variant='outlined'
          classes={{ root: styles.muiRoot }}
          value={email || ''}
          onChange={(event) => setEmail(event.target.value.trim())}
          placeholder=''
          inputProps={{
            onKeyUp: onEmailKeyUp
          }}
        />
      </div>
      <Button
        disabled={!isEmailValid}
        classes={{ root: styles.continueButton, disabled: styles.disabled }}
        onClick={navigateToPasswordScreen}
      >
        Continue
      </Button>
      {renderPageSwitch()}
      {passwordResetConfirmation && (
        <PasswordResetEmailConfirmation
          setPasswordResetConfirmation={setPasswordResetConfirmation}
          styles={styles}
          isMobile={isMobile}
        />
      )}
    </div>
  )

  const signIn = () => {
    const promise = signInWithEmailAndPassword(getAuth(), email, password)
    wrapFirebaseAuthRequest(promise)
  }

  const onPasswordKeyUp = (e) => {
    if (e.key === 'Enter' && password) {
      signIn()
    }
  }

  const renderPasswordScreen = () => (
    <div className={styles.passwordScreen}>
      <div className={styles.title}>Sign In</div>
      <div className={styles.email}>{email}</div>
      <div className={styles.textField}>
        <div className={styles.label}>Password</div>
        <OutlinedInput
          autoFocus={!isMobile}
          type={showPassword ? 'text' : 'password'}
          classes={{ root: styles.muiRoot }}
          value={password || ''}
          onChange={(event) => setPassword(event.target.value)}
          placeholder=''
          disabled={isProcessingUserRequest}
          autoComplete='current-password'
          endAdornment={
            <InputAdornment position='end' className={styles.showPasswordIcon}>
              <IconButton tabIndex={-1} onClick={() => setShowPassword(!showPassword)} edge='end'>
                {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
              </IconButton>
            </InputAdornment>
          }
          inputProps={{
            onKeyUp: onPasswordKeyUp
          }}
        />
        {error && <div className={styles.error}>{error}</div>}
      </div>
      <Button
        classes={{ root: styles.continueButton, disabled: styles.disabled }}
        disabled={!password}
        onClick={signIn}
      >
        Continue
      </Button>
      <div className={styles.link} onClick={handleForgotPasswordClicked}>
        Forgot password?
      </div>
    </div>
  )

  return (
    <div className={clsx([styles.loginPage, { [styles.mobile]: isMobile }])}>
      {!isPasswordScreen && renderEmailScreen()}
      {isPasswordScreen && renderPasswordScreen()}
    </div>
  )
}

export default LoginPage
