import styled from '@emotion/styled'
import clsx from 'clsx'
import { useContext, useEffect, useRef, useState } from 'react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'

import { useVideoParams } from './hooks/use-video-params'

import { TabView } from '@/components/tabview'
import TaggingDialog from '@/components/tag-your-buds'
import VideoPage from '@/components/video-player'
import useMobileDetect from '@/hooks/use-mobile-detect'
import { useURLParams } from '@/hooks/use-url-params'
import { APIContext } from '@/utils/api'
import COLORS from '@/utils/colors'

const THEATER_MODE_WIDTH = '80vw !important'

const Container = styled('div')({
  maxWidth: '100vw',
  // width: '100vw !important', // this is causing the page to be wider than the viewport
  padding: '0',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  '& .wrapper': {
    maxWidth: '880px',
    width: '100% !important',
    padding: '15px',
    '& .solo-poster': {
      width: '100%'
    },
    '&.theater-mode': {
      width: THEATER_MODE_WIDTH,
      maxWidth: THEATER_MODE_WIDTH,
      '& .mux-container__video': {
        maxHeight: 802
      }
    },
    '&.portrait-video mux-player': {
      maxHeight: 460
    },
    '& .mux-container': {
      display: 'flex',
      flexDirection: 'column',
      position: 'relative',
      width: '100%',
      '& .mux-container__video': {
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
        width: '100%',
        height: '100%'
      },
      '& mux-player': {
        '--time-display': 'block',
        '--duration-display': 'block'
      },
      '& .mux-footer': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        backgroundColor: COLORS['neutral-100'],
        borderRadius: '0 0 8px 8px',
        padding: '8px 16px',
        minHeight: 45,
        '& .rally-number': {
          display: 'flex',
          color: COLORS['neutral-700'],
          fontSize: '18px',
          fontWeight: '600',
          lineHeight: '166%',
          '& button:first-of-type': {
            marginLeft: 16,
            marginRight: 8
          }
        }
      },
      '& .theater-mode-toggle': {
        position: 'absolute',
        bottom: '11px',
        right: '134px',
        cursor: 'pointer',
        fontSize: '24px',
        color: 'white',
        zIndex: 1
      }
    }
  },
  '& .insights-container': {
    maxWidth: '880px',
    width: '100% !important',
    padding: '15px',
    margin: '0 auto'
  },
  '&.mobile': {
    width: '100% !important',
    '& .solo-poster': {
      marginTop: '15px'
    }
  }
})

const Content = () => {
  // this container won't be rendered until video is populated, but it will
  // be potentially rendered before insights is available (insights may not
  // ever become ready if they fail to be compute by the server! or they may
  // just be computed way later if server-side processing is still in progress)
  const { muxPlayerRef, insights, video, workflow } = useContext(APIContext)
  const { handleWithVideoParams } = useVideoParams()
  const params = useParams()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const vid = params.vid

  const ignoreTParamRef = useRef(false)
  const [isSettingFlag, setIsSettingFlag] = useState(false)
  const { removeSearchParams } = useURLParams()

  useEffect(() => {
    const handleRemoveParams = () => {
      setIsSettingFlag(true)
    }

    const handleSeek = () => {
      setIsSettingFlag(true)
    }

    const attachListeners = () => {
      const muxPlayer = document.querySelector('mux-player')
      if (muxPlayer?.shadowRoot) {
        const mediaTheme = muxPlayer.shadowRoot.querySelector('media-theme')
        if (mediaTheme?.shadowRoot) {
          const mediaController = mediaTheme.shadowRoot.querySelector('media-controller')
          if (mediaController) {
            const mediaTimeRange = mediaController.querySelector('media-time-range')
            if (mediaTimeRange) {
              mediaTimeRange.addEventListener('click', handleRemoveParams)
            }

            const bottomControlBar = mediaController.querySelector('media-control-bar[part="control-bar bottom"]')
            if (bottomControlBar) {
              const seekBackwardButton = bottomControlBar.querySelector('media-seek-backward-button')
              const seekForwardButton = bottomControlBar.querySelector('media-seek-forward-button')

              if (seekBackwardButton) {
                seekBackwardButton.addEventListener('click', handleSeek)
              }
              if (seekForwardButton) {
                seekForwardButton.addEventListener('click', handleSeek)
              }
            }

            // Stop observing once listeners are attached
            if (observer) observer.disconnect()
          }
        }
      }
    }

    // Observe for changes in the DOM
    // eslint-disable-next-line no-undef
    const observer = new MutationObserver(attachListeners)
    observer.observe(document.body, { childList: true, subtree: true })

    // Cleanup
    return () => {
      observer.disconnect()
    }
  }, [])

  useEffect(() => {
    if (isSettingFlag) {
      ignoreTParamRef.current = true
      removeSearchParams('l')
      setIsSettingFlag(false)
    }
  }, [removeSearchParams, isSettingFlag])

  useEffect(() => {
    if (isSettingFlag) {
      return
    }

    const muxPlayer = muxPlayerRef.current

    if (muxPlayer) {
      handleWithVideoParams(muxPlayer, ignoreTParamRef)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [muxPlayerRef.current, isSettingFlag, handleWithVideoParams])

  useEffect(() => {
    // When url is shared with state = explore, redirect the user to /video/[vid]/explore page
    const queryState = searchParams.get('state')
    try {
      if (queryState) {
        const query = JSON.parse(decodeURI(queryState))
        if (query.path === 'explore') {
          const { path, ...filters } = query
          navigate({ pathname: `/video/${vid}/explore`, search: `filters=${encodeURI(JSON.stringify(filters))}` }, { replace: true })
        }
      }
    } catch (err) {
      console.error(`Query string ${queryState} not recognized.`)
    }
  }, [navigate, searchParams, vid])

  return insights?.rallies ? <TabView video={video} insights={insights} workflow={workflow} /> : null
}

function InsightsPage () {
  const isMobile = useMobileDetect()
  const { updateSearchParams, removeSearchParams } = useURLParams()
  const muxPlayerRef = useRef()

  function listenForRateChangeAndUpdateParams () {
    const currentRate = muxPlayerRef.current.playbackRate

    updateSearchParams('speed', currentRate)
  }

  function jumpToVideoTime (secsInOriginalVideo) {
    const muxPlayer = muxPlayerRef.current
    if (muxPlayer) {
      if (muxPlayer.paused || muxPlayer.ended) {
        muxPlayer.play()
      }
      updateSearchParams('t', secsInOriginalVideo)
      removeSearchParams('l')
      muxPlayer.currentTime = secsInOriginalVideo
    }
  }

  return (
    <Container className={clsx([{ mobile: isMobile }])}>
      <VideoPage
        jumpToVideoTime={jumpToVideoTime}
        onRateChange={listenForRateChangeAndUpdateParams}
        muxPlayerRef={muxPlayerRef}
        playerContent={<Content />}
        showDeadTimeToggle
        showHighlights
        showRallyNav
        showTheaterControl
      />
      <TaggingDialog />
    </Container>
  )
}

export default InsightsPage
