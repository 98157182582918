import { styled } from '@mui/material'
import React, { useContext } from 'react'

import { PlayerStatsContext } from '..'
import StatsSectionTitle from '../components/stats-section-title'

import CourtServesReturns from '@/assets/court-serves-returns.svg?react'
import { Avatar } from '@/components/avatar'
import { ServesReturns } from '@/components/serves-and-returns'
import useMobileDetect from '@/hooks/use-mobile-detect'
import cls from '@/utils/classnames'
import COLORS from '@/utils/colors'
import { column, row } from '@/utils/flexGrid'

export default function ServesReturnGraph (props) {
  const context = useContext(PlayerStatsContext)
  const isMobile = useMobileDetect()
  const playerIdx = props.playerIdx

  const insights = context?.insights

  if (!insights) {
    return null
  }

  const playerName = props.player.name ? `${props.player.name}'s` : `Player ${playerIdx + 1}`
  const isRounded = playerIdx < 2

  return (
    <Container className={isMobile && 'mobile'}>
      <div className='graph-description'>
        <StatsSectionTitle tooltip={`This section shows the depth of ${playerName}'s serves and returns during this game.`} title='Serve & Return Depth' />
        <span className='desc-subtitle'>
          Depth
        </span>
        <CourtServesReturns className='serves-return-court' />
        <Avatar width='26' initials={playerName} round={isRounded} className={cls('avatar', `player-${playerIdx}`, !isRounded && 'square')} />
      </div>
      <div className='graph'>
        <ServesReturns insights={insights} playerIdx={playerIdx} />
      </div>
    </Container>
  )
}

const Container = styled('div')({
  ...row,
  padding: 32,
  '& .graph-description': {
    ...column,
    width: 200,
    minWidth: 200,
    color: COLORS.black,
    alignItems: 'flex-end',
    gap: 8,
    position: 'relative',
    '& .desc-subtitle': {
      fontSize: 12,
      color: COLORS['neutral-500']
    }
  },
  '& .graph': {
    display: 'flex',
    flexGrow: 1
  },
  '& .avatar': {
    alignSelf: 'flex-end',
    marginTop: -30,
    marginRight: 47,

    '&.square': {
      marginRight: 48
    },
    '&.player-0': {
      color: COLORS.player1
    },
    '&.player-1': {
      color: COLORS.player2
    },
    '&.player-2': {
      color: COLORS.player3
    },
    '&.player-3': {
      color: COLORS.player4
    }
  },
  '&.mobile': {
    ...column,
    padding: 15,
    gap: 20,
    '& .graph-description': {
      width: '70%',
      marginLeft: 5,
      alignItems: 'flex-start',
      '& .desc-subtitle': {
        marginLeft: 'auto'
      }
    },
    '& .serves-return-court': {
      width: '100%'
    },
    '& .graph .mobile': {
      width: '100%'
    },
    '& .avatar': {
      marginRight: 60,
      marginTop: -35,
      '&.square': {
        marginRight: 62
      }
    }
  }
})
