/* jsx-a11y/no-static-element-interactions */
import VisibilityIcon from '@mui/icons-material/Visibility'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import IconButton from '@mui/material/IconButton'
import InputAdornment from '@mui/material/InputAdornment'
import OutlinedInput from '@mui/material/OutlinedInput'
import TextField from '@mui/material/TextField'
import clsx from 'clsx'
import emailValidator from 'email-validator'
import { GoogleAuthProvider, OAuthProvider, createUserWithEmailAndPassword, fetchSignInMethodsForEmail, signInWithPopup } from 'firebase/auth'
import { useEffect, useState } from 'react'
import { useMatch, useNavigate, useSearchParams } from 'react-router-dom'

import styles from './styles.module.scss'
import TermsSection from './TermsSection'

import AppleLogo from '@/assets/apple_logo.svg?react'
import GoogleLogo from '@/assets/google_logo.svg?react'
import { Button } from '@/components/button'
import { CircularLoadingIndicator } from '@/components/loading-indicator/CircularLoadingIndicator'
import useMobileDetect from '@/hooks/use-mobile-detect'
import { useIsAuthInitialized, useIsLoggedIn } from '@/store/auth'
import COLORS from '@/utils/colors'
import { normalizeCreateUserErrorMessage } from '@/utils/email'
import { getAuth } from '@/utils/firebase'
import { isInIOSWebview, isInMobileAppWebview, notifySSORequested } from '@/utils/mobile-app-communication'

function CreateAccount (props) {
  const isNative = !!props.isNative
  const isMobile = useMobileDetect()
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()
  const isAuthReady = useIsAuthInitialized()
  const isLoggedIn = useIsLoggedIn()
  const [email, setEmail] = useState(searchParams.get('email') || '')
  const [promiseCheckIfEmailAlreadyUsed, setPromiseCheckIfEmailAlreadyUsed] = useState()
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [error, setError] = useState('')
  const [isProcessingUserRequest, setIsProcessingUserRequest] = useState(false)
  const [showPassword, setShowPassword] = useState(false)
  const [alreadyExist, setIsAlreadyExist] = useState(false)
  const [showConfirmPassword, setShowConfirmPassword] = useState(false)
  const isPasswordScreen = Boolean(searchParams.get('promptForPassword'))
  const { validate: validateEmail } = emailValidator
  const isEmailValid = validateEmail(email)
  const passwordsMatch = !!password && !!confirmPassword && password === confirmPassword
  const matchUpload = useMatch('/anonymous-upload')
  const isSubscribe = useMatch('/subscribe')

  useEffect(() => {
    if (password.length < 6) {
      setError('Passwords must be at least 6 characters long.')
    } else if (!passwordsMatch) {
      setError('Passwords do not match')
    } else {
      setError('')
    }
  }, [password, passwordsMatch])

  useEffect(() => {
    if (isAuthReady && isLoggedIn && !matchUpload) {
      navigate(isSubscribe ? '/subscribe' : '/library', { replace: true })
    }
  }, [isSubscribe, isAuthReady, isLoggedIn, matchUpload, navigate])

  useEffect(() => {
    if (isPasswordScreen) {
      if (!isEmailValid) {
        if (searchParams.get('email')) {
          searchParams.delete('email')
          setSearchParams(searchParams)
        }
      }
    }
  }, [email, isEmailValid, isPasswordScreen, searchParams, setSearchParams])

  if (isLoggedIn || !isAuthReady) {
    return undefined // will redirect to library if logged in
  }

  function wrapFirebaseAuthRequest (promise) {
    setIsProcessingUserRequest(true)
    setError()
    promise
      .then(() => { }) // if logged in, will auto-redirect after redux updates
      .catch((err) => {
        if (matchUpload && err.code === 'auth/email-already-in-use') {
          setSearchParams({ forgot: 1, email })
        } else {
          const normalizedError = normalizeCreateUserErrorMessage(err)
          setError(normalizedError)
        }
      })
      .finally(() => setIsProcessingUserRequest(false))
  }

  async function checkIfEmailAlreadyUsed (email) {
    const auth = getAuth()
    try {
      const signInMethods = await fetchSignInMethodsForEmail(auth, email)
      const ourProviders = ['google.com', 'apple.com']
      let provider
      if (!signInMethods.length) {
        return false
      }

      if (signInMethods.length > 0 && signInMethods.some((method) => ourProviders.includes(method))) {
        provider = 'SSO'
      } else {
        provider = ''
      }

      const providerMessage = provider === 'SSO' ? `This account was created with ${provider}. Please choose a SSO method below to continue.` : ''

      setIsAlreadyExist({
        message: providerMessage,
        signInMethods,
        email
      })
      return true // account already exists
    } catch (error) {
      setIsAlreadyExist(false)
      console.error('Error fetching sign-in methods:', error)
      return false // we should handle this case instead of pretending error means account does not exist (we don't really know)
    }
  }

  function renderPageSwitch () {
    const title = 'Already have an account?'
    const link = 'Sign in'
    return (
      <div className={styles.pageSwitch}>
        <div className={styles.text}>{title}</div>
        <div
          className={styles.link}
          onClick={() => {
            navigate('/login?' + new URLSearchParams({ email }).toString())
          }}
        >
          {link}
        </div>
      </div>
    )
  }

  function handleSSOWrapper (provider, func) {
    if (isInMobileAppWebview()) {
      notifySSORequested(provider)
    } else {
      func()
    }
  }

  const handleLoginWithGoogle = () => {
    const googleProvider = new GoogleAuthProvider()
    googleProvider.addScope('email')
    googleProvider.addScope('profile')
    wrapFirebaseAuthRequest(signInWithPopup(getAuth(), googleProvider))
  }

  const renderSignInWithGoogle = () => (
    <Button
      classes={{
        root: clsx([styles.ssoButton, styles.google]),
        text: styles.text,
        startIcon: styles.icon
      }}
      onClick={() => handleSSOWrapper('google', handleLoginWithGoogle)}
      startIcon={<GoogleLogo />}
    >
      Sign in with Google
    </Button>
  )

  const handleLoginWithApple = () => {
    const appleProvider = new OAuthProvider('apple.com')
    appleProvider.addScope('email')
    appleProvider.addScope('name')
    wrapFirebaseAuthRequest(signInWithPopup(getAuth(), appleProvider))
  }

  const renderSignInWithApple = () => (
    <Button
      classes={{
        root: clsx([styles.ssoButton, styles.apple]),
        text: styles.text,
        startIcon: styles.icon
      }}
      onClick={() => handleSSOWrapper('apple', handleLoginWithApple)}
      startIcon={<AppleLogo />}
    >
      Sign in with Apple
    </Button>
  )

  const navigateToPasswordScreen = () => {
    if (validateEmail(email)) {
      setError('')
      setSearchParams({ email, promptForPassword: 1 })
      setPromiseCheckIfEmailAlreadyUsed(checkIfEmailAlreadyUsed(email))
    }
  }

  const onEmailKeyUp = (e) => {
    if (e.key === 'Enter' && isEmailValid) {
      setPassword('')
      navigateToPasswordScreen()
    }
  }
  const onPasswordKeyUp = (e) => {
    if (e.key === 'Enter') {
      signUp()
    }
  }

  const renderEmailScreen = () => (
    <div className={styles.emailScreen}>
      <div className={styles.title}>Create an account</div>
      {renderSignInWithGoogle()}
      {(!isInMobileAppWebview() || isInIOSWebview()) && renderSignInWithApple()}
      <div className={styles.separator}>
        <div className={styles.line} />
        <div className={styles.text}>or</div>
        <div className={styles.line} />
      </div>
      <div className={clsx([styles.textField])}>
        <div className={styles.label}>Email</div>
        <TextField
          autoFocus={!isMobile}
          variant='outlined'
          classes={{ root: styles.muiRoot }}
          value={email || ''}
          name='email'
          onChange={(event) => setEmail(event.target.value.trim())}
          placeholder=''
          inputProps={{
            onKeyUp: onEmailKeyUp
          }}
        />
      </div>
      {(matchUpload || isNative) && <TermsSection />}
      <Button
        disabled={!isEmailValid}
        classes={{ root: styles.continueButton, disabled: styles.disabled }}
        onClick={navigateToPasswordScreen}
      >
        Continue
      </Button>
      {renderPageSwitch()}
    </div>
  )

  const signUp = async () => {
    if (error) {
      return
    }
    const emailAlreadyUsed = await promiseCheckIfEmailAlreadyUsed
    if (emailAlreadyUsed) {
      setIsProcessingUserRequest(false)
      return
    }
    const promise = createUserWithEmailAndPassword(getAuth(), email, password)
    wrapFirebaseAuthRequest(promise)
  }

  const renderIfEmailExists = () => {
    return (
      <div className={styles.emailScreen}>
        <div className={styles.title}>Account Already Exists</div>
        <div className={clsx(styles.subtitle)} style={{ color: COLORS['warning-500'] }}>An account with the email address {email} already exists.</div>
        <div className={styles.subtitle}>{alreadyExist.message}</div>

        {alreadyExist.signInMethods.includes('google.com') && renderSignInWithGoogle()}
        {alreadyExist.signInMethods.includes('apple.com') && renderSignInWithApple()}

        <div className={styles.pageSwitch} style={{ marginTop: '40px' }}>
          {!alreadyExist && (
            <div className={styles.text}>Already have an account?</div>)}
          <div
            className={styles.link} onClick={() => {
              setIsAlreadyExist(false)
              navigate('/login?' + new URLSearchParams({ email, promptForPassword: 1 }).toString())
            }}
          >
            {alreadyExist && 'Go '}Sign in
          </div>
        </div>
        {!alreadyExist && (
          <div className={styles.pageSwitch}>
            <div className={styles.text}>Don&apos;t have an account?</div>
            <div
              className={styles.link} onClick={() => {
                setIsAlreadyExist(false)
                navigate('/create-account')
              }}
            >
              Create one
            </div>
          </div>
        )}
      </div>
    )
  }

  const renderPasswordScreen = () => {
    const renderPasswordField = () => (
      <div className={clsx([styles.textField, styles.password])}>
        <div className={styles.label}>Create Password</div>
        <OutlinedInput
          autoFocus={!isMobile}
          type={showPassword ? 'text' : 'password'}
          classes={{ root: styles.muiRoot }}
          value={password || ''}
          onChange={(event) => setPassword(event.target.value)}
          placeholder=''
          disabled={isProcessingUserRequest}
          autoComplete='new-password'
          inputProps={{
            onKeyUp: onPasswordKeyUp
          }}
          endAdornment={
            <InputAdornment position='end' className={styles.showPasswordIcon}>
              <IconButton tabIndex={-1} onClick={() => setShowPassword(!showPassword)} edge='end'>
                {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
              </IconButton>
            </InputAdornment>
          }
        />
      </div>
    )
    const renderConfirmPasswordField = () => (
      <div className={clsx([styles.textField, styles.confirmPassword])}>
        <div className={styles.label}>Confirm Password</div>
        <OutlinedInput
          type={showConfirmPassword ? 'text' : 'password'}
          classes={{ root: styles.muiRoot }}
          value={confirmPassword || ''}
          onChange={(event) => setConfirmPassword(event.target.value)}
          placeholder=''
          disabled={isProcessingUserRequest}
          autoComplete='new-password'
          inputProps={{
            onKeyUp: onPasswordKeyUp
          }}
          endAdornment={
            <InputAdornment position='end' className={styles.showPasswordIcon}>
              <IconButton tabIndex={-1} onClick={() => setShowConfirmPassword(!showConfirmPassword)} edge='end'>
                {showConfirmPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
              </IconButton>
            </InputAdornment>
          }
        />
        {error && <div className={styles.error}>{error}</div>}
      </div>
    )

    return (
      <form
        className={styles.passwordScreen} onSubmit={e => {
          signUp()
          e.stopPropagation()
        }}
      >
        <div className={styles.title}>Create Password</div>
        <div className={styles.subtitle}>Your user name is:</div>
        <div className={styles.email}>{email}</div>
        <input type='hidden' name='email' autoComplete='username' value={email} />
        {renderPasswordField()}
        {renderConfirmPasswordField()}
        <Button
          classes={{ root: styles.continueButton, disabled: styles.disabled }}
          disabled={(!password && !confirmPassword) || isProcessingUserRequest || !passwordsMatch}
          onClick={signUp}
        >
          Continue
        </Button>
        {isProcessingUserRequest &&
          (
            <div className={styles.spinner}>
              <CircularLoadingIndicator label='Creating account' />
            </div>
          )}
      </form>
    )
  }

  return (
    <div className={clsx(['create-account', styles.createAccount, { [styles.mobile]: isMobile }])}>
      {!isPasswordScreen && renderEmailScreen()}
      {isPasswordScreen && !alreadyExist && renderPasswordScreen()}
      {isPasswordScreen && alreadyExist && renderIfEmailExists()}
    </div>
  )
}

export default CreateAccount
