import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import StarsIcon from '@mui/icons-material/Stars'
import { styled } from '@mui/material'
import { useEffect, useState } from 'react'

import { Button } from '../button'

import { useIsBuyingEnabled, useMonetizationPricing } from '@/hooks/monetization'
import useMobileDetect from '@/hooks/use-mobile-detect'
import useZendesk from '@/hooks/use-zendesk'
import { useIsLoggedIn, useLoggedInUser, useMyMonetizationData } from '@/store/auth'
import cls from '@/utils/classnames'
import COLORS from '@/utils/colors'
import { column } from '@/utils/flexGrid'

function PlanItem (props) {
  const { item } = props

  const ItemIcon = item.gold ? StarsIcon : CheckCircleIcon
  return (
    <div className={cls('plan-item', item.gold && 'gold')} key={item.title}>
      <div className='item-header'>
        {item.comingSoon ? <p className='coming-soon'>Coming soon!</p> : null}
      </div>
      <div className='item-body'>
        <ItemIcon className='item-icon' />
        <p className='item-name'>{item.text}</p>
      </div>
    </div>
  )
}

export function PricingPlan ({ plan, purchaseFlow, decreasedSize, promptToLogin }) {
  const isMobile = useMobileDetect()
  const md = useMyMonetizationData()
  const { getAnnuallyCostByPlan, getMonthlyCostByPlan } = useMonetizationPricing()
  const { toggleZendeskWidget } = useZendesk()
  const isBuyingDisabled = !useIsBuyingEnabled()
  const isLoggedIn = useIsLoggedIn()
  const [isInQue, setInQue] = useState(false)
  const user = useLoggedInUser()

  const disabled = plan.actionButton.isDisabled?.(md) || (plan.tier && isBuyingDisabled)
  const handleTryButton = () => {
    // If professional, toggle zendesk widget
    if (plan.tier === 'professional') {
      plan.actionButton.action(purchaseFlow, toggleZendeskWidget)
      return
    }

    // If not logged in, prompt user to login
    if (!isLoggedIn) {
      promptToLogin && promptToLogin()
      setInQue(true)
      return
    }

    plan.actionButton.action(purchaseFlow, toggleZendeskWidget)
  }

  useEffect(() => {
    if (isInQue && user?.token) {
      plan.actionButton.action(purchaseFlow, toggleZendeskWidget)
      setInQue(false)
    }
  }, [isInQue, plan.actionButton, purchaseFlow, toggleZendeskWidget, user?.token])

  return (
    <Container className={cls(isMobile && 'mobile', decreasedSize && 'decreased-size')}>
      <div className='plan-title'>
        {plan.title}
      </div>
      <div className='plan-price'>
        {plan.oldPrice && <span>{plan.oldPrice}</span>}
        {plan.price ? plan.price : `$${getMonthlyCostByPlan(plan.tier)}/mo`}
      </div>
      <div className='plan-billing'>
        {plan.billing ? plan.billing : `Billed at $${getAnnuallyCostByPlan(plan.tier)} Annually`}
      </div>
      {plan.description &&
        <div className='plan-description'>
          {plan.description}
        </div>}
      <Button
        className='green'
        onClick={handleTryButton}
        disabled={disabled}
      >
        {(isBuyingDisabled && plan.tier)
          ? 'Coming soon!'
          : (plan.tier && md.currentSubscription?.tier === plan.tier)
              ? 'Already Subscribed'
              : plan.actionButton.text}
      </Button>
      {plan.everythingFromInfo &&
        <div className='plan-billing'>
          {plan.everythingFromInfo}
        </div>}
      <div className='plan-items'>
        {plan.items.map((item, i) => <PlanItem key={i} item={item} />)}
      </div>
      {plan.customSection}
    </Container>
  )
}

const Container = styled('div')({
  ...column,
  width: '360px',
  padding: '36px 28px 50px',
  border: `1px solid ${COLORS['neutral-400']}`,
  borderRadius: '8px',
  minHeight: '738px',
  gap: '10px',
  textAlign: 'start',

  // font-sizes
  '& .plan-title, .item-name, a': {
    fontSize: '20px'
  },

  '& .plan-title': {
    color: COLORS.black,
    fontWeight: 700,
    lineHeight: '138%'
  },
  '& .plan-price': {
    color: COLORS.black,
    fontSize: '48px',
    fontWeight: 700,
    lineHeight: '142%',
    '& span': {
      textDecoration: 'line-through',
      color: COLORS['neutral-500'],
      fontSize: '36px',
      marginRight: 10
    }
  },
  '& .plan-billing, .trusted-by': {
    color: COLORS['neutral-500'],
    fontWeight: 600,
    fontSize: 14,
    lineHeight: '138%'
  },
  '& .trusted-by': {
    textAlign: 'center'
  },
  '& .plan-description': {
    color: COLORS.black,
    fontWeight: 400,
    lineHeight: '158%'
  },
  '& .plan-items': {
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
    '& .plan-item': {
      padding: '4px',
      '& .coming-soon': {
        color: COLORS['warning-600'],
        fontWeight: 700,
        fontSize: '14px',
        textTransform: 'uppercase',
        paddingLeft: '34px'
      },
      '& .item-body': {
        display: 'flex',
        gap: '10px',
        '& .item-icon': {
          fontSize: '24px',
          color: COLORS['primary-500'],
          marginTop: '2px'
        },
        '& .item-name': {
          color: COLORS.black,
          fontWeight: 400,
          lineHeight: '138%',
          '& a': {
            fontWeight: 600
          }
        }
      },
      '&.gold': {
        backgroundColor: COLORS['warning-050'],
        '& .item-icon': {
          color: COLORS.player3
        }
      }
    }
  },
  '&.mobile': {
    fontSize: '14px',
    '& .plan-title, .item-name, a': {
      fontSize: '16px'
    },
    '& .plan-price': {
      fontSize: '24px',
      lineHeight: '26px',
      letterSpacing: 0,
      '& span': {
        fontSize: '20px'
      }
    },
    '& .plan-items': {
      '& .plan-item': {
        '& .item-icon': {
          marginTop: '0'
        }
      }
    }
  },
  '&.decreased-size': {
    width: 'auto',
    maxWidth: '230px',
    minHeight: 'auto',
    padding: '25px 22px ',
    gap: '5px',
    '& .plan-title, .item-name, a': {
      fontSize: '14px'
    },
    '& .plan-price': {
      fontSize: '20px'
    },
    '& .plan-items': {
      gap: '5px'
    },
    '& .item-icon': {
      fontSize: '16px !important'
    },
    '& .coming-soon': {
      fontSize: '10px !important'
    }
  }
})
