import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout
} from '@stripe/react-stripe-js'
import { useCallback, useEffect, useState } from 'react'

import { Spinner } from '@/components/spinner'
import { GiftPurchaseSuccessful, PurchaseSuccessful, PurchaseUnsuccessful } from '@/pages/purchase-done'
import Container, { CheckoutContainer } from '@/pages/stripe/container'
import { useAnonymousId, useLoggedInUserCredentials } from '@/store/auth'
import { isInMobileAppWebview } from '@/utils/mobile-app-communication'

// created outside of any component to avoid recreating it
async function loadStripeWrapper () {
  if (isInMobileAppWebview()) {
    return
  }
  const { loadStripe } = await import('@stripe/stripe-js')
  return loadStripe(import.meta.env.STRIPE_PUBLISHER_KEY)
}
export const stripePromise = loadStripeWrapper()

export function CheckoutForm ({ productId }) {
  const { token, uid } = useLoggedInUserCredentials()
  const anonymousId = useAnonymousId()

  const fetchClientSecret = useCallback(() => {
    const body = { productId }
    if (window.location.hostname.startsWith('localhost')) {
      body.toLocalhost = true // redirect to localhost web app when checkout is done
    }
    const headers = {
      'Content-Type': 'application/json'
    }
    if (uid && token) {
      headers['x-uid'] = uid
      headers['x-token'] = token
    } else {
      headers['x-uid'] = anonymousId
    }

    return window.fetch(`${import.meta.env.VITE_API_SERVER}/stripe/session/create`, {
      method: 'POST',
      headers,
      body: JSON.stringify(body)
    })
      .then(res => res.json())
      .then(data => data.clientSecret)
  }, [anonymousId, productId, token, uid])

  if (isInMobileAppWebview()) {
    return 'invalid checkout component for mobile app'
  }
  // if (!token) {
  //   return <Spinner text='Loading...' />
  // }

  const options = { fetchClientSecret }
  return (
    <CheckoutContainer id='checkout'>
      <EmbeddedCheckoutProvider
        stripe={stripePromise}
        options={options}
      >
        <EmbeddedCheckout className='stripe-checkout-content' />
      </EmbeddedCheckoutProvider>
    </CheckoutContainer>
  )
}

// This component is used for stripe checkout page and not the native ones.
function StripeCheckoutReturnPage () {
  const anonymousId = useAnonymousId()
  const { token, uid } = useLoggedInUserCredentials()
  const [status, setStatus] = useState(null)
  const [giftId, setGiftId] = useState(null)

  useEffect(() => {
    const queryString = window.location.search
    const urlParams = new URLSearchParams(queryString)
    const sessionId = urlParams.get('session_id')
    let isReady = false

    if (isInMobileAppWebview()) {
      return
    }

    const headers = {
      'Content-Type': 'application/json'
    }

    if (anonymousId) {
      headers['x-uid'] = anonymousId
      isReady = true
    }
    if (uid && token) {
      headers['x-uid'] = uid
      headers['x-token'] = token
      isReady = true
    }

    function fetch () {
      if (!isReady) {
        return
      }

      window.fetch(`${import.meta.env.VITE_API_SERVER}/stripe/session/status`, {
        method: 'POST',
        headers,
        body: JSON.stringify({ sessionId })
      })
        .then(res => res.json())
        .then(data => {
          setStatus(data.status)

          if (data?.giftId) {
            setGiftId(data.giftId)
          }
        })
    }

    fetch()
  }, [anonymousId, token, uid])

  if (isInMobileAppWebview()) {
    return 'invalid checkout return component for mobile app'
  }

  if (status === null) {
    return <Spinner text='Loading...' />
  }

  return (
    <Container>
      {status === 'complete'
        ? (
            giftId ? <GiftPurchaseSuccessful giftId={giftId} /> : <PurchaseSuccessful />
          )
        : (
          <PurchaseUnsuccessful />
          )}
    </Container>
  )
}

export default StripeCheckoutReturnPage
