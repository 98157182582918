import CloseIcon from '@mui/icons-material/Close'
import EditIcon from '@mui/icons-material/Edit'
import clsx from 'clsx'
import { useState, useRef, useCallback } from 'react'
import { useDispatch } from 'react-redux'

import { AlgoliaWrapper } from './algolia-wrapper'
import { Container } from './container'
import { SelfTagPopup } from './self-tag-popup'
import { TagPlayerPopup } from './tag-player-popup'

import { tagUser } from '@/store/library'
import { useDialog } from '@/store/providers/dialog-provider'
import { useMayEditVideo } from '@/store/video'

export const getDefaultPlayerName = (playerIdx) => `Player ${playerIdx + 1}`

/**
 * TagPlayer component
 *
 * @exports
 * @param props {object} {vid, playerIdx, playerName, aiEngineVersion, truncate}
 * @returns {React.ReactElement}
 */
export function TagPlayer (props) {
  const anchorElRef = useRef(null)
  const [tagPlayerPopupOpened, setTagPlayerPopupOpened] = useState(false)
  const [selfTagPopupOpened, setSelfTagPopupOpened] = useState(false)
  const { vid, playerIdx, playerName, aiEngineVersion, truncate } = props
  const taggable = useMayEditVideo(vid)
  const dispatch = useDispatch()
  const showDialog = useDialog()

  const getDefaultInitialName = useCallback(() => {
    return getDefaultPlayerName(playerIdx)
  }, [playerIdx])

  const initialName = playerName || getDefaultInitialName()

  const onContainerClick = (event) => {
    if (!taggable) return
    setTagPlayerPopupOpened(true)
  }

  const onRemoveTagClick = (event) => {
    if (!taggable) return
    event.stopPropagation()
    showDialog({
      title: 'Are you sure you want to untag a player?',
      disagreeText: 'Keep player tagged',
      agreeText: 'Untag player',
      disagreeButtonClasses: 'gray',
      confirmButtonClasses: 'danger',
      onAccept: () => {
        dispatch(tagUser({ vid, playerIdx }))
      },
      onCancel: () => {}
    })
  }

  return (
    <>
      <Container
        truncate={truncate}
        onClick={onContainerClick}
        sx={props.sx}
        ref={anchorElRef}
        className={clsx([{ taggable, tagged: !!playerName }])}
      >
        {props.children}
        <div className='player-name'>
          <div className='name-container'>
            <span>{initialName}</span>
          </div>
          {taggable &&
            <>
              {!!playerName &&
                <div className='remove-icon-container' onClick={onRemoveTagClick}>
                  <CloseIcon titleAccess='Remove Tag' />
                </div>}
              {!playerName && <EditIcon className='edit-icon' />}
            </>}
        </div>
      </Container>
      <AlgoliaWrapper indexName='users' searchAttributes={['name']}>
        <TagPlayerPopup
          open={tagPlayerPopupOpened}
          anchorEl={anchorElRef.current}
          onClose={() => setTagPlayerPopupOpened(false)}
          vid={vid}
          playerIdx={playerIdx}
          initialName={initialName}
          playerName={playerName}
          aiEngineVersion={aiEngineVersion}
          onOpenSelfTagPopup={() => setSelfTagPopupOpened(true)}
        />
        <SelfTagPopup
          vid={vid}
          playerIdx={playerIdx}
          open={selfTagPopupOpened}
          aiEngineVersion={aiEngineVersion}
          anchorEl={anchorElRef.current}
          onClose={() => {
            setSelfTagPopupOpened(false)
          }}
        />
      </AlgoliaWrapper>
    </>
  )
}
