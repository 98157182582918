import SettingsIcon from '@mui/icons-material/Settings'
import StarIcon from '@mui/icons-material/Star'
import { styled } from '@mui/material/styles'
import dayjs from 'dayjs'

import Button from './button'

import StripeDialog from '@/components/dialog/stripe-dialog'
import { SUBSCRIPTION_PLANS } from '@/hooks/monetization'
import { usePurchaseFlow } from '@/hooks/use-purchase-flow'
import useZendesk from '@/hooks/use-zendesk'
import { millinow } from '@/utils'
import cls from '@/utils/classnames'
import COLORS from '@/utils/colors'
import { column, row } from '@/utils/flexGrid'

const subscriptionLinks = {
  apple: 'https://support.apple.com/guide/iphone/view-or-cancel-subscriptions-iph4e3e7324f/ios',
  google: 'https://support.google.com/googleplay/answer/7018481?hl=en&co=GENIE.Platform%3DAndroid',
  stripe: 'https://example.com'
}

const subscriptionSources = {
  apple: 'Apple App Store',
  google: 'Google Play Store',
  stripe: 'Stripe'
}

/**
 * SubscriptionCard component displays information about a user's subscription.
 *
 * @component
 * @param {Object} props - The props for the component
 * @param {Object} props.currentSubscription - The name/type of the current subscription
 * @param {number} props.currentSubscription.start - The start date of the subscription
 * @param {number} props.currentSubscription.end - The end date of the subscription
 * @param {string} props.currentSubscription.tier - One of `premium` or `starter`
 * @param {string} props.currentSubscription.src - One of 'apple`, `android` or `stripe`
 * @returns {JSX.Element} The rendered component
 */
const SubscriptionCard = (props) => {
  const { currentSubscription: { start, end, tier, src } = {}, giftSubscription = {} } = props
  const isPremium = tier === SUBSCRIPTION_PLANS.PREMIUM
  const subscription = isPremium ? 'Premium' : 'Starter'
  const isStripe = src === 'stripe'
  const isExpired = end <= millinow()
  const startDT = dayjs(start).format('M/D/YY')
  const endsDT = dayjs(end).format('M/D/YY')
  const { toggleZendeskWidget } = useZendesk()
  const { clearSKUToBuy, skuToBuy, subscribeToPlan } = usePurchaseFlow()

  return (
    <Container className={cls(isExpired && 'expired')}>
      <div className='s-title'>
        {isExpired ? 'Expired' : 'Current'} Subscription: <b>{subscription}</b>
      </div>
      <div className='s-notice'>
        You {isExpired ? 'were' : 'are'} subscribed to this service via {subscriptionSources[src]}
      </div>
      {giftSubscription?.type === SUBSCRIPTION_PLANS.PREMIUM && !isPremium &&
        <div className='s-notice'>
          You also have access to <b>Shot Explorer</b> via your gift until <b>{giftSubscription?.endDateLabel}</b>.
        </div>}

      <div className='s-period'>
        <div className='s-start'>Subscription started on {startDT}</div>
        <div className='s-end'>Subscription {isExpired ? 'ended' : 'ends'} on {endsDT}</div>
      </div>
      <div className='s-buttons'>
        {(!isPremium && isExpired) && <Button onClick={() => subscribeToPlan('starter')} className='outlined'>Resubscribe to Starter</Button>}
        {!isExpired && (
          isStripe
            ? (
              <Button onClick={toggleZendeskWidget} className='outlined'>Manage your subscription <SettingsIcon /></Button>
              )
            : (
              <Button href={subscriptionLinks[src]} className='outlined'>Manage your subscription <SettingsIcon /></Button>

              )
        )}
        {(!isPremium || isExpired) && <Button onClick={() => subscribeToPlan('premium')}>{isPremium && isExpired ? 'Resubscribe to Premium' : 'Upgrade to Premium'} <StarIcon sx={{ color: COLORS.player3 }} /></Button>}
      </div>
      <StripeDialog skuToBuy={skuToBuy} isOpen={skuToBuy} onClose={clearSKUToBuy} />
    </Container>
  )
}

/**
 * Styled container for the SubscriptionCard component.
 * Applies specific styling including layout and colors.
 */
const Container = styled('div')({
  ...column,
  alignItems: 'center',
  alignSelf: 'stretch',
  background: '#fff',
  border: `1px solid ${COLORS['neutral-300']}`,
  borderRadius: 'var(--radius-sm, 8px)',
  color: COLORS['neutral-800'],
  gap: 16,
  marginTop: 24,
  padding: '16px 0px',

  '& .s-title': {
    fontSize: 18,
    fontWeight: 400,
    letterSpacing: -1,
    lineHeight: '166%',
    textAlign: 'center'
  },
  '&.expired .s-title': {
    color: COLORS['danger-800']
  },
  '& .s-notice': {
    fontSize: 12,
    fontWeight: 500,
    lineHeight: '133%',
    textAlign: 'center'
  },
  '& .s-period': {
    ...row,
    alignItems: 'center',
    alignSelf: 'stretch',
    backgroundColor: COLORS['neutral-200'],
    gap: 32,
    justifyContent: 'center',
    padding: '8px 0',

    '& > div': {
      fontSize: 14,
      fontWeight: 600,
      lineHeight: '142%',
      textAlign: 'center'
    }
  },
  '&.expired .s-period': {
    backgroundColor: COLORS['danger-100']
  },
  '& .s-buttons': {
    alignItems: 'center',
    alignSelf: 'stretch',
    display: 'flex',
    gap: 8,
    justifyContent: 'center',
    padding: '0 16px'
  }
})

export default SubscriptionCard
