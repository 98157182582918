/* jsx-a11y/no-static-element-interactions */
import CloseIcon from '@mui/icons-material/Close'
import VisibilityIcon from '@mui/icons-material/Visibility'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import IconButton from '@mui/material/IconButton'
import InputAdornment from '@mui/material/InputAdornment'
import OutlinedInput from '@mui/material/OutlinedInput'
import TextField from '@mui/material/TextField'
import clsx from 'clsx'
import { validate as validateEmail } from 'email-validator'
import { createUserWithEmailAndPassword, fetchSignInMethodsForEmail, GoogleAuthProvider, OAuthProvider, sendPasswordResetEmail, signInWithEmailAndPassword, signInWithPopup } from 'firebase/auth'
import { useEffect, useState } from 'react'

import { PasswordResetEmailConfirmation } from '../../pages/login/alert'

import styles from './styles.module.scss'

import AppleLogo from '@/assets/apple_logo.svg?react'
import GoogleLogo from '@/assets/google_logo.svg?react'
import { Button } from '@/components/button'
import { CircularLoadingIndicator } from '@/components/loading-indicator/CircularLoadingIndicator'
import useMobileDetect from '@/hooks/use-mobile-detect'
import { useIsAuthInitialized, useIsLoggedIn } from '@/store/auth'
import COLORS from '@/utils/colors'
import { normalizeCreateUserErrorMessage, normalizeLoginErrorMessage, normalizeSendEmailErrorMessage } from '@/utils/email'
import { getAuth } from '@/utils/firebase'
import { isInIOSWebview, isInMobileAppWebview, notifySSORequested } from '@/utils/mobile-app-communication'

const DIALOG_TYPE = {
  LOGIN: 'login',
  CREATE_ACCOUNT: 'create_account',
  FORGOT_PASSWORD: 'forgot_password'
}

function SignUpAndLoginDialog ({ initDialogType, handleCloseDialog }) {
  const isMobile = useMobileDetect()
  const isAuthReady = useIsAuthInitialized()
  const isLoggedIn = useIsLoggedIn()

  const [dialogType, setDialogType] = useState(initDialogType ?? DIALOG_TYPE.CREATE_ACCOUNT)

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [showPassword, setShowPassword] = useState(false)
  const [error, setError] = useState('')
  const [isProcessingUserRequest, setIsProcessingUserRequest] = useState(false)
  const [isFromPasswordReset, setIsFromPasswordReset] = useState(false)

  const isEmailValid = validateEmail(email)

  function getNormalizer () {
    if (dialogType === DIALOG_TYPE.LOGIN) {
      return normalizeLoginErrorMessage
    }
    if (dialogType === DIALOG_TYPE.CREATE_ACCOUNT) {
      return normalizeCreateUserErrorMessage
    }
    if (dialogType === DIALOG_TYPE.FORGOT_PASSWORD) {
      return normalizeSendEmailErrorMessage
    }
  }

  function wrapFirebaseAuthRequest (promise) {
    setIsProcessingUserRequest(true)
    setError()
    let hasError = false
    promise
      .then(() => { }) // if logged in, will auto-redirect after redux updates
      .catch((err) => {
        hasError = true
        const normalizedError = getNormalizer()(err)
        console.log(normalizedError)
        setError(normalizedError)
      })
      .finally(() => {
        setIsProcessingUserRequest(false)
        if (hasError) {
          return
        }

        if (dialogType === DIALOG_TYPE.FORGOT_PASSWORD) {
          setDialogType(DIALOG_TYPE.LOGIN)
          setIsFromPasswordReset(true)
          return
        }

        if (!hasError) {
          handleCloseDialog()
        }
      })
  }

  const props = {
    isMobile,
    isAuthReady,
    isLoggedIn,
    email,
    setEmail,
    password,
    setPassword,
    error,
    setError,
    showPassword,
    setShowPassword,
    isEmailValid,
    setDialogType,
    handleCloseDialog,
    isProcessingUserRequest,
    setIsProcessingUserRequest,
    wrapFirebaseAuthRequest,
    isFromPasswordReset,
    setIsFromPasswordReset
  }

  useEffect(() => {
    if (isAuthReady && isLoggedIn) {
      // If for some reason the dialog pop up when a user is logged in, we are going to hide it
      handleCloseDialog()
    }
  }, [isAuthReady, isLoggedIn, handleCloseDialog])

  return (
    <div className={clsx([styles.dialog, { [styles.mobile]: isMobile }])}>
      {dialogType === DIALOG_TYPE.CREATE_ACCOUNT && <CreateAccount {...props} />}
      {dialogType === DIALOG_TYPE.LOGIN && <Login {...props} />}
      {dialogType === DIALOG_TYPE.FORGOT_PASSWORD && <ForgotPassword {...props} />}
    </div>
  )
}

function CreateAccount (props) {
  const { isMobile, email, setEmail, password, setPassword, error, setError, showPassword, setShowPassword, isEmailValid, setDialogType, handleCloseDialog, isProcessingUserRequest, setIsProcessingUserRequest, wrapFirebaseAuthRequest } = props

  const [alreadyExist, setIsAlreadyExist] = useState(false)
  const [isPasswordScreen, setIsPasswordScreen] = useState(false)
  const [confirmPassword, setConfirmPassword] = useState('')
  const [showConfirmPassword, setShowConfirmPassword] = useState(false)
  const [promiseCheckIfEmailAlreadyUsed, setPromiseCheckIfEmailAlreadyUsed] = useState()

  const passwordsMatch = !!password && !!confirmPassword && password === confirmPassword

  useEffect(() => {
    if (password.length < 6) {
      setError('Passwords must be at least 6 characters long.')
    } else if (!passwordsMatch) {
      setError('Passwords do not match')
    } else {
      setError('')
    }
  }, [password, passwordsMatch, setError])

  async function checkIfEmailAlreadyUsed (email) {
    const auth = getAuth()
    try {
      const signInMethods = await fetchSignInMethodsForEmail(auth, email)
      const ourProviders = ['google.com', 'apple.com']
      let provider
      if (!signInMethods.length) {
        return false
      }

      if (signInMethods.length > 0 && signInMethods.some((method) => ourProviders.includes(method))) {
        provider = 'SSO'
      } else {
        provider = ''
      }

      const providerMessage = provider === 'SSO' ? `This account was created with ${provider}. Please choose a SSO method below to continue.` : ''
      setIsAlreadyExist({
        message: providerMessage,
        signInMethods,
        email
      })
      return true // account already exists
    } catch (error) {
      setIsAlreadyExist(false)
      return false // we should handle this case instead of pretending error means account does not exist (we don't really know)
    }
  }

  const navigateToPasswordScreen = () => {
    if (validateEmail(email)) {
      setError('')
      setIsPasswordScreen(true)
      setPromiseCheckIfEmailAlreadyUsed(checkIfEmailAlreadyUsed(email))
    }
  }

  const onEmailKeyUp = (e) => {
    if (e.key === 'Enter' && isEmailValid) {
      setPassword('')
      navigateToPasswordScreen()
    }
  }

  const onPasswordKeyUp = (e) => {
    if (e.key === 'Enter') {
      signUp()
    }
  }

  const renderEmailScreen = () => (
    <div className={styles.emailScreen}>
      <div className={styles.row}>
        <div className={styles.title}>Create an account</div>
        <div className={styles.closeIcon}>
          <IconButton className={styles.iconButton} onClick={handleCloseDialog}>
            <CloseIcon />
          </IconButton>
        </div>
      </div>
      <SignInWith {...props} />
      <div className={styles.separator}>
        <div className={styles.line} />
        <div className={styles.text}>or</div>
        <div className={styles.line} />
      </div>
      <div className={clsx([styles.textField])}>
        <div className={styles.label}>Email</div>
        <TextField
          autoFocus={!isMobile}
          variant='outlined'
          classes={{ root: styles.muiRoot }}
          value={email || ''}
          name='email'
          onChange={(event) => setEmail(event.target.value.trim())}
          placeholder=''
          inputProps={{ onKeyUp: onEmailKeyUp }}
        />
      </div>
      <Button
        disabled={!isEmailValid}
        classes={{ root: styles.continueButton, disabled: styles.disabled }}
        onClick={navigateToPasswordScreen}
      >
        Continue
      </Button>
      <div className={styles.pageSwitch}>
        <div className={styles.text}>Already have an account?</div>
        <div
          className={styles.link}
          onClick={() => setDialogType(DIALOG_TYPE.LOGIN)}
        >
          Sign in
        </div>
      </div>
    </div>
  )

  const signUp = async () => {
    if (error) {
      return
    }
    const emailAlreadyUsed = await promiseCheckIfEmailAlreadyUsed
    if (emailAlreadyUsed) {
      setIsProcessingUserRequest(false)
      return
    }
    const promise = createUserWithEmailAndPassword(getAuth(), email, password)
    wrapFirebaseAuthRequest(promise)
  }

  const renderIfEmailExists = () => (
    <div className={styles.emailScreen}>
      <div className={styles.title}>Account Already Exists</div>
      <div className={clsx(styles.subtitle)} style={{ color: COLORS['warning-500'] }}>An account with the email address {email} already exists.</div>
      <div className={styles.subtitle}>{alreadyExist.message}</div>
      {alreadyExist.signInMethods.includes('google.com') && <SignInWith hideApple {...props} />}
      {alreadyExist.signInMethods.includes('apple.com') && <SignInWith hideGoogle {...props} />}
      <div className={styles.pageSwitch} style={{ marginTop: '40px' }}>
        {!alreadyExist && (
          <div className={styles.text}>Already have an account?</div>)}
        <div
          className={styles.link} onClick={() => {
            setIsAlreadyExist(false)
            setDialogType(DIALOG_TYPE.LOGIN)
          }}
        >
          {alreadyExist && 'Go '}Sign in
        </div>
      </div>
      {!alreadyExist && (
        <div className={styles.pageSwitch}>
          <div className={styles.text}>Don&apos;t have an account?</div>
          <div
            className={styles.link} onClick={() => {
              setIsAlreadyExist(false)
              setDialogType(DIALOG_TYPE.CREATE_ACCOUNT)
            }}
          >
            Create one
          </div>
        </div>
      )}
    </div>
  )

  const renderPasswordScreen = () => {
    const renderPasswordField = () => (
      <div className={clsx([styles.textField, styles.password])}>
        <div className={styles.label}>Create Password</div>
        <OutlinedInput
          autoFocus={!isMobile}
          type={showPassword ? 'text' : 'password'}
          classes={{ root: styles.muiRoot }}
          value={password || ''}
          onChange={(event) => setPassword(event.target.value)}
          placeholder=''
          disabled={isProcessingUserRequest}
          autoComplete='new-password'
          inputProps={{
            onKeyUp: onPasswordKeyUp
          }}
          endAdornment={
            <InputAdornment position='end' className={styles.showPasswordIcon}>
              <IconButton tabIndex={-1} onClick={() => setShowPassword(!showPassword)} edge='end'>
                {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
              </IconButton>
            </InputAdornment>
          }
        />
      </div>
    )
    const renderConfirmPasswordField = () => (
      <div className={clsx([styles.textField, styles.confirmPassword])}>
        <div className={styles.label}>Confirm Password</div>
        <OutlinedInput
          type={showConfirmPassword ? 'text' : 'password'}
          classes={{ root: styles.muiRoot }}
          value={confirmPassword || ''}
          onChange={(event) => setConfirmPassword(event.target.value)}
          placeholder=''
          disabled={isProcessingUserRequest}
          autoComplete='new-password'
          inputProps={{
            onKeyUp: onPasswordKeyUp
          }}
          endAdornment={
            <InputAdornment position='end' className={styles.showPasswordIcon}>
              <IconButton tabIndex={-1} onClick={() => setShowConfirmPassword(!showConfirmPassword)} edge='end'>
                {showConfirmPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
              </IconButton>
            </InputAdornment>
          }
        />
        {error && <div className={styles.error}>{error}</div>}
      </div>
    )

    return (
      <form
        className={styles.passwordScreen} onSubmit={e => {
          signUp()
          e.stopPropagation()
        }}
      >
        <div className={styles.row}>
          <div className={styles.title}>Create Password</div>
          <div className={styles.closeIcon}>
            <IconButton className={styles.iconButton} onClick={handleCloseDialog}>
              <CloseIcon />
            </IconButton>
          </div>
        </div>
        <div className={styles.subtitle}>Your user name is:</div>
        <div className={styles.email}>{email}</div>
        <input type='hidden' name='email' autoComplete='username' value={email} />
        {renderPasswordField()}
        {renderConfirmPasswordField()}
        <Button
          classes={{ root: styles.continueButton, disabled: styles.disabled }}
          disabled={(!password && !confirmPassword) || isProcessingUserRequest || !passwordsMatch}
          onClick={signUp}
        >
          Continue
        </Button>
        {isProcessingUserRequest &&
          (
            <div className={styles.spinner}>
              <CircularLoadingIndicator label='Creating account' />
            </div>
          )}
      </form>
    )
  }

  return (
    <div className={clsx([styles.createAccount, { [styles.mobile]: isMobile }])}>
      {!isPasswordScreen && renderEmailScreen()}
      {isPasswordScreen && !alreadyExist && renderPasswordScreen()}
      {isPasswordScreen && alreadyExist && renderIfEmailExists()}
    </div>
  )
}

function Login (props) {
  const { isMobile, email, setEmail, password, setPassword, error, setError, showPassword, setShowPassword, isEmailValid, setDialogType, handleCloseDialog, isProcessingUserRequest, wrapFirebaseAuthRequest, isFromPasswordReset, setIsFromPasswordReset } = props

  const [isPasswordScreen, setIsPasswordScreen] = useState(false)
  const [passwordResetConfirmation, setPasswordResetConfirmation] = useState(isFromPasswordReset)

  useEffect(() => {
    if (isFromPasswordReset) {
      setIsFromPasswordReset(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const navigateToPasswordScreen = () => {
    setError('')
    setIsPasswordScreen(true)
  }

  const onEmailKeyUp = (e) => {
    if (e.key === 'Enter' && isEmailValid) {
      setPassword('')
      navigateToPasswordScreen()
    }
  }

  const renderSignInText = () => (
    <div className={styles.row}>
      <div className={styles.title}>Sign In</div>
      <div className={styles.closeIcon}>
        <IconButton className={styles.iconButton} onClick={handleCloseDialog}>
          <CloseIcon />
        </IconButton>
      </div>
    </div>
  )

  const renderEmailScreen = () => (
    <div className={styles.emailScreen}>
      {renderSignInText()}
      <SignInWith {...props} />
      <div className={styles.separator}>
        <div className={styles.line} />
        <div className={styles.text}>or</div>
        <div className={styles.line} />
      </div>
      <div className={clsx([styles.textField])}>
        <div className={styles.label}>Email</div>
        <TextField
          autoFocus={!isMobile}
          name='email'
          autoComplete='username'
          variant='outlined'
          classes={{ root: styles.muiRoot }}
          value={email || ''}
          onChange={(event) => setEmail(event.target.value.trim())}
          placeholder=''
          inputProps={{ onKeyUp: onEmailKeyUp }}
        />
      </div>
      <Button
        disabled={!isEmailValid}
        classes={{ root: styles.continueButton, disabled: styles.disabled }}
        onClick={navigateToPasswordScreen}
      >
        Continue
      </Button>
      <div className={styles.pageSwitch}>
        <div className={styles.text}>Don&apos;t have an account?</div>
        <div
          className={styles.link} onClick={() => setDialogType(DIALOG_TYPE.CREATE_ACCOUNT)}
        >
          Create one
        </div>
      </div>
      {passwordResetConfirmation && (
        <PasswordResetEmailConfirmation
          setPasswordResetConfirmation={setPasswordResetConfirmation}
          styles={styles}
          isMobile={isMobile}
        />
      )}
    </div>
  )

  const signIn = () => {
    const promise = signInWithEmailAndPassword(getAuth(), email, password)
    wrapFirebaseAuthRequest(promise)
  }

  const onPasswordKeyUp = (e) => {
    if (e.key === 'Enter' && password) {
      signIn()
    }
  }

  const renderPasswordScreen = () => (
    <div className={styles.passwordScreen}>
      {renderSignInText()}
      <div className={styles.email}>{email}</div>
      <div className={styles.textField}>
        <div className={styles.label}>Password</div>
        <OutlinedInput
          autoFocus={!isMobile}
          type={showPassword ? 'text' : 'password'}
          classes={{ root: styles.muiRoot }}
          value={password || ''}
          onChange={(event) => setPassword(event.target.value)}
          placeholder=''
          disabled={isProcessingUserRequest}
          autoComplete='current-password'
          endAdornment={
            <InputAdornment position='end' className={styles.showPasswordIcon}>
              <IconButton tabIndex={-1} onClick={() => setShowPassword(!showPassword)} edge='end'>
                {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
              </IconButton>
            </InputAdornment>
          }
          inputProps={{ onKeyUp: onPasswordKeyUp }}
        />
        {error && <div className={styles.error}>{error}</div>}
      </div>
      <Button
        classes={{ root: styles.continueButton, disabled: styles.disabled }}
        disabled={!password}
        onClick={signIn}
      >
        Continue
      </Button>
      <div className={styles.link} onClick={() => setDialogType(DIALOG_TYPE.FORGOT_PASSWORD)}>
        Forgot password?
      </div>
    </div>
  )

  return (
    <div className={clsx([styles.loginPage, { [styles.mobile]: isMobile }])}>
      {!isPasswordScreen && renderEmailScreen()}
      {isPasswordScreen && renderPasswordScreen()}
    </div>
  )
}

function ForgotPassword (props) {
  const { isMobile, email, setEmail, error, isEmailValid, setDialogType, isProcessingUserRequest, wrapFirebaseAuthRequest } = props

  const handleBackToLogin = () => {
    setDialogType(DIALOG_TYPE.LOGIN)
  }

  const doSendPasswordResetEmail = () => {
    wrapFirebaseAuthRequest(
      sendPasswordResetEmail(getAuth(), email, { url: window.location.href })
    )
  }

  const onEmailKeyUp = (e) => {
    if (e.key === 'Enter' && email) {
      doSendPasswordResetEmail()
    }
  }

  return (
    <div className={clsx([styles.forgotPassword, { [styles.mobile]: isMobile }])}>
      <div className={styles.content}>
        <div className={styles.title}>Forgot your password?</div>
        <div className={styles.info}>
          {`No Problem! Enter the email address
          associated with your account and we'll
          send you a link to reset your password.`}
        </div>
        <div className={clsx([styles.emailField])}>
          <div className={styles.label}>Email</div>
          <TextField
            autoFocus={!isMobile}
            variant='outlined'
            classes={{ root: styles.muiRoot }}
            value={email || ''}
            onChange={(event) => {
              setEmail(event.target.value)
            }}
            placeholder=''
            inputProps={{ onKeyUp: onEmailKeyUp }}
            disabled={isProcessingUserRequest}
          />
          {error && <div className={styles.error}>{error}</div>}
        </div>
        <Button
          classes={{ root: styles.sendEmailButton, disabled: styles.disabled }}
          disabled={!isEmailValid}
          onClick={doSendPasswordResetEmail}
        >
          Send Email
        </Button>
        <div className={styles.backToLogin} onClick={handleBackToLogin}>
          Back to login
        </div>
      </div>
    </div>
  )
}

function SignInWith (props) {
  const { hideApple, hideGoogle, handleCloseDialog, wrapFirebaseAuthRequest } = props

  function handleSSOWrapper (provider, func) {
    if (isInMobileAppWebview()) {
      notifySSORequested(provider)
    } else {
      func()
      handleCloseDialog()
    }
  }

  const handleLoginWithGoogle = () => {
    const googleProvider = new GoogleAuthProvider()
    googleProvider.addScope('email')
    googleProvider.addScope('profile')
    wrapFirebaseAuthRequest(signInWithPopup(getAuth(), googleProvider))
  }

  const renderSignInWithGoogle = () => (
    <Button
      classes={{
        root: clsx([styles.ssoButton, styles.google]),
        text: styles.text,
        startIcon: styles.icon
      }}
      onClick={() => handleSSOWrapper('google', handleLoginWithGoogle)}
      startIcon={<GoogleLogo />}
    >
      Sign in with Google
    </Button>
  )

  const handleLoginWithApple = () => {
    const appleProvider = new OAuthProvider('apple.com')
    appleProvider.addScope('email')
    appleProvider.addScope('name')
    wrapFirebaseAuthRequest(signInWithPopup(getAuth(), appleProvider))
  }

  const renderSignInWithApple = () => (
    <Button
      classes={{
        root: clsx([styles.ssoButton, styles.apple]),
        text: styles.text,
        startIcon: styles.icon
      }}
      onClick={() => handleSSOWrapper('apple', handleLoginWithApple)}
      startIcon={<AppleLogo />}
    >
      Sign in with Apple
    </Button>
  )

  return (
    <>
      {!hideGoogle && renderSignInWithGoogle()}
      {(!hideApple && (!isInMobileAppWebview() || isInIOSWebview())) && renderSignInWithApple()}
    </>
  )
}

export default SignUpAndLoginDialog
