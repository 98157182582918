import Page from './index'

import { isInMobileAppWebview } from '@/utils/mobile-app-communication'

const isInWebview = isInMobileAppWebview()

export default !isInWebview
  ? [
      {
        path: '/gift',
        element: <Page />
      }
    ]
  : []
