import { assert, now } from '.'

import { selectLoggedInUser, updateCurrentUser } from '@/store/auth'

/**
 * @typedef {Object} ReminderUpdate information about a reminder; any omitted field will not be changed from its current value, if any
 * @property {boolean} [off] if true, this reminder is disabled and will not be shown to the user again
 * @property {int} [lastRemindedAtEpoch] when this reminder was last shown
 */

/**
 * Updates the current user's reminders settings with new tag information based on provided update object.
 *
 * @param {string} reminderId the unique ID associated with this reminder (e.g., "tag")
 * @param {ReminderUpdate} reminderUpdate the changes to make to the specified reminder
 */
export function updateReminder (reminderId, { enabled, lastRemindedAtEpoch }) {
  return async (dispatch, getState) => {
    const state = getState()
    const user = selectLoggedInUser(state)
    const settings = user.settings ?? user?.customData?.settings
    const reminders = settings?.reminders ?? {}
    // Need to clone the object to prevent `Cannot assign to read only property 'off' of object '#<Object>'`
    // as the object received from the redux are immutable
    const reminder = { ...reminders[reminderId] ?? {} }
    if (enabled !== undefined) {
      reminder.off = !enabled
    }
    if (lastRemindedAtEpoch !== undefined) {
      reminder.t = lastRemindedAtEpoch
      assert(lastRemindedAtEpoch <= now(), 'invalid lastRemindedAtEpoch')
    }
    dispatch(updateCurrentUser({
      settings: {
        reminders: {
          ...reminders, // keep other reminder data as-is
          [reminderId]: reminder // the new data for reminderId
        }
      }
    }))
  }
}
