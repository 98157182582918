const normalizeSendEmailErrorMessage = (err) => {
  const normalizedFirebaseErrors = [{ code: 'auth/invalid-email', text: 'Invalid Email' }]
  const defaultErrorMessage = 'Error while sending email.'
  const normalized = normalizedFirebaseErrors.find((el) => err.code === el.code)
  return normalized?.text || err.message || defaultErrorMessage
}

const normalizeCreateUserErrorMessage = (err) => {
  const normalizedFirebaseErrors = [
    { code: 'auth/invalid-email', text: 'Invalid Email' },
    { code: 'auth/weak-password', text: 'Password should be at least 6 characters' },
    { code: 'auth/email-already-in-use', text: 'Account already exists' }
  ]
  const defaultErrorMessage = 'Error while creating new user'
  const normalized = normalizedFirebaseErrors.find((el) => err.code === el.code)
  return normalized?.text || err.message || defaultErrorMessage
}

const normalizeLoginErrorMessage = (err) => {
  const normalizedFirebaseErrors = [
    { code: 'auth/invalid-email', text: 'Invalid Email' },
    {
      code: 'auth/invalid-credential',
      text: 'Wrong password. Please try again or click Forgot password to reset it.'
    },
    {
      code: 'auth/too-many-requests',
      text: 'Access to this account has been temporarily disabled due to many failed login attempts. You can immediately restore it by resetting your password or you can try again later.'
    }
  ]
  const defaultErrorMessage = 'Login Error'
  const normalized = normalizedFirebaseErrors.find((el) => err.code === el.code)
  return normalized?.text || err.message || defaultErrorMessage
}

export { normalizeCreateUserErrorMessage, normalizeLoginErrorMessage, normalizeSendEmailErrorMessage }
