import MuiDialog from '@mui/material/Dialog'
import { styled } from '@mui/material/styles'

import COLORS from '@/utils/colors'

const Dialog = styled(MuiDialog)({
  overflowX: 'hidden',
  '& .MuiPaper-root': { overflowX: 'hidden' },
  '& .paper': {
    width: '900px',
    maxWidth: 'unset',
    maxHeight: 'calc(100% - 20px)',
    borderRadius: '20px',
    '& .muiDialogContentRoot': { padding: '32px' },
    '& .header': {
      display: 'flex',
      flexDirection: 'column',
      '& .close-button': {
        cursor: 'pointer',
        top: '-20px',
        right: '20px !important',
        fontSize: '60px',
        position: 'absolute',
        bottom: 'auto',
        left: 'auto',
        transform: 'rotate(45deg)'
      },
      '& .title': {
        textAlign: 'center',
        letterSpacing: '-1px',
        marginTop: 0,
        marginBottom: '16px',
        fontSize: '40px',
        fontWeight: 700,
        lineHeight: '120%'
      },
      '& .subtitle': {
        display: 'flex',
        whiteSpace: 'nowrap',
        justifyContent: 'center',
        marginBottom: '32px',
        '& .helpLink': {
          marginLeft: '5px',
          color: COLORS['primary-500']
        }
      }
    },
    '& .content': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      '& .dndContainer': {
        backgroundColor: COLORS.white,
        border: `1px dashed ${COLORS['neutral-300']}`,
        borderRadius: '10px',
        paddingTop: '45px',
        paddingBottom: '45px',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        '& h5': {
          marginBottom: '10px',
          fontSize: '20px',
          fontWeight: 600,
          lineHeight: '180%'
        },
        '& img': {
          width: '130px'
        },
        '& .uploadButton': {
          padding: '14px 26px',
          backgroundColor: '#00d824',
          color: 'white',
          textTransform: 'none',
          em: { color: 'inherit' }
        }
      },
      '& .spacer-text': {
        margin: '20px 0'
      },
      '& .demoVideoButton': {
        backgroundColor: 'white',
        color: '#221d35',
        textAlign: 'center',
        textTransform: 'uppercase',
        border: '1px solid #bdbdbd',
        borderRadius: '30px',
        padding: '15px 28px'
      }
    },
    '& .footer': {
      paddingTop: '40px',
      display: 'flex',
      justifyContent: 'space-between',
      '& .pbVisionLogo': {},
      '& .signInSection': {
        display: 'flex',
        gap: '10px',
        '& .label': {},
        '& .signInLink': {
          color: COLORS['primary-500']
        }
      }
    },
    '&.mobile': {
      '& .close-button': {
        top: '-5px',
        right: '10px !important',
        fontSize: 30
      },
      '& .title': {
        fontSize: 20
      },
      '& .subtitle': {
        display: 'block',
        whiteSpace: 'unset',
        marginBottom: 20
      },
      '& .dndContainer': {
        padding: 10,
        '& > img': {
          width: 60
        },
        '& h5': {
          fontSize: 16,
          textAlign: 'center'
        }
      },
      '& .footer': {
        flexDirection: 'column',
        '& .signInSection': {
          marginTop: 10
        }
      }
    }
  }
})

export default Dialog
