/* eslint-disable react/no-array-index-key */
import CloseIcon from '@mui/icons-material/Close'
import PersonIcon from '@mui/icons-material/Person'
import Popover from '@mui/material/Popover'
import { styled } from '@mui/material/styles'
import clsx from 'clsx'
import { useContext, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { SimpleButton } from '../button/simple-button'

import { Button } from '@/components/button'
import { TagPlayer } from '@/components/tag-player'
import { APIContext } from '@/utils/api'
import COLORS from '@/utils/colors'
import { column, row } from '@/utils/flexGrid'

const sharedStyle = {
  '& .pi__wrapper': {
    display: 'block',
    position: 'relative',
    top: 0,
    left: 0,
    overflow: 'hidden',
    width: '100%',
    height: '100%',

    '& .pi__images': {
      position: 'absolute',
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      width: '100%',
      height: '100%',
      transformOrigin: 'top left'
    }
  }
}

const popoverStyle = {
  ...sharedStyle,
  '& .MuiPaper-root': {
    backgroundColor: COLORS['neutral-050'],
    borderColor: COLORS['neutral-300'],
    borderRadius: '12px',
    borderStyle: 'solid',
    borderWidth: 1
  },

  '& header': {
    ...row,
    marginBottom: '8px',
    '& button': {
      color: COLORS['neutral-300']
    },
    '& .player-name': {
      color: COLORS['00-on-surface-high-emphasis'],
      fontSize: 20,
      fontWeight: 600,
      lineHeight: '155%'
    },
    '& input': {
      padding: '4px',
      marginBottom: '1px'
    },
    '& > span': {
      flex: 'unset'
    },
    '& > span svg': {
      marginLeft: '8px'
    }
  },
  '& picture.pi__wrapper': {
    height: 371,
    width: 332
  },
  '& .pi__popup': {
    ...column,
    padding: '16px',
    overflow: 'hidden',
    '& .jump-to-player-stats': {
      marginTop: '12px'
    }
  }
}

const PlayerContainer = styled('div', {
  shouldForwardProp: (prop) => !['width', 'height', 'scale'].includes(prop)
})(
  ({
    width,
    height
    // theme,
  }) => ({
    borderRadius: '5px',
    border: '3px solid currentColor',
    position: 'relative',
    width,
    height,
    '& button': {
      borderRadius: '2px',
      '.pi__images': {
        top: 0,
        left: 0
      }
    },
    '&.player1': {
      color: COLORS.player1,
      border: '3px solid currentColor'
    },
    '&.player2': {
      color: COLORS.player2,
      border: '3px solid currentColor'
    },
    '&.player3': {
      color: COLORS.player3,
      border: '3px solid currentColor'
    },
    '&.player4': {
      color: COLORS.player4,
      border: '3px solid currentColor'
    },
    ...sharedStyle
  })
)

const normalizeUrl = (url) =>
  url.startsWith('http') || url.startsWith('data:image/') ? url : `data:image/png;base64,${url}`

const ButtonStyled = styled(Button)({
  backgroundColor: COLORS.white,
  paddingBlock: '8px',
  border: `1px solid ${COLORS['neutral-300']}`,
  color: COLORS['neutral-700'],
  '&:hover': {
    borderColor: COLORS['neutral-400']
  }
})

/**
 *
 * @param props
 */
export function PlayerImage (props) {
  const { vid, aiEngineVersion, playerIdx, width = 200, height = 191, className, fit, children, text, onChange, numberOfImages = 8, imageWidth = 25 } = props
  const { video } = useContext(APIContext)
  const { userData } = video
  const [anchorEl, setAnchorEl] = useState(null)
  const navigate = useNavigate()

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }
  const hasPopup = Boolean(onChange && text)
  const open = Boolean(anchorEl)

  let { scale } = props
  if (fit) {
    scale = width / 256
  }
  const avatars = []
  for (let imageIdx = 0; imageIdx < numberOfImages; imageIdx++) {
    avatars.push(`https://storage.googleapis.com/${import.meta.env.VITE_PRO_BUCKET}/${vid}/${aiEngineVersion}/player${playerIdx}-${imageIdx}.jpg`)
  }

  function onJumpToPlayerStatsClick () {
    navigate(`/video/${vid}/player/${playerIdx + 1}`)
  }

  return (
    <PlayerContainer className={className} width={width} height={height} scale={scale}>
      <SimpleButton className='pi__wrapper' onClick={handleClick}>
        <i className='pi__images'>
          {avatars.map((a, i) => (
            <img key={i} className={clsx('pi__img')} style={{ width: `${imageWidth}%` }} src={normalizeUrl(a)} alt='' />
          ))}
        </i>
      </SimpleButton>
      {children}
      {hasPopup && (
        <Popover
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left'
          }}
          sx={popoverStyle}
        >
          <div className='pi__popup'>
            <header>
              <TagPlayer vid={vid} playerIdx={playerIdx} playerName={userData.players[playerIdx]?.name} aiEngineVersion={aiEngineVersion} />
              <SimpleButton onClick={handleClose} sx={{ marginLeft: 'auto' }}>
                <CloseIcon />
              </SimpleButton>
            </header>
            <picture className='pi__wrapper'>
              <i className='pi__images'>
                {avatars.map((a, i) => (
                  <img key={i} className={clsx('pi__img')} style={{ width: `${imageWidth}%` }} src={normalizeUrl(a)} alt='' />
                ))}
              </i>
            </picture>
            <ButtonStyled className='jump-to-player-stats' onClick={onJumpToPlayerStatsClick}>
              <em>Jump to Player Stats</em>
              <PersonIcon />
            </ButtonStyled>
          </div>
        </Popover>
      )}
    </PlayerContainer>
  )
}
