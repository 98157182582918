import React, { cloneElement, useMemo, useState } from 'react'

import UpgradeToAccessDialog from '../dialog/upgrade-to-access'

import { SUBSCRIPTION_PLANS } from '@/hooks/monetization'
import { useMyMonetizationData } from '@/store/auth'
import { assert } from '@/utils'

/**
 * Component designed to be wrapped around clickable elements to restrict access
 * to features based on the user's subscription level. If the user does not have
 * the required subscription, a dialog will be triggered instead of allowing the
 * original click action to proceed.
 *
 * @param {Object} props The properties for the FeatureGuard component.
 * @param {string} props.requiredSubscription The required subscription tier to access the feature.
 * @param {string} props.hasException If exception is true, feature guard is disabled.
 * @param {string} props.hasOpacity When this is true, component will have the opacity.
 * @param {React.ReactNode} props.children The clickable element(s) guarded by the subscription status.
 * @returns {React.ReactElement} A component that conditionally allows clicks based on the subscription level or triggers a dialog.
 *
 * @example
 * <FeatureGuard requiredSubscription={SUBSCRIPTION_PLANS.PREMIUM}>
 *   <MenuItem onClick={openRawDataDialog} disabled={!isWorkflowDone}>
 *     <ListItemIcon>
 *       <TextSnippetIcon fontSize="small" />
 *     </ListItemIcon>
 *     Download Raw Data
 *   </MenuItem>
 * </FeatureGuard>
 */

export default function FeatureGuard ({ requiredSubscription, hasOpacity, hasException, children }) {
  // ensure requiredSubscription is valid value
  assert(Object.values(SUBSCRIPTION_PLANS).indexOf(requiredSubscription) !== -1,
    'unknown subscription tier required')

  const { hasPremiumAccess, hasStarterAccess } = useMyMonetizationData()
  const [dialogOpen, setDialogOpen] = useState(false)

  const isAllowed = useMemo(() => {
    // if has exception is true, then feature guard is disabled
    if (hasException) {
      return true
    }
    // if a user has premium access, then everything is allowed for them
    if (hasPremiumAccess) {
      return true
    }
    // even if the user doesn't have premium access, they can still access
    // starter-only features if they have a starter subscription
    if (requiredSubscription === SUBSCRIPTION_PLANS.STARTER && hasStarterAccess) {
      return true
    }
    // if we get here, premium access was required but the user does not have it
    return false
  }, [hasPremiumAccess, hasStarterAccess, hasException, requiredSubscription])

  const handleChildClick = (event) => {
    // Prevent the original click action if the subscription is not appropriate
    if (!isAllowed) {
      setDialogOpen(true)
      event.preventDefault()
      event.stopPropagation()
    }
  }

  const renderChildren = () => {
    const originalOnClick = children.props?.onClick

    const onClickHandler = (event) => {
      // Call the original onClick if the event was not stopped
      if (!isAllowed) {
        event.stopPropagation()
        return
      }
      originalOnClick && originalOnClick(event)
    }

    return (
      <div onClickCapture={handleChildClick}>
        {React.Children.map(children, child =>
          cloneElement(child, {
            onClick: onClickHandler,
            style: {
              ...child.props.style,
              opacity: hasOpacity && !isAllowed ? 0.3 : 1,
              pointerEvents: hasOpacity && !isAllowed ? 'none' : 'auto'
            }
          }))}
      </div>
    )
  }

  return (
    <>
      {renderChildren()}
      <UpgradeToAccessDialog isOpen={dialogOpen} setIsOpen={setDialogOpen} requiredSubscription={requiredSubscription} />
    </>
  )
}
